import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import { useToastContext } from "../../context/ToastContext";
import { Loader } from "../../components/ui/Loader";
import { Button } from "../../components/ui/Button";
import { FormError } from "../../components/ui/FormError";
import { Dropdown } from "../../components/ui/inputs/dropdown";
import { bannedInputs, numberRegex, urlRegex } from "../../constants";
import {
  BuildingIcon,
  RupeeIcon,
  RedirectIcon,
  LinkedInIcon,
  UploadIcon,
} from "../../icons/icons";
import { getCurrentDate } from "../../utils/getCurrentDate";

export const StartupDetailsForm = ({ token, industries }) => {
  const businessDetailsApi = `${process.env.REACT_APP_API_URL}insert-business-details`;

  const { isLoading, error } = useFetch();
  const navigate = useNavigate();
  const toast = useToastContext();

  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const BusinessDetailsApiCall = (data) => {
    setIsClicked(true);
    document.getElementById("duplicate-error").classList.add("hidden");
    document.getElementById("category-error").classList.add("hidden");
    document.getElementById("category-limit").classList.add("hidden");
    if (selectedIndustry.length < 1) {
      document.getElementById("category-error").classList.remove("hidden");
      return;
    }
    if (selectedIndustry.length > 3) {
      document.getElementById("category-limit").classList.remove("hidden");
      return;
    }
    let logo = document.getElementById("startupLogo").files[0];

    const formData = new FormData();
    formData.append("file", logo);
    formData.append("company_name", data.companyName);
    formData.append("require_investment", data.requireInvestment);
    formData.append("startup_industry", selectedIndustry);
    formData.append("website", data.website);
    formData.append("linked_in", data.startupLinkedin);
    formData.append("date", getCurrentDate());
    axios
      .post(businessDetailsApi, formData, {
        headers: { "x-token": token },
      })
      .then((response) => {
        setIsClicked(false);
        if (response.data.message === "duplicate") {
          document.getElementById("duplicate-error").classList.remove("hidden");
          return;
        }
        if (response.data.message === "validation error") {
          toast.open("false", "Sorry, Failed to submit. Validation failed.");
          return;
        }
        navigate("/startup-dashboard");
      })
      .catch(() => toast.open("false", "Failed to submit"));
  };

  return (
    <div
      id="startupForm"
      className="flex col-span-7 authentication-bg relative bg-white md:items-center pb-3 px-4 md:px-0 md:py-0 md:my-3 lg:my-0 lg:py-3 md:col-start-2 md:col-span-5 lg:col-span-2"
    >
      <form
        onSubmit={handleSubmit(BusinessDetailsApiCall)}
        className="mx-auto p-5 rounded-md shadow-md md:w-full lg:p-3 lg:shadow-none lg:rounded-none lg:pt-0"
      >
        <h4 className="mb-2">Tell us something about your business</h4>
        <div className="mb-1">
          <label htmlFor="companyName" className="text-sm mb-0 required">
            Company Name
          </label>
          <div
            className="flex rounded-md overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="text"
              className="border-0 w-full ps-3"
              id="companyName"
              name="companyName"
              placeholder="Company Name"
              autoFocus
              {...register("companyName", {
                required: "Company Name is required",
                validate: (fieldValue) => {
                  return (
                    !bannedInputs.some((value) => fieldValue.includes(value)) ||
                    "Enter a different name"
                  );
                },
                minLength: {
                  value: 3,
                  message: "Must be at least 3 characters long",
                },
                maxLength: {
                  value: 100,
                  message: "Max length is 100 characters",
                },
              })}
            />
            <span className="input-icons icon-span">
              <BuildingIcon />
            </span>
          </div>
          {errors.companyName && (
            <div>
              <FormError message={errors.companyName.message} />
            </div>
          )}
        </div>
        <div className="mb-1">
          <label htmlFor="startupLogo" className="text-sm mb-0">
            Company Logo
          </label>
          <div
            className="flex items-center rounded-md overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="file"
              accept="image/*"
              className="border-0 w-full ps-3"
              id="startupLogo"
              name="startupLogo"
              placeholder="Upload Company Logo"
            />
            <span className="input-icons icon-span">
              <UploadIcon />
            </span>
          </div>
        </div>
        <div className="mb-1">
          <label htmlFor="requireInvestment" className="mb-0 text-sm required">
            How much investment are you looking for (₹)
          </label>
          <div
            className="flex rounded-md overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="number"
              className="border-0 w-full ps-3"
              id="requireInvestment"
              name="requireInvestment"
              placeholder="ex: 1000000"
              {...register("requireInvestment", {
                required: "Required investment is required",
                pattern: {
                  value: numberRegex,
                  message: "Amount must only contain digits",
                },
                minLength: {
                  value: 4,
                  message: "Must be atleast 10000",
                },
              })}
            />
            <span className="input-icons icon-span">
              <RupeeIcon />
            </span>
          </div>
          {errors.requireInvestment && (
            <div>
              <FormError message={errors.requireInvestment.message} />
            </div>
          )}
        </div>
        <div className="mb-1 flex-col">
          <p>
            Industry{" "}
            <span className="required">({selectedIndustry.length + "/3"})</span>
            <span id="category-limit" className="hidden text-red-500 ms-2">
              Limit exceeded
            </span>
          </p>
          <Dropdown
            options={industries?.map((industry) => ({
              label: industry.industry_name
                .replace(new RegExp("_", "g"), " ")
                .toUpperCase(),
              value: industry.industry_name,
            }))}
            onChange={(selectedOptions) =>
              setSelectedIndustry(selectedOptions.map((option) => option.value))
            }
            isMulti
          />
          <small className="text-red-500 mb-2 hidden" id="category-error">
            This field is mandatory.
          </small>
        </div>
        <div className="mb-1">
          <label htmlFor="website" className="mb-0 text-sm">
            Website Link
          </label>
          <div
            className="flex rounded-md overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="text"
              className="border-0 w-full ps-3"
              id="website"
              name="website"
              placeholder="Websites URL"
              {...register("website", {
                validate: {
                  validUrl: (fieldValue) => {
                    return fieldValue.length > 0
                      ? urlRegex.test(fieldValue) || "Please enter a valid url"
                      : null;
                  },
                  bannedUrl: (fieldValue) => {
                    return (
                      !bannedInputs.some((value) =>
                        fieldValue.includes(value)
                      ) || "Enter a different url"
                    );
                  },
                },
              })}
            />
            <span className="input-icons icon-span">
              <RedirectIcon />
            </span>
          </div>
          {errors.website && (
            <div>
              <FormError message={errors.website.message} />
            </div>
          )}
        </div>
        <div className="mb-1">
          <label htmlFor="startupLinkedin" className="mb-0 text-sm">
            Linkedin profile{" "}
            <small>
              ( LinkedIn profiles boost authenticity and credibility—highly
              recommended. )
            </small>
          </label>
          <div
            className="flex rounded-md overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="text"
              className="border-0 w-full ps-3"
              id="startupLinkedin"
              name="startupLinkedin"
              placeholder="Linkedin URL"
              {...register("startupLinkedin", {
                validate: {
                  validUrl: (fieldValue) => {
                    return fieldValue.length > 0
                      ? urlRegex.test(fieldValue) || "Please enter a valid url"
                      : null;
                  },
                  bannedUrl: (fieldValue) => {
                    return (
                      !bannedInputs.some((value) =>
                        fieldValue.includes(value)
                      ) || "Enter a different url"
                    );
                  },
                },
              })}
            />
            <span className="input-icons icon-span">
              <LinkedInIcon />
            </span>
          </div>
          {errors.startupLinkedin && (
            <div>
              <FormError message={errors.startupLinkedin.message} />
            </div>
          )}
        </div>
        <span id="duplicate-error" className="hidden text-red-500">
          website already exists.
        </span>
        {isLoading && !error ? <Loader pt="10px" classes="my-2" /> : null}
        <Button
          type="submit"
          className={`py-1.5 mt-3 w-full ${
            isClicked && "disabled cursor-not-allowed"
          }`}
          bgColor={isClicked ? "bg-gray-400" : "bg-primary"}
          disabled={isClicked}
        >
          Next
        </Button>
      </form>
    </div>
  );
};
