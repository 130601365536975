import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCredits } from "../../store/slices/FetchCreditsSlice";
import { useFilterContext } from "../../context/filterContext";
import { useToastContext } from "../../context/ToastContext";
import useLocalStorage from "../../hooks/useLocalStorage";
import useFetch from "../../hooks/useFetch";
import useSearchApi from "../../hooks/useSearchApi";
import useNotificationApi from "../../hooks/useNotificationApi";
import useDisplayIndustries from "../../hooks/useDisplayIndustries";
import { Error } from "../../components/ui/Error";
import { Loader } from "../../components/ui/Loader";
import { AdCard } from "../../components/ui/cards/adCard";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/layout/Navbar";
import { AddBidModal } from "../../components/ui/modals/market/addBidModal";
import { PostAdModal } from "../../components/ui/modals/market/postAdModal";
import { Button } from "../../components/ui/Button";
import "./market.css";
import { randomId } from "../../utils/randomId";
import { FilterIcon, LongRightArrow } from "../../icons/icons";
import { Dropdown } from "../../components/ui/inputs/dropdown";
import { scrollToTop } from "../../utils/scrollTotop";
import { getCurrentDate } from "../../utils/getCurrentDate";

const Market = () => {
  const searchAdApi = `${process.env.REACT_APP_API_URL}search-ad`;
  const postBidApi = `${process.env.REACT_APP_API_URL}post-bid`;
  const postAdApi = `${process.env.REACT_APP_API_URL}post-ad`;

  const { token, userRole, name } = useLocalStorage();
  const { isLoading, error, fetchApi } = useFetch();
  const { filterAds, setFilterAds } = useFilterContext();
  const { sendNotification } = useNotificationApi();
  const toast = useToastContext();
  const { displayIndustries } = useDisplayIndustries();
  const { search } = useSearchApi();
  const { credits } = useSelector((state) => state.credits);
  const dispatch = useDispatch();

  const defaultFilter = useMemo(() => ({ tags: [] }), []);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [tags, setTags] = useState([]);
  const [postModal, setPostModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [adDetails, setAdDetails] = useState({
    id: "",
    title: "",
  });
  const [showFilter, setShowFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(defaultFilter);
  const selectedFilterRef = useRef(selectedFilter);
  const [key, setKey] = useState(randomId);
  const loaderRef = useRef(true);

  const fetchProjects = useCallback(() => {
    let filterObj = {
      tags: selectedFilterRef.current.tags,
    };
    search(searchAdApi, token, "", filterObj, (data) => {
      loaderRef.current = false;
      setFilterAds({
        data: data.ads,
        active_projects: data.active_ads,
      });
    });
  }, [search, setFilterAds, searchAdApi, token]);

  const postBid = useCallback(
    (id, startupId, data) => {
      fetchApi(
        {
          url: postBidApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            ad_id: id,
            bid_amount: data.bidAmount,
            bid_deadline: data.workDuration,
            bid_description: data.bidDescription,
            date: getCurrentDate(),
          },
        },
        (data) => {
          if (data.message === "bid restricted") {
            toast.open(
              "false",
              "Bid failed: Insufficient credits (25 needed) or already submitted."
            );
            return;
          }
          toast.open("true", "Bid posted successfully!");
          sendNotification(
            startupId,
            `Recieved a new bid from ${name}`,
            "market"
          );
          dispatch(fetchCredits(token));
        }
      );
    },
    [fetchApi, token, postBidApi, name, sendNotification, toast, dispatch]
  );

  const handleBidModal = (id, title, min_budget, startup_id) => {
    setAdDetails({
      id: id,
      title: title,
      minAmount: min_budget,
      startupId: startup_id,
    });
    setOpen(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchProjects();
      displayIndustries((data) => setTags(data.industries), "service provider");
    }, 30);
    return () => {
      clearTimeout(timer);
    };
  }, [displayIndustries, fetchProjects]);

  useEffect(() => {
    selectedFilterRef.current = selectedFilter;
  }, [selectedFilter]);

  const clearFilter = useCallback(() => {
    selectedFilterRef.current = defaultFilter;
    fetchProjects();
    setSelectedFilter(defaultFilter);
    setKey(randomId);
  }, [defaultFilter, fetchProjects]);

  const postAd = useCallback(
    (data, expertise) => {
      fetchApi(
        {
          url: postAdApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            title: data.title,
            max_budget: data.maxBudget,
            min_budget: data.minBudget,
            deadline: data.deadline,
            tags: expertise,
            description: data.description,
            date: getCurrentDate(),
          },
        },
        () => {
          toast.open("true", "Project created successfully!");
          fetchProjects();
        }
      );
    },
    [fetchApi, token, postAdApi, toast, fetchProjects]
  );

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        {isLoading && <Loader />}
        {error && (
          <div className="p-3 md:p-6">
            <Error />
          </div>
        )}
        {!error && !isLoading && (
          <div className="p-3 md:p-6 md:pb-0">
            <div className="flex items-center justify-between">
              <h5 className="text-xl font-semibold">Active Projects</h5>
              <div className="flex items-center gap-x-2">
                <span className="font-semibold">
                  {showFilter ? "Hide" : "Show"} Filters
                </span>
                <span
                  className="w-[40px] h-[40px] flex items-center justify-center rounded-full cursor-pointer transition-all duration-150 ease-in hover:bg-[#f9f9f9]"
                  title="Filter Users"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterIcon width="25px" height="25px" />
                </span>
              </div>
            </div>
            {showFilter && (
              <div className="sm:col-span-2 md:col-span-3 bg-white shadow p-3 rounded-lg">
                <div className="grid md:grid-cols-3 gap-x-5 gap-y-1 md:gap-y-0">
                  <div className="col-span-3 sm:col-span-1">
                    <p className="font-medium text-sm">Select Tags</p>
                    <Dropdown
                      key={`tag-${key}`}
                      name="tag-filter"
                      id="tag-filter"
                      closeMenuOnSelect={true}
                      isClearable={true}
                      options={tags?.map((tag) => ({
                        label: tag.industry_name
                          .replace(new RegExp("_", "g"), " ")
                          .toUpperCase(),
                        value: tag.industry_name,
                      }))}
                      onChange={(selectedOptions) =>
                        setSelectedFilter((prev) => ({
                          ...prev,
                          tags: selectedOptions
                            ? selectedOptions.map((option) => option.value)
                            : [],
                        }))
                      }
                      isMulti
                    />
                  </div>
                  <div className="col-span-3 ml-auto space-x-2">
                    <Button
                      className="font-medium px-3 py-1 text-xs md:text-sm"
                      bgColor="bg-red-500"
                      onClick={() => clearFilter()}
                    >
                      Clear All
                    </Button>
                    <Button
                      className="font-medium px-3 py-1 text-xs md:text-sm"
                      onClick={() => fetchProjects()}
                    >
                      Apply Filter
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {!error && !isLoading && filterAds && (
          <>
            {userRole !== "V&iR8" && (
              <div className="p-3 !pb-0 md:p-6 text-right">
                <Button
                  className="p-1.5 text-sm"
                  onClick={() => setPostModal(true)}
                >
                  Post Project
                </Button>
              </div>
            )}
            {!filterAds.data.length > 0 ? (
              <div className="p-3 md:p-6">
                {loaderRef.current ? (
                  <Loader />
                ) : (
                  <p className="font-medium text-lg">No Project Found</p>
                )}
              </div>
            ) : (
              <>
                <div className="p-3 md:p-6 grid gap-4">
                  {filterAds.data.map((ad) => (
                    <AdCard
                      ad={ad}
                      key={ad.ad_id}
                      role={userRole}
                      handleBidModal={handleBidModal}
                      credits={credits}
                    />
                  ))}
                </div>
              </>
            )}
          </>
        )}
        <button
          className="fixed right-3 bottom-3 w-10 h-10 flex items-center justify-center rounded-full border border-gray-300 bg-customOrange cursor-pointer"
          onClick={scrollToTop}
        >
          <LongRightArrow className="-rotate-90" fill="#fff" />
        </button>
      </div>
      <PostAdModal
        open={postModal}
        onClose={() => setPostModal(false)}
        postAd={postAd}
        industries={tags}
      />
      <AddBidModal
        open={open}
        onClose={() => setOpen(false)}
        adDetails={adDetails}
        postBid={postBid}
      />
    </div>
  );
};

export default Market;
