import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../components/ui/Button";
import successImg from "../../images/paymentSuccess.svg";
import FailedImg from "../../images/paymentFailed.svg";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";

const PaymentStatus = () => {
  const { type, status } = useParams();
  const navigate = useNavigate();

  const [openSidebar, setOpenSidebar] = useState(false);

  const redirect = () => {
    if (type === "subscription" || type === "top-up") {
      navigate("/store");
    }
    if (type === "market") {
      navigate("/my-projects");
    }
  };

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="h-screen flex items-center justify-center">
          <div className="p-5 flex flex-col justify-center items-center bg-white rounded-lg shadow-md">
            {status === "success" ? (
              <>
                <img src={successImg} alt="payment-success-img" width={300} />
                <div className="text-center mt-4">
                  <p className="text-lg font-semibold">Payment Successful</p>
                  <p className="py-3">
                    Thank you for your payment. Confirmation mail has been sent
                    to your registered email.
                  </p>
                  <Button
                    onClick={redirect}
                    className="px-3 py-1.5 text-sm hover:text-white"
                  >
                    Go Back
                  </Button>
                </div>
              </>
            ) : (
              <>
                <img src={FailedImg} alt="payment-success-img" width={300} />
                <div className="text-center mt-4">
                  <p className="text-lg font-semibold">Payment Failed</p>
                  <p className="py-3">
                    Your payment has failed, Please try again later. If there is
                    an issue of fund transfer please contact us by raising a
                    ticket. Thank you.
                  </p>
                  <Button
                    onClick={redirect}
                    className="px-3 py-1.5 text-sm hover:text-white"
                  >
                    Back to Dashboard
                  </Button>
                </div>
              </>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default PaymentStatus;
