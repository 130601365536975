import React, { useState } from "react";
import { Link } from "react-router-dom";
import StartupSidebar from "./startupSidebar";
import MentorSidebar from "./mentorSidebar";
import InvestorSidebar from "./investorSidebar";
import VendorSidebar from "./vendorSidebar";
import { TicketModal } from "../ui/modals/ticket/ticketModal";
import "../../Pages/dashboard/dashboard.css";
import "./sidebar.css";
import pitchHereLogo from "../../images/pitch-here-logo.png";

export const RegularSidebar = ({ supportTicket, role, style }) => {
  const [open, setOpen] = useState(false);

  const currPath = window.location.pathname;
  const activeStyle =
    "text-sm flex items-center gap-x-4 cursor-pointer p-2 bg-primary text-white hover:text-white rounded-md mt-2";
  const regularStyle =
    "text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-blue-200 rounded-md mt-2";

  return (
    <nav
      className="fixed left-0 z-10 bg-[#F0F8FE] h-screen p-5 pt-6 w-[20%]"
      style={style}
    >
      <Link to="#">
        <img src={pitchHereLogo} alt="logo" width={180} />
      </Link>
      {role === 1 ? (
        <StartupSidebar
          currPath={currPath}
          activeStyle={activeStyle}
          regularStyle={regularStyle}
        />
      ) : role === 2 ? (
        <MentorSidebar
          currPath={currPath}
          activeStyle={activeStyle}
          regularStyle={regularStyle}
        />
      ) : role === 3 ? (
        <InvestorSidebar
          currPath={currPath}
          activeStyle={activeStyle}
          regularStyle={regularStyle}
        />
      ) : (
        <VendorSidebar
          currPath={currPath}
          activeStyle={activeStyle}
          regularStyle={regularStyle}
        />
      )}
      {!style ? (
        <div className="w-11/12 absolute left-2/4 right-2/4 bottom-0 -translate-x-2/4 mb-2 text-center">
          <p
            onClick={() => setOpen(!open)}
            className="py-2.5 px-4 text-sm bg-customOrange text-white rounded-md cursor-pointer"
          >
            Raise Support Ticket
          </p>
        </div>
      ) : null}
      <TicketModal
        open={open}
        onClose={() => setOpen(false)}
        supportTicket={supportTicket}
      />
    </nav>
  );
};
