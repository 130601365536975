import { useEffect, useRef, useState } from "react";
import ReactDom from "react-dom";
import { Button } from "../../Button";
import { useForm } from "react-hook-form";
import { FormError } from "../../FormError";
import {
  bannedInputs,
  numberRegex,
  textWithSpaceRegex,
} from "../../../../constants";
import {
  BriefCaseIcon,
  BuildingIcon,
  PhoneIcon,
  RupeeIcon,
  UserIcon,
} from "../../../../icons/icons";
import { Dropdown } from "../../inputs/dropdown";

export const EditMentorProfile = ({
  open,
  onClose,
  updateMentoDetials,
  editData,
  industries,
}) => {
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const ref = useRef(false);
  const menuRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      mentorName: editData.name,
      mentorMobile: editData.phone,
      mentorExperience: editData.work_experience,
      mentorPastCompany: editData.previous_company,
      mentorFee: editData.fee,
    },
  });

  useEffect(() => {
    if (!ref.current) {
      if (editData?.mentor_industry) {
        setSelectedIndustry([...editData.mentor_industry]);
      }
    }
    return () => {
      ref.current = true;
    };
  }, [editData]);

  if (!open) return null;

  const submitModal = (data) => {
    if (selectedIndustry.length < 1) {
      document.getElementById("industry-error").classList.remove("hidden");
      return;
    }
    if (selectedIndustry.length > 3) {
      document.getElementById("industry-limit").classList.remove("hidden");
      return;
    }
    document.getElementById("industry-error").classList.add("hidden");
    document.getElementById("industry-limit").classList.add("hidden");
    updateMentoDetials(data, selectedIndustry);
    setSelectedIndustry([]);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`max-w-[90%] max-h-[95vh] overflow-y-auto md:min-w-[700px] md:max-w-[700px] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading font-semibold text-center border-b p-3 text-base">
          Update Profile
        </h4>
        <form
          onSubmit={handleSubmit(submitModal)}
          className="grid grid-cols-1 md:grid-cols-2 gap-3 sm:p-3 mt-3"
        >
          <div className="mb-1">
            <label htmlFor="mentorName" className="mb-0 text-sm">
              Edit Name
            </label>
            <div
              className="flex mb-3 rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="border-0 w-full ps-3"
                id="mentorName"
                name="mentorName"
                placeholder="Enter Name"
                autoFocus
                {...register("mentorName", {
                  validate: {
                    validLength: (fieldValue) => {
                      return fieldValue.trim().length > 0
                        ? fieldValue.trim().length > 2 ||
                            "minimum required length is 3"
                        : null;
                    },
                    validName: (fieldValue) => {
                      return (
                        !bannedInputs.some((value) =>
                          fieldValue.includes(value)
                        ) || "Enter a different name"
                      );
                    },
                  },
                  pattern: {
                    value: textWithSpaceRegex,
                    message:
                      "Invalid name, must not start or end with space and must contain only alphabets",
                  },
                })}
              />
              <span className="input-icons cursor-pointer icon-span w-[39px]">
                <UserIcon />
              </span>
            </div>
            {errors.mentorName && (
              <div>
                <FormError message={errors.mentorName.message} />
              </div>
            )}
          </div>
          <div className="mb-1">
            <label htmlFor="mentorMobile" className="mb-0 text-sm">
              Change Contact Number
            </label>
            <div
              className="flex mb-3 rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                maxLength="12"
                minLength="7"
                className="border-0 w-full ps-3"
                id="mentorMobile"
                name="mentorMobile"
                placeholder="Enter Phone number"
                {...register("mentorMobile", {
                  validate: {
                    minValue: (fieldValue) => {
                      return fieldValue.length > 0
                        ? fieldValue.length > 6 ||
                            "Number should be atleast 7 digits"
                        : null;
                    },
                    maxValue: (fieldValue) => {
                      return fieldValue.length > 0
                        ? fieldValue.length < 13 ||
                            "Number cannot be more than 12 digits"
                        : null;
                    },
                  },
                  pattern: {
                    value: numberRegex,
                    message: "Number must only contain digits",
                  },
                })}
              />
              <span className="input-icons icon-span w-[39px]">
                <PhoneIcon />
              </span>
            </div>
            {errors.mentorMobile && (
              <div>
                <FormError message={errors.mentorMobile.message} />
              </div>
            )}
          </div>
          <div className="mb-1">
            <label htmlFor="mentorExperience" className="mb-0 text-sm">
              Years of Experience
            </label>
            <div
              className="flex mb-3 rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="border-0 w-full ps-3"
                id="mentorExperience"
                name="mentorExperience"
                placeholder="Enter Work Experience"
                {...register("mentorExperience", {
                  pattern: {
                    value: numberRegex,
                    message: "Experience must only contain digits",
                  },
                })}
              />
              <span className="input-icons icon-span w-[39px]">
                <BriefCaseIcon />
              </span>
            </div>
            {errors.mentorExperience && (
              <div>
                <FormError message={errors.mentorExperience.message} />
              </div>
            )}
          </div>
          <div className="flex-col" ref={menuRef}>
            <p className="text-sm">
              Industry <span>({selectedIndustry.length + "/3"})</span>
              <span
                id="industry-limit"
                className="hidden text-red-500 text-sm ms-2"
              >
                Limit exceeded
              </span>
            </p>
            <Dropdown
              options={industries?.map((industry) => ({
                label: industry.industry_name
                  .replace(new RegExp("_", "g"), " ")
                  .toUpperCase(),
                value: industry.industry_name,
              }))}
              value={selectedIndustry.map((industry) => ({
                label: industry
                  .replace(new RegExp("_", "g"), " ")
                  .toUpperCase(),
                value: industry,
              }))}
              onChange={(selectedOptions) =>
                setSelectedIndustry(
                  selectedOptions.map((option) => option.value)
                )
              }
              isMulti
            />
            <small className="text-red-500 mb-2 hidden" id="industry-error">
              This field is mandatory.
            </small>
          </div>
          <div className="mb-1">
            <label htmlFor="mentorPastCompany" className="mb-0 text-sm">
              Previous Company
            </label>
            <div
              className="flex mb-3 rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="border-0 w-full ps-3"
                id="mentorPastCompany"
                name="mentorPastCompany"
                placeholder="Enter Company Name"
                {...register("mentorPastCompany", {
                  validate: (fieldValue) => {
                    return fieldValue.trim().length > 0
                      ? !bannedInputs.some((value) =>
                          fieldValue.includes(value)
                        ) || "Enter a different name"
                      : null;
                  },
                })}
              />
              <span className="input-icons cursor-pointer icon-span w-[39px]">
                <BuildingIcon />
              </span>
            </div>
            {errors.mentorPastCompany && (
              <div>
                <FormError message={errors.mentorPastCompany.message} />
              </div>
            )}
          </div>
          <div className="mb-1">
            <label htmlFor="mentorFee" className="mb-0 text-sm">
              Session Fees
            </label>
            <div
              className="flex mb-3 rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="border-0 w-full ps-3"
                id="mentorFee"
                name="mentorFee"
                placeholder="Enter Session Fees"
                {...register("mentorFee", {
                  pattern: {
                    value: numberRegex,
                    message: "Invalid amount, must contain only digits",
                  },
                  validate: (fieldValue) => {
                    return fieldValue > 600 || "Fee cannot be less tha 600";
                  },
                })}
              />
              <span className="input-icons cursor-pointer icon-span w-[39px]">
                <RupeeIcon />
              </span>
            </div>
            {errors.mentorFee && (
              <div>
                <FormError message={errors.mentorFee.message} />
              </div>
            )}
          </div>
          <div className="md:col-span-2 flex items-center justify-end p-3">
            <Button
              onClick={onClose}
              className="px-2 py-1 mx-1 text-sm"
              bgColor="bg-secondary"
            >
              Close
            </Button>
            <Button type="submit" className="px-2 py-1 mx-1 text-sm">
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
