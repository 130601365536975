import { useState } from "react";
import ReactDom from "react-dom";
import useSubmitReview from "../../../../hooks/useSubmitReview";
import { Stars } from "./Stars";
import { Button } from "../../Button";
import "./modal.css";

const RatingModal = ({ open, onClose, role, id, submitRating, type = "" }) => {
  const { submitReview } = useSubmitReview();

  const [rating, setRating] = useState(0);
  const [reviewMsg, setReviewMsg] = useState("");

  if (!open) return null;
  const handleModalSubmit = () => {
    document.getElementById("reason-error").classList.add("hidden");
    const reason = document.getElementById("rating-reason").value;
    if (reason.length < 1) {
      document.getElementById("reason-error").classList.remove("hidden");
      return;
    }
    if (type === "market") {
      submitRating(rating, reviewMsg);
    } else {
      submitReview(id, rating, reviewMsg, role, onClose);
    }
    setRating(0);
    setReviewMsg("");
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`min-w[90%] md:min-w-[40%] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="border-b p-3 text-center font-medium text-base capitalize">
          Review
        </h4>
        <div className="content p-3">
          <div className="mb-4 flex justify-center">
            <div className="flex flex-col items-center mx-1">
              <p
                onClick={() => setRating(1)}
                className="px-3 py-1 w-[40px] rounded-full text-xl font-semibold border hover:bg-primary hover:text-white"
              >
                1
              </p>
              <p>Worst</p>
            </div>
            <div className="flex flex-col items-center mx-1">
              <p
                onClick={() => setRating(2)}
                className="px-3 py-1 w-[40px] rounded-full text-xl font-semibold border hover:bg-primary hover:text-white"
              >
                2
              </p>
              <p>Bad</p>
            </div>
            <div className="flex flex-col items-center mx-1">
              <p
                onClick={() => setRating(3)}
                className="px-3 py-1 w-[40px] rounded-full text-xl font-semibold border hover:bg-primary hover:text-white"
              >
                3
              </p>
              <p>Average</p>
            </div>
            <div className="flex flex-col items-center mx-1">
              <p
                onClick={() => setRating(4)}
                className="px-3 py-1 w-[40px] rounded-full text-xl font-semibold border hover:bg-primary hover:text-white"
              >
                4
              </p>
              <p>Good</p>
            </div>
            <div className="flex flex-col items-center mx-1">
              <p
                onClick={() => setRating(5)}
                className="px-3 py-1 w-[40px] rounded-full text-xl font-semibold border hover:bg-primary hover:text-white"
              >
                5
              </p>
              <p>Excellent</p>
            </div>
          </div>
          {rating > 0 && (
            <div className="my-3">
              <Stars rating={rating} />
            </div>
          )}
          <textarea
            name="rating-reason"
            id="rating-reason"
            cols="30"
            rows="4"
            className="p-2 border border-gray-300 rounded-md"
            placeholder="Please state your reason for this rating**(mandatory)"
            value={reviewMsg}
            onChange={(e) => setReviewMsg(e.target.value)}
            required
          ></textarea>
          <p id="reason-error" className="hidden text-secondary text-sm py-1">
            Reason is required
          </p>
        </div>
        <div className="modalFooter border-t p-3">
          <Button
            onClick={onClose}
            className="px-2 py-1 mx-1 hover:bg-red-700 hover:text-white"
            bgColor="bg-secondary"
          >
            Close
          </Button>
          {rating && reviewMsg ? (
            <Button
              onClick={handleModalSubmit}
              className="px-2 py-1 mx-1 hover:bg-indigo-700 hover:text-white"
            >
              Submit
            </Button>
          ) : (
            <Button className="disabled px-2 py-1 mx-1" disabled>
              Submit
            </Button>
          )}
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};

export default RatingModal;
