import { Link } from "react-router-dom";
import { Pagination } from "../../../components/ui/Button";

const RequestTable = ({ page, setPage, type, data }) => {
  let filterData;

  if (type === "All") {
    filterData = data;
  } else if (type === "Pending") {
    filterData = data.filter((data) => data.status === "0");
  } else if (type === "Completed") {
    filterData = data.filter((data) => data.status === "1");
  } else {
    filterData = data.filter((data) => data.status === "-1");
  }

  return (
    <>
      <div className="overflow-auto w-full md:overflow-none">
        <table className="table-auto min-w-max w-full mt-4 border bg-white shadow-md border-slate-300">
          <thead className="bg-[#F9FAFC] text-[#5a5a5b] border border-gray-200 text-left">
            <tr>
              <th className="font-medium p-3 text-xs md:text-sm">Name</th>
              <th className="font-medium p-3 text-xs md:text-sm">Role</th>
              <th className="font-medium p-3 text-xs md:text-sm">Amount</th>
              <th className="font-medium p-3 text-xs md:text-sm">
                Requested On
              </th>
              <th className="font-medium p-3 text-xs md:text-sm">Status</th>
              <th className="font-medium p-3 text-xs md:text-sm">Action</th>
            </tr>
          </thead>
          <tbody>
            {filterData.length > 0 ? (
              filterData.slice(page * 10 - 10, page * 10).map((request) => (
                <tr
                  key={request.id}
                  className="bg-white border-b border-slate-300 align-middle h-auto"
                >
                  <td className="p-3 text-xs md:text-sm uppercase">
                    {request.name}
                  </td>
                  <td className="p-3 text-xs">
                    <span
                      className={`p-1 ${
                        request.role === "f@!3A"
                          ? "blue-badge"
                          : request.role === "R$7s2"
                          ? "orange-badge"
                          : "green-badge"
                      }`}
                    >
                      {request.role === "f@!3A"
                        ? "Startup"
                        : request.role === "R$7s2"
                        ? "Mentor"
                        : "Vendor"}
                    </span>
                  </td>
                  <td className="p-3 text-xs md:text-sm">
                    <span className="font-medium mr-1">&#8377;</span>
                    {request.role === "f@!3A"
                      ? request.withdraw_amount
                      : request.withdraw_amount * (1 - 0.1)}
                  </td>
                  <td className="p-3 text-xs md:text-sm">{request.date}</td>
                  <td className="p-3 text-xs">
                    <span
                      className={`p-1 ${
                        request.status === "-1"
                          ? "red-badge"
                          : request.status === "0"
                          ? "orange-badge"
                          : "green-badge"
                      }`}
                    >
                      {request.status === "-1"
                        ? "Rejected"
                        : request.status === "0"
                        ? "Pending"
                        : "Completed"}
                    </span>
                  </td>
                  <td className="p-3 text-xs">
                    <Link
                      to={`/admin/request/${request.id}`}
                      state={{ data: request }}
                      className="p-1 md:p-1.5 bg-primary text-white rounded-md"
                    >
                      View Details
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="bg-white text-center">
                <td colSpan={6} className="p-3 text-xs md:text-sm">
                  No Withdraw Request
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {filterData.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          totalDataCount={filterData.length}
          dataCount={10}
        />
      )}
    </>
  );
};

export default RequestTable;
