import { ResponsiveBar } from "@nivo/bar";

const colorScheme = ["#17335E", "#B02444", "#F97316", "#26d9a6", "#627BFA"];
const getColor = (bar) => colorScheme[bar.index % colorScheme.length];

export const BarGraph = ({ data, type = "industries" }) => {
  const updatedData = data.map((d, i) => ({
    ...d,
    color: getColor({ index: i }),
  }));
  const customBottomLegend = type === "cities" ? "Cities" : "Industry";
  const customLeftLegend = type === "cities" ? "Users" : "Startup Count";

  return (
    <div className="h-[350px] w-full">
      <ResponsiveBar
        data={updatedData}
        indexBy="field"
        colors={getColor}
        layers={["grid", "axes", "bars", "legends"]}
        theme={{
          text: {
            fontSize: "14px",
            fill: "#ffffff",
          },
          axis: {
            ticks: {
              text: {
                fontSize: "12px",
                fill: "#000000",
              },
            },
            legend: {
              text: {
                fill: "#000000",
                fontSize: "12px",
              },
            },
            domain: {
              line: {
                stroke: "#777777",
              },
            },
          },
          legends: {
            text: {
              fill: "#000000",
              fontSize: "12px",
              textTransform: "cap",
            },
          },
          grid: {
            line: {
              strokeWidth: 0,
            },
          },
        }}
        margin={{ top: 20, right: 40, bottom: 140, left: 60 }}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        grid={{ yValues: 4, xValues: 5 }}
        tooltip={CustomTooltip}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 0,
          legend: customBottomLegend,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: 15,
          format: () => "",
        }}
        axisLeft={{
          tickSize: 4,
          tickPadding: 5,
          tickRotation: 0,
          legend: customLeftLegend,
          legendPosition: "middle",
          legendOffset: -40,
          truncateTickAt: 0,
          tickValues: 4,
          format: (e) => Math.floor(e) === e && e,
        }}
        legends={[
          {
            data: [
              ...updatedData.map((d) => ({
                label: d.field.replace(new RegExp("_", "g"), " "),
                color: d.color,
              })),
            ],
            anchor: "bottom-left",
            direction: "column",
            justify: false,
            translateX: 0,
            translateY: 140,
            itemsSpacing: 3,
            itemWidth: 300,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 15,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

const CustomTooltip = ({ data }) => {
  return (
    <div className="bg-white shadow rounded-md p-1">
      <div>
        <span className="font-medium">Field: </span>{" "}
        {data.field.replace(new RegExp("_", "g"), " ")}
      </div>
      <div>
        <span className="font-medium">Value: </span> {data.value}
      </div>
    </div>
  );
};
