import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import loginSlice from "./slices/LoginSlice";
import chatSlice from "./slices/ChatSlice";
import notificationSlice from "./slices/NotificationSlice";
import cardsDataSlice from "./slices/CardsDataSlice";
import fetchIndustriesSlice from "./slices/FetchIndustriesSlice";
import fetchCreditsSlice from "./slices/FetchCreditsSlice";
import startupDashboardSlice from "./slices/dashboard/StartupDashboardSlice";
import ReviewsSlice from "./slices/profile/ReviewsSlice";
import sampleTemplateSlice from "./slices/SampleTemplateSlice";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";

const persistConfig = {
  key: "pitchhere",
  storage,
  whitelist: ["users", "chatRoom"],
};

const reducer = combineReducers({
  users: loginSlice,
  chatRoom: chatSlice,
  notifications: notificationSlice,
  cardsData: cardsDataSlice,
  industries: fetchIndustriesSlice,
  credits: fetchCreditsSlice,
  dashboard: startupDashboardSlice,
  reviews: ReviewsSlice,
  templates: sampleTemplateSlice,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const Store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
let persistor = persistStore(Store);

export default Store;
export { persistor };
