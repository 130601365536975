import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useToastContext } from "../../context/ToastContext";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/layout/Navbar";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import { Button } from "../../components/ui/Button";
import { AttachmentIcon, CircleXmarkIcon } from "../../icons/icons";
import { getCurrentDate } from "../../utils/getCurrentDate";

const TicketDetails = () => {
  const ticketDetailsApi = `${process.env.REACT_APP_API_URL}ticket-details`;
  const addCommentApi = `${process.env.REACT_APP_API_URL}add-ticket-response`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const { id } = useParams();
  const toast = useToastContext();

  const [ticketDetails, setTicketDetails] = useState(null);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [sendBtnStatus, setSendBtnStatus] = useState(false);
  const [attachment, setAttachment] = useState(null);

  const ticketDetail = useCallback(() => {
    fetchApi(
      {
        url: ticketDetailsApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          id: id,
        },
      },
      (data) => {
        setTicketDetails(data.support_ticket[0]);
      }
    );
  }, [ticketDetailsApi, fetchApi, token, id]);

  const addComment = useCallback(() => {
    let commentText = document.getElementById("add-comment").value;
    document.getElementById("missingComment").classList.add("hidden");
    if (commentText.length < 1) {
      document.getElementById("missingComment").classList.remove("hidden");
      return;
    }
    setSendBtnStatus(true);
    const formData = new FormData();
    formData.append("ticket_id", id);
    formData.append("support_response", commentText);
    formData.append("file", attachment);
    formData.append("date", getCurrentDate());
    formData.append("role", userRole);
    axios
      .post(addCommentApi, formData, {
        headers: { "x-token": token },
      })
      .then(() => {
        toast.open("true", "Comment added successfully!");
        ticketDetail();
        document.getElementById("add-comment").value = "";
        setSendBtnStatus(false);
        setAttachment(null);
      })
      .catch((err) => {
        if (err.response.status) {
          toast.open("warning", "Failed to post comment");
        }
      });
  }, [addCommentApi, id, token, userRole, toast, ticketDetail, attachment]);

  useEffect(() => {
    const getDetails = setTimeout(() => {
      ticketDetail();
    });
    return () => {
      clearTimeout(getDetails);
    };
  }, [ticketDetail]);

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="p-3 md:p-5 h-full">
          {isLoading && <Loader />}
          {error && <Error />}
          {!isLoading && !error && (
            <>
              <div className="bg-white shadow-md rounded-lg p-3 md:p-5 space-y-1 mb-4">
                <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-y-2">
                  <p>
                    <span className="font-medium">Email : </span>
                    {ticketDetails?.email}
                  </p>
                  <p>
                    <span className="font-medium">Status : </span>
                    <span
                      className={`text-sm ${
                        ticketDetails?.status === "0"
                          ? "!text-red-600"
                          : ticketDetails?.status === "1"
                          ? "!text-orange-600"
                          : "!text-green-600"
                      }`}
                    >
                      {ticketDetails?.status === "0"
                        ? "Pending"
                        : ticketDetails?.status === "1"
                        ? "Under Review"
                        : "Completed"}
                    </span>
                  </p>
                </div>
                <p>
                  <span className="font-medium">Complaint Date : </span>
                  {ticketDetails?.date}
                </p>
                <p>
                  <span className="font-medium">Support Type : </span>
                  {ticketDetails?.support_type}
                </p>
                <p>
                  <span className="font-medium">Ticket Heading : </span>
                  {ticketDetails?.ticket_heading}
                </p>
                <p>
                  <span className="font-medium">Ticket Query : </span>
                  {ticketDetails?.query}
                </p>
                <p>
                  <span className="font-medium">Supporting Attachment : </span>
                  {ticketDetails?.path?.length > 0 ? (
                    <Link
                      to={ticketDetails.path}
                      target="_blank"
                      className="bg-slate-200 shadow-md p-1 rounded-md"
                    >
                      {ticketDetails?.file_name}
                    </Link>
                  ) : (
                    <span className="text-red-500">N/A</span>
                  )}
                </p>
              </div>
              <div className="w-full flex flex-col justify-between bg-white rounded-md px-3 py-2 gap-y-2">
                <textarea
                  name="add-comment"
                  id="add-comment"
                  cols="30"
                  rows="3"
                  placeholder="Enter your comment"
                  className="text-slate-500"
                  required
                ></textarea>
                <div className="flex items-center justify-end gap-x-3">
                  {attachment && (
                    <span className="relative bg-gray-300 p-1 rounded">
                      {attachment.name}
                      <CircleXmarkIcon
                        className="absolute -right-1 -top-1 cursor-pointer"
                        onClick={() => setAttachment(null)}
                      />
                    </span>
                  )}
                  <input
                    type="file"
                    id="attachment"
                    accept="application/pdf,image/*"
                    onChange={(e) => setAttachment(e.target.files[0])}
                    hidden
                  />
                  <label htmlFor="attachment" className="mb-0 cursor-pointer">
                    <AttachmentIcon fill="#ADADAD" width="20px" height="20px" />
                  </label>
                  <Button
                    className={`px-3 py-1.5 text-sm w-max ${
                      sendBtnStatus && "disabled"
                    }`}
                    disabled={sendBtnStatus}
                    onClick={addComment}
                  >
                    Add Comment
                  </Button>
                </div>
              </div>
              <p id="missingComment" className="hidden text-red-500 text-sm">
                Comment is required*
              </p>
              {ticketDetails?.ticket_response?.length > 0 ? (
                <div className="mt-5 space-y-2 max-h-[500px] overflow-y-auto">
                  <h6 className="text-lg font-medium">Comments : </h6>
                  {ticketDetails.ticket_response.map((comment) => (
                    <div
                      key={comment._id}
                      className={`p-3 shadow-md rounded-md ${
                        comment.role === userRole
                          ? "bg-[#F0F8FE]"
                          : "bg-gray-200"
                      }`}
                    >
                      <p>{comment.support_response}</p>
                      <div className="flex items-center justify-between">
                        {comment.path.length > 0 && (
                          <p className="mt-2">
                            Attachment :{" "}
                            <Link
                              to={comment.path}
                              target="_blank"
                              className="text-red-500"
                            >
                              {comment.file_name}
                            </Link>
                          </p>
                        )}
                        <p className="ml-auto text-xs text-slate-400 font-medium">
                          {userRole === comment.role
                            ? "Me"
                            : comment.role === "admin"
                            ? "Admin"
                            : "User"}{" "}
                          - {comment.date}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TicketDetails;
