import React, { useCallback, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { useToastContext } from "../../../context/ToastContext";
import { Button } from "../../../components/ui/Button";
import AddTopUp from "../../../components/ui/modals/store/addTopUp";
import EditTopUp from "../../../components/ui/modals/store/editTopUp";
import TopUpCard from "../../../components/ui/cards/topUpCard";
import { DeleteModal } from "../../../components/ui/modals/utility-modals/deleteModal";
import { randomId } from "../../../utils/randomId";

const TopUp = () => {
  const displayTopUpApi = `${process.env.REACT_APP_API_URL}display-top-up-plans`;
  const addTopUpApi = `${process.env.REACT_APP_API_URL}add-top-up`;
  const updateTopUpApi = `${process.env.REACT_APP_API_URL}edit-top-up`;
  const deleteTopUpApi = `${process.env.REACT_APP_API_URL}delete-top-up`;

  const { fetchApi } = useFetch();
  const { token } = useLocalStorage();
  const toast = useToastContext();

  const [selectedPlan, setSelectedPlan] = useState("Startup");
  const [openAddTopUpModal, setOpenAddTopUpModal] = useState(false);
  const [editTopUpModal, setEditTopUpModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [singleTopUp, setSingleTopUp] = useState([]);
  const [topUpPlans, setTopUpPlans] = useState({
    startup: [],
    vendor: [],
  });
  const [planId, setPlanId] = useState(null);

  const displayTopUpPlans = useCallback(() => {
    fetchApi(
      { url: displayTopUpApi, headers: { "x-token": token } },
      (data) => {
        setTopUpPlans({
          startup: data.plans?.filter((plan) => plan.role === "startup"),
          vendor: data.plans?.filter(
            (plan) => plan.role === "service provider"
          ),
        });
      }
    );
  }, [displayTopUpApi, token, fetchApi]);

  const addTopUp = useCallback(
    (data) => {
      fetchApi(
        {
          url: addTopUpApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            role: data.role,
            amount: parseInt(data.amount),
            tokens: parseInt(data.tokens),
            discount_status: data.discount_status,
            discount:
              data.type === "flat"
                ? parseInt(data.discount)
                : parseFloat(data.discount),
            discount_type: data.discount_type,
          },
        },
        () => {
          toast.open("true", "New Top-Up plan created successfully!");
          displayTopUpPlans();
        }
      );
    },
    [token, addTopUpApi, fetchApi, displayTopUpPlans, toast]
  );

  const updateTopUp = useCallback(
    (data, id, status) => {
      fetchApi(
        {
          url: updateTopUpApi,
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            id: id,
            plan_status: status,
            role: data.role,
            amount: parseInt(data.amount),
            tokens: parseInt(data.tokens),
            discount_status: data.discount_status,
            discount:
              data.type === "flat"
                ? parseInt(data.discount)
                : parseFloat(data.discount),
            discount_type: data.discount_type,
          },
        },
        () => {
          toast.open("true", "Top-Up plan updated successfully!");
          displayTopUpPlans();
        }
      );
    },
    [token, updateTopUpApi, fetchApi, displayTopUpPlans, toast]
  );

  const deleteTopUp = useCallback(
    (id) => {
      fetchApi(
        {
          url: deleteTopUpApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            id: id,
          },
        },
        () => {
          toast.open("true", "Top-Up plan deleted successfully!");
          displayTopUpPlans();
        }
      );
    },
    [token, deleteTopUpApi, fetchApi, displayTopUpPlans, toast]
  );

  useEffect(() => {
    const fetchTopUpPlans = setTimeout(() => {
      displayTopUpPlans();
    });
    return () => {
      clearTimeout(fetchTopUpPlans);
    };
  }, [displayTopUpPlans]);

  const checkSelectedPlan = () => {
    if (selectedPlan === "Startup") {
      return topUpPlans.startup;
    } else return topUpPlans.vendor;
  };

  const updateModal = (data) => {
    setSingleTopUp(data);
    setEditTopUpModal(true);
  };

  const handleDeleteModal = (id) => {
    setPlanId(id);
    setDeleteModal(true);
  };

  return (
    <div className="grid sm:grid-cols-3 md:grid-cols-5 gap-3">
      <div className="sm:col-span-3 md:col-span-5 flex flex-col items-start gap-y-3 sm:flex-row sm:items-center sm:justify-between">
        <div>
          <span
            className={`${
              selectedPlan === "Startup"
                ? "bg-primary text-white"
                : "bg-gray-200"
            } px-2 py-1 rounded-l-md border-r-2 border-gray-400`}
            onClick={() => setSelectedPlan("Startup")}
          >
            Startup
          </span>
          <span
            className={`${
              selectedPlan === "Service Provider"
                ? "bg-primary text-white"
                : "bg-gray-200"
            } px-2 py-1 rounded-r-md`}
            onClick={() => setSelectedPlan("Service Provider")}
          >
            Service Provider
          </span>
        </div>
        <Button
          className="px-3 py-1"
          onClick={() => setOpenAddTopUpModal(true)}
        >
          Create Top-Up Plan
        </Button>
      </div>
      <p className="sm:col-span-3 md:col-span-5 font-medium text-lg">
        {selectedPlan} Plans
      </p>
      {checkSelectedPlan().length > 0 ? (
        checkSelectedPlan().map((plan) => (
          <TopUpCard
            key={plan._id}
            data={plan}
            role="admin"
            updateModal={updateModal}
            updateTopUp={updateTopUp}
            handleDeleteModal={handleDeleteModal}
          />
        ))
      ) : (
        <p>No Plans Found.</p>
      )}
      <div className="sm:col-span-3 md:col-span-5"></div>
      <AddTopUp
        open={openAddTopUpModal}
        onClose={() => setOpenAddTopUpModal(false)}
        addTopUp={addTopUp}
      />
      <EditTopUp
        key={randomId()}
        open={editTopUpModal}
        onClose={() => setEditTopUpModal(false)}
        topUpData={singleTopUp}
        updateTopUp={updateTopUp}
      />
      <DeleteModal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        deleteMethod={deleteTopUp}
        id={planId}
      />
    </div>
  );
};

export default TopUp;
