import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const searchStartupsApi = `${process.env.REACT_APP_API_URL}search-startups-profile`;

const initialState = {
  startups: {
    data: [],
    count: null,
    filteredUsers: [],
    startupStatus: "idle",
    startupError: null,
  },
  mentors: [],
  investors: [],
  vendors: [],
  status: "idle",
  isError: null,
};

export const fetchStartupsCards = createAsyncThunk(
  "users/fetchStartups",
  async (
    { token, searchInput, filterObj, isInitialFetch },
    { getState, rejectWithValue }
  ) => {
    let bodyObj = {
      ...filterObj,
      search_data: searchInput,
    };

    const controller = new AbortController();
    const { signal } = controller;

    try {
      const response = await axios.post(searchStartupsApi, bodyObj, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        signal,
      });
      const data = response.data;

      const state = getState();
      return {
        startups: {
          data: isInitialFetch ? data.data : state.cardsData.startups.data,
          count: data.users_count,
          filteredUsers: !isInitialFetch
            ? data.data
            : state.cardsData.startups.filteredUsers,
        },
      };
    } catch (error) {
      if (error.response.data.message === "Invalid token") {
        window.location.href = "/error-401";
      }
      let errorMessage = "Failed to fetch startups.";
      if (error.response && error.response.data) {
        errorMessage = error.response.data.message || errorMessage;
      }
      return rejectWithValue(errorMessage);
    } finally {
      controller.abort();
    }
  }
);

const cardsDataSlice = createSlice({
  name: "cardsData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStartupsCards.pending, (state) => {
        state.startupStatus = "loading";
      })
      .addCase(fetchStartupsCards.fulfilled, (state, action) => {
        state.startupStatus = "succeeded";
        state.startups = action.payload.startups;
      })
      .addCase(fetchStartupsCards.rejected, (state, action) => {
        state.startupStatus = "failed";
        state.startupError = action.payload;
      });
  },
});

export default cardsDataSlice.reducer;
