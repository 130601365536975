import React, { useState } from "react";
import { Button, Pagination } from "../../../components/ui/Button";

export const WithdrawReq = ({ withdrawReqs, cancelWithdraw }) => {
  const [page, setPage] = useState(1);
  return (
    <>
      <table className="table-auto w-full mt-2 border shadow-sm border-slate-300">
        <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
          <tr>
            <th className="p-3 font-medium text-sm">No.</th>
            <th className="p-3 font-medium text-sm whitespace-nowrap">
              Amount
            </th>
            <th className="p-3 font-medium text-sm whitespace-nowrap">Date</th>
            <th className="p-3 font-medium text-sm whitespace-nowrap">
              Status
            </th>
            <th className="p-3 font-medium text-sm whitespace-nowrap">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {withdrawReqs.length > 0 ? (
            withdrawReqs.slice(page * 7 - 7, page * 7).map((request, index) => (
              <tr
                key={request._id}
                className="bg-white border-b border-slate-300 align-middle h-auto"
              >
                <td className="p-3 text-sm font-medium">
                  {(page - 1) * 7 + index + 1}
                </td>
                <td className="p-3 text-sm">
                  <span className="font-medium mr-1">&#8377;</span>
                  {request.amount}
                </td>
                <td className="p-3 text-sm">{request.date}</td>
                <td className="p-3 text-sm">
                  <span
                    className={`px-2 py-0.5 ${
                      request.status === "0"
                        ? "blue-badge"
                        : request.status === "1"
                        ? "green-badge"
                        : "red-badge"
                    }`}
                  >
                    {request.status === "0"
                      ? "Processing"
                      : request.status === "1"
                      ? "Completed"
                      : "Rejected"}
                  </span>
                </td>
                <td className="p-3 text-sm">
                  {request.status === "0" ? (
                    <Button
                      className="px-1 py-0.5"
                      bgColor="bg-secondary"
                      onClick={() => cancelWithdraw(request._id)}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      className="disabled px-1 py-0.5"
                      bgColor="bg-secondary"
                      disabled
                    >
                      Cancel
                    </Button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr className="bg-white text-center">
              <td colSpan={5} className="p-3 text-sm">
                No agreement found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {withdrawReqs.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          totalDataCount={withdrawReqs.length}
        />
      )}
    </>
  );
};
