import React from "react";
import RegularAdminSidebar from "./regularAdminSidebar";
import ResponsiveAdminSidebar from "./responsiveAdminSidebar";
import useWindowDimensions from "../../hooks/useWindowDimension";
import "../../Pages/dashboard/dashboard.css";
import "./sidebar.css";

const AdminSidebar = ({ openSidebar, closeSidebar, style }) => {
  const { width } = useWindowDimensions();

  return (
    <>
      {openSidebar && width < 1024 && (
        <ResponsiveAdminSidebar closeSidebar={closeSidebar} style={style} />
      )}
      {width >= 1024 && <RegularAdminSidebar style={style} />}
    </>
  );
};

export default AdminSidebar;
