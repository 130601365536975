import { Button } from "../Button";
import { DeleteIcon, PenIcon } from "../../../icons/icons";
import useCheckout from "../../../hooks/useCheckout";
import PHcoin from "../../../images/PH-coin.svg";

const TopUpCard = ({
  data,
  role = "",
  updateModal,
  updateTopUp,
  handleDeleteModal,
  callbackUrl,
}) => {
  const { createCheckoutSession } = useCheckout();

  return (
    <div
      className={`relative card border rounded-md shadow-none ${
        role === "admin" ? "group hover:bg-slate-200" : ""
      }`}
    >
      {role === "admin" && (
        <label className="inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={data.plan_status === "0" ? false : true}
            onChange={() =>
              updateTopUp(data, data._id, data.plan_status === "0" ? "1" : "0")
            }
          />
          <div className="relative w-9 h-5 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
        </label>
      )}

      {role === "admin" && (
        <>
          <span
            className="hidden group-hover:inline absolute right-10 top-2 cursor-pointer"
            onClick={() => updateModal(data)}
          >
            <PenIcon fill="#12d512" />
          </span>
          <span
            className="hidden group-hover:inline absolute right-2 top-2 cursor-pointer"
            onClick={() => handleDeleteModal(data._id)}
          >
            <DeleteIcon />
          </span>
        </>
      )}
      <div className="mb-5 text-center">
        {data.discount_status === "0" ? (
          <>
            <div className="flex justify-center mb-2">
              <sup className="text-base text-medium mt-3 mb-0 me-1">₹</sup>
              <h4 className="font-medium text-4xl mb-0 text-indigo-500">
                {data.final_amount}
              </h4>
            </div>
            <div className="flex items-center justify-center gap-x-1">
              <p className="font-medium text-lg">{data.tokens}</p>
              <img src={PHcoin} alt="PH coin" />
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-center mb-2">
              <sup className="text-base text-medium mt-3 mb-0 me-1">₹</sup>
              <h4 className="font-medium text-4xl mb-0 text-indigo-500">
                {data.final_amount}
              </h4>
              <sub className="flex justify-center items-center opacity-75 ml-1 mt-3 text-sm">
                /
                <div className="flex justify-center items-center ml-1">
                  <span className="text-medium me-1">₹</span>
                  <h4 className="font-medium mb-0 text-indigo-500 line-through">
                    {data.amount}
                  </h4>
                </div>
              </sub>
            </div>
            <div className="flex items-center justify-center gap-x-1 mb-4">
              <p className="font-medium text-lg">{data.tokens}</p>
              <img src={PHcoin} alt="PH coin" />
            </div>
          </>
        )}
        {role.length < 1 &&
          (data.final_amount !== 0 ? (
            <Button
              onClick={() =>
                createCheckoutSession(
                  {
                    type: "top-up",
                    id: data._id,
                  },
                  callbackUrl
                )
              }
              className="absolute left-0 bottom-0 px-2 py-1 w-full mb-0 rounded-t-none"
            >
              Select
            </Button>
          ) : (
            <Button
              className="absolute left-0 bottom-0 px-2 py-1 w-full mb-0 rounded-t-none disabled"
              disabled
            >
              Default Plan
            </Button>
          ))}
      </div>
    </div>
  );
};

export default TopUpCard;
