import { PlayVideoIcon } from "../../../icons/icons";
import "./Card.css";

const WelcomeCard = ({ name }) => {
  return (
    <div className="welcome-card-bg space-y-2 md:space-y-0">
      <p className="font-medium text-white capitalize">Hello, {name}</p>
      <h6 className="text-base font-semibold">
        New to our platform ? Watch this tutorial
        <br /> explaining all about it.
      </h6>
      <div className="flex items-center gap-x-3">
        <a
          href="https://www.youtube.com/@PitchHere123"
          target="_blank"
          rel="noreferrer"
          className="px-3 py-1 md:py-2 text-xs rounded-full button bg-white text-black font-semibold flex items-center gap-x-1"
        >
          Tutorial{" "}
          <PlayVideoIcon className="bg-primary p-1 rounded-full" fill="#fff" />
        </a>
        <a
          href="https://pitchhere.ai/"
          target="_blank"
          rel="noreferrer"
          className="px-3 py-1 md:py-2 text-xs rounded-full button bg-white text-black font-semibold"
        >
          Know More
        </a>
      </div>
    </div>
  );
};

export default WelcomeCard;
