import { useToastContext } from "../context/ToastContext";
import useFetch from "./useFetch";
import useLocalStorage from "./useLocalStorage";
import useNotificationApi from "./useNotificationApi";

const useUnfollowConnection = () => {
  const unfollowConnectionApi = `${process.env.REACT_APP_API_URL}unfollow-connection`;

  const { fetchApi } = useFetch();
  const { token, name } = useLocalStorage();
  const { sendNotification } = useNotificationApi();
  const toast = useToastContext();

  const unFollow = (id, setConnectionStatus) => {
    fetchApi(
      {
        url: unfollowConnectionApi,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          connection_id: id,
        },
      },
      () => {
        toast.open("true", "Connection removed successfully!");
        setConnectionStatus((prev) => ({ ...prev, status: "disconnect" }));
        sendNotification(id, `Connection removed by ${name}`, "connections");
      }
    );
  };
  return { unFollow };
};

export default useUnfollowConnection;
