import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useUnSuspendApi from "../../../hooks/useUnSuspendApi";
import useNotificationApi from "../../../hooks/useNotificationApi";
import { useToastContext } from "../../../context/ToastContext";
import InvestorDetails from "./details/investorDetails";
import MentorDetails from "./details/mentorDetails";
import VendorDetails from "./details/vendorDetails";
import StartupDetails from "./details/startupDetails";
import AdminSidebar from "../../../components/layout/adminSidebar";
import AdminNavbar from "../../../components/layout/adminNavbar";
import { Error } from "../../../components/ui/Error";
import { Loader } from "../../../components/ui/Loader";
import { SuspendModal } from "../../../components/ui/modals/utility-modals/suspendModal";
import { ConfirmationModal } from "../../../components/ui/modals/utility-modals/confirmationModal";

const UserDetails = () => {
  const userDetailsApi = `${process.env.REACT_APP_API_URL}user`;
  const platformVerificationApi = `${process.env.REACT_APP_API_URL}platform-verify`;
  const startupLevelApi = `${process.env.REACT_APP_API_URL}startup-level`;

  const { fetchApi, isLoading, error } = useFetch();
  const { token } = useLocalStorage();
  const { unSuspend } = useUnSuspendApi();
  const location = useLocation();
  const { sendNotification } = useNotificationApi();
  const toast = useToastContext();
  const { id } = useParams();

  const [details, setDetails] = useState({});
  const [openSidebar, setOpenSidebar] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    callback: null,
    message: "",
  });

  const getUserDetails = useCallback(() => {
    fetchApi(
      {
        url: userDetailsApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          user_id: id,
          role: location.state.role,
        },
      },
      (data) => {
        setDetails(data);
      }
    );
  }, [userDetailsApi, fetchApi, token, location, id]);

  const platformVerifyUser = useCallback(() => {
    fetchApi(
      {
        url: platformVerificationApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          id: id,
        },
      },
      () => {
        toast.open("true", "Verification successful");
        sendNotification(
          id,
          `Your profile has been successfully verified by PitchHere`,
          "admin"
        );
        getUserDetails();
      }
    );
  }, [
    fetchApi,
    token,
    platformVerificationApi,
    toast,
    sendNotification,
    getUserDetails,
    id,
  ]);

  const startupLevel = useCallback(
    (level) => {
      fetchApi(
        {
          url: startupLevelApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            user_id: id,
            level: level,
          },
        },
        () => {
          toast.open("true", "Mentorship level updated successfully!");
          getUserDetails();
        }
      );
    },
    [startupLevelApi, fetchApi, token, toast, getUserDetails, id]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      getUserDetails();
    }, 10);
    return () => {
      clearTimeout(timer);
    };
  }, [getUserDetails]);

  const conditionalUserRender = () => {
    if (location.state.role === "f@!3A") {
      return (
        <StartupDetails
          details={details}
          startupLevel={startupLevel}
          handleConfirmationModal={handleConfirmationModal}
        />
      );
    }
    if (location.state.role === "R$7s2") {
      return (
        <MentorDetails
          details={details}
          handleConfirmationModal={handleConfirmationModal}
        />
      );
    }
    if (location.state.role === "Sw%aa") {
      return (
        <InvestorDetails
          details={details}
          handleConfirmationModal={handleConfirmationModal}
        />
      );
    } else {
      return (
        <VendorDetails
          details={details}
          handleConfirmationModal={handleConfirmationModal}
        />
      );
    }
  };

  const handleConfirmationModal = (type) => {
    if (type === "suspend") {
      setOpen(true);
    } else if (type === "unsuspend") {
      setConfirmationModal({
        open: true,
        callback: () => unSuspend(id, getUserDetails),
        message: "Are you sure you want to un-suspend this user?",
      });
    } else if (type === "verify" || type === "certify") {
      setConfirmationModal({
        open: true,
        callback: () => platformVerifyUser(),
        message: `Are you sure you want to platform ${type} this user?`,
      });
    }
  };

  return (
    <div className="grid grid-cols-5">
      <AdminSidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <AdminNavbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="p-4">
          {isLoading && <Loader />}
          {error && <Error />}
          {!isLoading && !error && details && conditionalUserRender()}
        </section>
      </div>
      <SuspendModal
        open={open}
        onClose={() => setOpen(false)}
        id={id}
        callBackFn={getUserDetails}
      />
      <ConfirmationModal
        open={confirmationModal.open}
        onClose={() =>
          setConfirmationModal((prev) => ({ ...prev, open: false }))
        }
        message={confirmationModal.message}
        callBackFn={confirmationModal.callback}
      />
    </div>
  );
};

export default UserDetails;
