import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useLocalStorage = () => {
  const navigate = useNavigate();

  const token = useSelector((state) => {
    if (state.users.length > 0) {
      return state.users[0].id;
    }
  });
  const userRole = useSelector((state) => {
    if (state.users.length > 0) {
      return state.users[0].role;
    }
  });
  const name = useSelector((state) => {
    if (state.users.length > 0) {
      return state.users[0].name;
    }
  });

  useEffect(() => {
    if (!token || !userRole || !name) {
      navigate("/error-401");
    }
  }, [token, userRole, name, navigate]);

  return { token, userRole, name };
};

export default useLocalStorage;
