import useFetch from "./useFetch";
import useLocalStorage from "./useLocalStorage";

const useDisplayFiles = () => {
  const displayFilesApi = `${process.env.REACT_APP_API_URL}display-files`;

  const { fetchApi } = useFetch();
  const { token } = useLocalStorage();

  const displayFiles = (fileData, id) => {
    fetchApi(
      {
        url: displayFilesApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          user_id: id,
        },
      },
      (data) => {
        fileData(data);
      }
    );
  };
  return { displayFiles };
};

export default useDisplayFiles;
