import { useEffect } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import useAddDescription from "../../../../hooks/useAddDescription";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import { Button } from "../../Button";

export const DescriptionModal = ({ open, onClose, callBackFn, url, data }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: {
      description: data,
    },
  });
  const { userRole } = useLocalStorage();
  const { addDescription } = useAddDescription();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  if (!open) return null;

  const submitModal = (data) => {
    if (userRole === "f@!3A") {
      addDescription(url, { description: data.description }, callBackFn);
    } else if (userRole === "R$7s2") {
      addDescription(url, { short_description: data.description }, callBackFn);
    } else {
      addDescription(url, { description: data.description }, callBackFn);
    }
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`min-w-[90%] md:min-w-[550px] bg-white rounded-xl shadow p-3 pb-0 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading font-semibold text-center border-b p-3 text-base">
          Add Description
        </h4>
        <form onSubmit={handleSubmit(submitModal)} className="p-3 mt-5">
          <textarea
            name="description"
            id="description"
            cols="30"
            rows="7"
            placeholder="Write here..."
            className="border p-1.5 rounded-md"
            {...register("description", {
              required: "Description is required",
              minLength: {
                value: 20,
                message: "Description must be atleast 20 characters",
              },
            })}
          ></textarea>
          {errors.description && (
            <span className="my-1 text-secondary text-xs">
              {errors.description.message}
            </span>
          )}
          <div className="flex justify-end p-2">
            <Button
              className="px-2 py-1 mx-1 text-sm"
              bgColor="bg-secondary"
              onClick={onClose}
            >
              Close
            </Button>
            <Button type="submit" className="px-2 py-1 mx-1 text-sm">
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
