import { useNavigate } from "react-router-dom";
import useFetch from "./useFetch";
import useLocalStorage from "./useLocalStorage";

const useSuspendApi = () => {
  const suspendAccountApi = `${process.env.REACT_APP_API_URL}suspend-account`;

  const { fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const navigate = useNavigate();

  const Suspend = (id, reason, callBackFn) => {
    if (userRole !== "admin") {
      navigate("/error-403");
      return;
    }
    fetchApi(
      {
        url: suspendAccountApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          user_id: id,
          reason: reason,
        },
      },
      () => {
        callBackFn();
      }
    );
  };
  return { Suspend };
};
export default useSuspendApi;
