import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import useToken from "../../hooks/useLocalStorage";
import useFetchRoom from "../../hooks/useFetchRoom";
import useFetch from "../../hooks/useFetch";
import { updateStatus } from "../../store/slices/ChatSlice";
import { useToastContext } from "../../context/ToastContext";
import socket from "../../utils/socketConnection";
import { MyMessage, OtherMessage } from "./Message";
import "./chat.css";
import FavIcon from "../../images/favicon.png";
import {
  AttachmentIcon,
  CircleXmarkIcon,
  VideoOnIcon,
  // VideoOnIcon,
} from "../../icons/icons";
import { getCurrentDate } from "../../utils/getCurrentDate";
import { Button } from "../../components/ui/Button";

const Chat = ({ userId, refresh, handleMeetingModal }) => {
  const sendMessageApi = `${process.env.REACT_APP_API_URL}send-message`;
  const getMessagesApi = `${process.env.REACT_APP_API_URL}get-messages`;

  const { token, userRole } = useToken();
  const { roomData } = useFetchRoom();
  const { fetchApi } = useFetch();
  const toast = useToastContext();
  const dispatch = useDispatch();
  const location = useLocation();

  const date = new Date();
  const showTime =
    date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

  const [newMessage, setNewMessage] = useState("");
  const [messagesList, setMessagesList] = useState([]);
  const [connectionData, setConnectionData] = useState({
    name: "",
    image: "",
    fees: 0,
  });
  const [attachment, setAttachment] = useState();
  const lastMessageRef = useRef(null);
  const runOnce = useRef(false);

  const send = async (type) => {
    if (type === "text") {
      socket.emit("send message", {
        senderId: userId,
        recieverId: roomData.recieverId,
        message: newMessage,
        room: roomData.roomId,
        date: getCurrentDate(),
        time: showTime,
        type: "text",
        status: "0",
      });
      setNewMessage("");
      try {
        var res = await fetch(sendMessageApi, {
          method: "POST",
          headers: { "Content-Type": "application/json", "x-token": token },
          body: JSON.stringify({
            reciever_id: roomData.recieverId,
            message: newMessage,
            room: roomData.roomId,
            date: getCurrentDate(),
            time: showTime,
            type: "text",
            status: "0",
          }),
        });
        var data = await res.json();
      } catch (error) {
        toast.open("false", "Failed to send message");
      }
      if (data.status === 200) {
        if (data.message !== "message delivered") {
          dispatch(updateStatus(data.connection_status));
          toast.open("warning", "You are not connected to this user anymore");
          refresh();
          return;
        }
      }
    } else {
      const formData = new FormData();
      formData.append("file", attachment);
      formData.append("sender_id", userId);
      formData.append("reciever_id", roomData.recieverId);
      formData.append("room", roomData.roomId);
      formData.append("date", getCurrentDate());
      formData.append("time", showTime);
      formData.append("type", "file");
      formData.append("status", "0");
      axios
        .post(sendMessageApi, formData, {
          headers: { "x-token": token },
        })
        .then((data) => {
          setAttachment(null);
          if (data.data.status === 200) {
            if (data.data.message !== "message delivered") {
              dispatch(updateStatus(data.connection_status));
              refresh();
              return;
            }
            socket.emit("send message", {
              senderId: userId,
              recieverId: roomData.recieverId,
              message: data.data.path,
              room: roomData.roomId,
              date: getCurrentDate(),
              time: showTime,
              type: "file",
              status: "0",
            });
          }
        })
        .catch((err) => {
          if (err.response.status) {
            toast.open(
              "warning",
              "File must be of type ( .png, .jpg, .jpeg, pdf )"
            );
          }
        });
    }
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (roomData.status !== "1" || roomData.deactivated === "1") {
      toast.open("warning", "You cannot send message to this user anymore");
      return;
    }
    if (newMessage.length > 0 || attachment) {
      if (newMessage.length > 0) {
        send("text");
      } else {
        send("file");
      }
    } else {
      toast.open("false", "Cannot send empty message");
    }
  };

  const fetchMessages = useCallback(async () => {
    fetchApi(
      {
        url: getMessagesApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          reciever_id: roomData.recieverId,
        },
      },
      (data) => {
        setMessagesList(data.messages);
        setConnectionData({
          name: data.name,
          image: data.logo,
          fees: data.mentor_fee,
        });
      }
    );
  }, [fetchApi, getMessagesApi, token, roomData.recieverId]);

  useEffect(() => {
    if (!runOnce.current) {
      socket.emit("user disconnect", { userId });
      if (roomData !== undefined) {
        socket.emit("join room", { roomId: roomData.roomId, userId });
      }
    }
    const timer = setTimeout(() => {
      fetchMessages();
    });
    return () => {
      runOnce.current = true;
      clearTimeout(timer);
    };
  }, [token, roomData, userId, location, fetchMessages]);

  useEffect(() => {
    socket.on("my message", (data) => {
      setMessagesList((prev) => [
        ...prev,
        {
          sender_id: data.senderId,
          reciever_id: data.recieverId,
          message: data.message,
          room: data.room,
          date: data.date,
          time: data.time,
          message_type: data.type,
          status: data.status,
        },
      ]);
    });
    socket.on("user message", (data) => {
      setMessagesList((prev) => [
        ...prev,
        {
          sender_id: data.senderId,
          reciever_id: data.recieverId,
          message: data.message,
          room: data.room,
          date: data.date,
          time: data.time,
          message_type: data.type,
          status: data.status,
        },
      ]);
    });
    lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
    return () => {
      socket.removeAllListeners();
    };
  }, [messagesList]);

  return (
    <div className="m-0 bg-slate-500 md:shadow-md chat-container">
      <div className="hidden md:flex items-center justify-between border-b p-2 text-white">
        <div className="flex items-center justify-between">
          <img
            src={
              connectionData.image.length > 0 ? connectionData.image : FavIcon
            }
            alt="reciever-img"
            width={45}
            className="h-[45px] rounded-full me-2"
          />
          <span>{connectionData.name}</span>
        </div>
        {userRole !== "V&iR8" &&
          userRole !== "R$7s2" &&
          roomData.recieverRole !== "V&iR8" && (
            <Link to="/meetings" title="Schedule a Meeting">
              <VideoOnIcon width="22px" height="22px" />
            </Link>
          )}
      </div>
      <div className="pt-3 px-2 bg-white cstm-chat-height overflow-auto">
        <ul className="grid grid-cols-4">
          {messagesList.map((message) =>
            message.sender_id !== roomData.recieverId ? (
              <MyMessage key={message.time + message.date} content={message} />
            ) : (
              <OtherMessage
                key={message.time + message.date}
                content={message}
              />
            )
          )}
          <li ref={lastMessageRef}></li>
        </ul>
      </div>
      {roomData.status === "1" && (
        <form
          onSubmit={sendMessage}
          className="grid grid-cols-6 w-full bg-white absolute bottom-0 md:relative"
        >
          <div className="col-span-5 flex items-center border">
            {newMessage.length > 0 && !attachment && roomData.status === "1" ? (
              <>
                <input
                  className="p-2.5 w-11/12"
                  id="message"
                  type="text"
                  name="message"
                  placeholder="Type a message......"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                />
                <AttachmentIcon className="disabled" />
              </>
            ) : newMessage.length < 1 &&
              attachment &&
              roomData.status === "1" ? (
              <>
                <div className="w-11/12 px-2 text-sm">
                  <span className="relative bg-gray-300 p-1 rounded">
                    {attachment.name}
                    <CircleXmarkIcon
                      className="absolute -right-1 -top-1 cursor-pointer"
                      onClick={() => setAttachment()}
                    />
                  </span>
                </div>
                <input
                  type="file"
                  id="attachment"
                  accept="application/pdf,image/png,image/jpeg"
                  onChange={(e) => setAttachment(e.target.files[0])}
                  hidden
                />
                <label htmlFor="attachment" className="mb-0 cursor-pointer">
                  <AttachmentIcon />
                </label>
              </>
            ) : newMessage.length < 1 &&
              !attachment &&
              roomData.status === "1" ? (
              <>
                <input
                  className="p-2.5 w-11/12"
                  id="message"
                  type="text"
                  name="message"
                  placeholder="Type a message......"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                />
                <input
                  type="file"
                  id="attachment"
                  accept="application/pdf,image/png,image/jpeg"
                  onChange={(e) => setAttachment(e.target.files[0])}
                  hidden
                />
                <label htmlFor="attachment" className="mb-0 cursor-pointer">
                  <AttachmentIcon />
                </label>
              </>
            ) : null}
          </div>
          <Button
            className={`p-2.5 rounded-none ${
              roomData.status !== "1" ? "disabled" : ""
            }`}
            type="submit"
          >
            Send
          </Button>
        </form>
      )}

      {(roomData.status !== "1" || roomData.deactivated === "1") && (
        <p className="shadow p-1 rounded-md text-sm bg-[#f6f6f6]">
          {roomData.status === "-1"
            ? "You are not connected with this user anymore. For more details check your connection status with this user."
            : roomData.status === "-2"
            ? "Cannot send message to this user anymore. For more details check your connection status with this user."
            : roomData.deactivated === "1"
            ? "This account has been deactivated. For more details check your connection status with this user."
            : null}
          <Link
            to={
              userRole === "f@!3A"
                ? "/startup-connections"
                : userRole === "R$7s2"
                ? "/mentor-connections"
                : userRole === "Sw%aa"
                ? "/investor-connections"
                : null
            }
            className="text-blue-500 ps-1"
          >
            (Connections)
          </Link>
        </p>
      )}
    </div>
  );
};

export default Chat;
