import { Button } from "../../components/ui/Button";
import FavIcon from "../../images/favicon.png";

const RecentStartups = ({ data, ViewProfile, userId }) => {
  return (
    <div className="single-row">
      <div className="flex items-center">
        <div className="h-[45px] border flex items-center rounded-full">
          <img
            src={data.logo.length > 0 ? data.logo : FavIcon}
            alt="profile-img"
            width={45}
            className="h-full rounded-full p-[2px]"
          />
        </div>
        <div className="ps-2 flex flex-col">
          <span
            className="px-1 text-md font-medium capitalize w-fit hover:cursor-pointer"
            onClick={() =>
              ViewProfile(
                userId,
                data.role === "f@!3A" || data.role === "V&iR8"
                  ? data.company_name
                  : data.name,
                data.role
              )
            }
          >
            {data.company_name}
          </span>
          {/* <span className="text-sm font-medium capitalize">{data.name}</span> */}
        </div>
      </div>
      <Button
        className="px-3 py-1 !rounded-full text-xs hover:cursor-pointer"
        onClick={() =>
          ViewProfile(
            userId,
            data.role === "f@!3A" || data.role === "V&iR8"
              ? data.company_name
              : data.name,
            data.role
          )
        }
      >
        View
      </Button>
    </div>
  );
};

export default RecentStartups;
