import { useEffect, useState } from "react";
import ReactDom from "react-dom";
import { Button } from "../../Button";
import { FormError } from "../../FormError";
import "../../../../custom.css";
import { Dropdown } from "../../inputs/dropdown";

export const EditSampleFileModal = ({
  open,
  onClose,
  callBackFn,
  existingData,
}) => {
  const initalState = {
    title: "",
    type: "",
    description: "",
    tags: "",
    status: 0,
    thumbnail: null,
    file: null,
  };
  const [fileData, setFileData] = useState(initalState);
  const [error, setError] = useState({ name: "", message: "" });

  useEffect(() => {
    setFileData({
      title: existingData?.title,
      type: existingData?.document_type,
      description: existingData?.description,
      tags: existingData?.tags,
      status: existingData?.status,
      thumbnail: existingData?.thumbnail_name,
      file: existingData?.file_name,
    });
  }, [existingData]);

  if (!open) return null;

  const submitModal = (e) => {
    e.preventDefault();
    if (!fileData.title) {
      setError({ name: "title", message: "title is required" });
      return;
    }
    if (!fileData.type) {
      setError({ name: "type", message: "document type is required" });
      return;
    }
    if (!fileData.description) {
      setError({
        name: "description",
        message: "document description is required",
      });
      return;
    }
    if (!fileData.tags) {
      setError({ name: "tags", message: "document tags are required" });
      return;
    }
    if (!fileData.thumbnail) {
      setError({ name: "thumbnail", message: "thumbnail is required" });
      return;
    }
    if (!fileData.file) {
      setError({ name: "file", message: "file is required" });
      return;
    }
    callBackFn(fileData, existingData);
    setFileData(initalState);
    onClose();
  };

  const options = [
    {
      label: "Pitchdeck",
      value: "Pitchdeck",
    },
    {
      label: "PPT",
      value: "PPT",
    },
  ];

  const statusOptions = [
    {
      label: "Inactive",
      value: 0,
    },
    {
      label: "Active",
      value: 1,
    },
  ];

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`min-w-[90%] md:min-w-[400px] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading font-semibold text-center border-b p-3 text-base">
          Edit sample document
        </h4>
        <form
          onSubmit={submitModal}
          className="grid sm:grid-cols-2 p-3 gap-3 text-left"
        >
          <div>
            <label className="mb-0 text-sm required" htmlFor="title">
              Document Title
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="border-0 w-full p-3"
                id="title"
                name="title"
                placeholder="Enter Title"
                value={fileData.title}
                onChange={(e) =>
                  setFileData((prev) => ({ ...prev, title: e.target.value }))
                }
                required
              />
            </div>
            {error.name === "title" && (
              <div>
                <FormError message={error.message} />
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <label htmlFor="documentType" className="mb-0 text-sm required">
              Document Type
            </label>
            <Dropdown
              key={`documentType`}
              name="documentType"
              id="documentType"
              options={options}
              value={{
                label: fileData.type,
                value: fileData.type,
              }}
              onChange={(selectedOption) =>
                setFileData((prev) => ({
                  ...prev,
                  type: selectedOption ? selectedOption.value : "",
                }))
              }
              closeMenuOnSelect={true}
              isClearable={true}
              required
            />
            {error.name === "type" && (
              <div>
                <FormError message={error.message} />
              </div>
            )}
          </div>
          <div className="sm:col-span-2">
            <label className="mb-0 text-sm required" htmlFor="description">
              Document Description
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <textarea
                cols="30"
                rows="3"
                minLength={50}
                maxLength={100}
                className="border-0 w-full p-3"
                id="description"
                name="description"
                placeholder="Enter description"
                value={fileData.description}
                onChange={(e) =>
                  setFileData((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
                required
              ></textarea>
            </div>
            {error.name === "description" && (
              <div>
                <FormError message={error.message} />
              </div>
            )}
          </div>
          <div>
            <label className="mb-0 text-sm required" htmlFor="tags">
              Document Tags
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="border-0 w-full p-3"
                id="tags"
                name="tags"
                placeholder="Enter tags (comma separated)"
                value={fileData.tags}
                onChange={(e) =>
                  setFileData((prev) => ({
                    ...prev,
                    tags: e.target.value,
                  }))
                }
                required
              />
            </div>
            {error.name === "tags" && (
              <div>
                <FormError message={error.message} />
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <label htmlFor="status" className="mb-0 text-sm required">
              Status
            </label>
            <Dropdown
              key={`status`}
              name="status"
              id="status"
              options={statusOptions}
              value={{
                label: fileData.type === 0 ? "Inactive" : "Active",
                value: fileData.type,
              }}
              onChange={(selectedOption) =>
                setFileData((prev) => ({
                  ...prev,
                  type: selectedOption ? selectedOption.value : 0,
                }))
              }
              closeMenuOnSelect={true}
            />
            {error.name === "status" && (
              <div>
                <FormError message={error.message} />
              </div>
            )}
          </div>
          <div>
            <label className="mb-0 text-sm required" htmlFor="upload-thumbnail">
              Upload Thumbnail
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="border-0 w-full p-3"
                id="upload-thumbnail-display"
                name="upload-thumbnail-display"
                placeholder="Select a thumbnail"
                value={
                  fileData.thumbnail
                    ? fileData.thumbnail.name || fileData.thumbnail
                    : ""
                }
                readOnly
              />
              <input
                type="file"
                className="hidden"
                id="upload-thumbnail"
                name="upload-thumbnail"
                onChange={(e) =>
                  setFileData((prev) => ({
                    ...prev,
                    thumbnail: e.target.files[0],
                  }))
                }
              />
              <label
                htmlFor="upload-thumbnail"
                className="mb-0 p-3 bg-gray-200 text-sm cursor-pointer"
              >
                Browse
              </label>
            </div>
            {error.name === "thumbnail" && (
              <div>
                <FormError message={error.message} />
              </div>
            )}
          </div>
          <div>
            <label htmlFor="upload-file" className="mb-0 text-sm required">
              Upload File
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="border-0 w-full p-3"
                id="upload-file-display"
                name="upload-file-display"
                placeholder="Select a file"
                value={fileData.file ? fileData.file.name || fileData.file : ""}
                readOnly
              />
              <input
                type="file"
                className="hidden"
                id="upload-file"
                name="upload-file"
                onChange={(e) =>
                  setFileData((prev) => ({
                    ...prev,
                    file: e.target.files[0],
                  }))
                }
              />
              <label
                htmlFor="upload-file"
                className="mb-0 p-3 bg-gray-200 text-sm cursor-pointer"
              >
                Browse
              </label>
            </div>
            {error.name === "file" && (
              <div>
                <FormError message={error.message} />
              </div>
            )}
          </div>
          <div className="sm:col-span-2 modalFooter">
            <Button
              className="px-3 py-1 mx-1"
              bgColor="bg-secondary"
              onClick={onClose}
            >
              Close
            </Button>
            <Button type="submit" className="px-3 py-1">
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
