import { useState } from "react";
import ReactDom from "react-dom";
import { Button } from "../../Button";
import { urlRegex } from "../../../../constants";
import { GlobeIcon } from "../../../../icons/icons";

export const AddPortfolioModal = ({ open, onClose, vendorPortfolio, url }) => {
  const [portfolioFile, setPortfolioFile] = useState();
  const [vendorUrl, setVendorUrl] = useState(url);

  if (!open) return null;

  const submitModal = () => {
    document.getElementById("empty-inputs").classList.add("hidden");
    document.getElementById("invalid-url").classList.add("hidden");
    if (portfolioFile === undefined && vendorUrl === "") {
      document.getElementById("empty-inputs").classList.remove("hidden");
      return;
    }
    if (vendorUrl.length > 0 && !urlRegex.test(vendorUrl)) {
      document.getElementById("invalid-url").classList.remove("hidden");
      return;
    }
    vendorPortfolio(vendorUrl, portfolioFile, setPortfolioFile);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`min-[90%] md:w-[400px] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="py-2 border-b text-center font-semibold text-base">
          Company Portfolio
        </h4>
        <form className="p-2 pb-0">
          <span id="empty-inputs" className="hidden text-secondary text-xs">
            Please fill atleast one field before submitting
          </span>
          <span id="invalid-url" className="hidden text-secondary text-xs">
            Invalid website url
          </span>
          <div className="mb-1">
            <label htmlFor="vendorWebsite" className="mb-0 text-sm">
              Portfolio website
            </label>
            <div
              className="flex mb-3 rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="border-0 w-full ps-3"
                id="vendorWebsite"
                name="vendorWebsite"
                value={vendorUrl}
                onChange={(e) => setVendorUrl(e.target.value)}
                placeholder="Enter full website address"
                autoFocus
              />
              <span className="input-icons icon-span">
                <GlobeIcon />
              </span>
            </div>
          </div>
          <div>
            <p className="mb-0">Upload Images or PDF's</p>
            <label
              htmlFor="uploadPortfolio"
              className="p-1 rounded-md bg-primary text-white text-sm cursor-pointer"
            >
              Upload
            </label>
            <input
              type="file"
              id="uploadPortfolio"
              name="uploadPortfolio"
              accept="image/png,image/jpeg,application/pdf"
              className="hidden"
              onChange={(e) => setPortfolioFile(e.target.files[0])}
            />
            <span className="ms-2">{portfolioFile && portfolioFile.name}</span>
          </div>
          <div className="col-start-2 text-right p-2">
            <Button
              onClick={onClose}
              className="px-1 py-0.5 mx-1"
              bgColor="bg-secondary"
            >
              Close
            </Button>
            <Button onClick={submitModal} className="px-1 py-0.5 mx-1">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
