import { CircleCheck } from "../../icons/icons";

export const EmailSuccess = ({ backToLogin }) => {
  return (
    <div
      className="flex flex-col col-span-7 authentication-bg relative text-center pt-14 md:pt-0 md:justify-center md:col-start-2 md:col-span-5 md:items-center pb-3 px-4 md:px-0 md:py-0 md:my-3 lg:col-span-2 lg:my-0 lg:py-0"
      id="confirmationText"
    >
      <div className="mx-auto pt-5 md:p-6 md:rounded-md md:shadow-md md:w-full bg-white lg:p-3 lg:shadow-none lg:rounded-none lg:bg-transparent lg:pt-0">
        <CircleCheck
          width="70px"
          height="70px"
          fill="#1bcf1b"
          className="mx-auto mb-4"
        />
        <h3 className="font-medium mb-2">Check your email!</h3>
        <p>
          An mail has been sent to your registered email address containing link
          to reset your password.
        </p>
        <p className="mt-2">
          Go Back to
          <span
            className="ps-1 text-blue-500 cursor-pointer underline underline-offset-1"
            onClick={backToLogin}
          >
            Login
          </span>
        </p>
      </div>
    </div>
  );
};
