import { Link } from "react-router-dom";
import AdminSidebarMain from "./adminSidebarMain";
import "../../Pages/admin-portal/admin-dashboard/adminDashboard.css";
import pitchHereLogo from "../../images/pitch-here-logo.png";

const RegularAdminSidebar = () => {
  const currPath = window.location.pathname;
  const activeStyle =
    "py-2.5 my-1 flex items-center px-2 bg-primary text-white hover:text-white rounded-md cursor-pointer";
  const regularStyle =
    "py-2.5 my-1 flex items-center px-2 rounded-md cursor-pointer hover:bg-customOrange hover:text-white";

  return (
    <div
      className="left-half h-full bg-[#f0f8ff] text-left shadow-lg"
      id="sidebar"
    >
      <div className="flex items-center justify-center py-2">
        <Link to="#">
          <img src={pitchHereLogo} alt="logo" width={200} />
        </Link>
      </div>
      <nav className="sidebar-main">
        <div className="sidebar-inner">
          <AdminSidebarMain
            currPath={currPath}
            activeStyle={activeStyle}
            regularStyle={regularStyle}
          />
        </div>
      </nav>
      <Link
        to="/admin"
        className="bg-customOrange text-white p-2 rounded-md w-full block text-center"
      >
        <span className="ps-2 tracking-normal">Logout</span>
      </Link>
    </div>
  );
};

export default RegularAdminSidebar;
