import useFetch from "./useFetch";
import useLocalStorage from "./useLocalStorage";

const useVerificationApi = () => {
  const verificationApi = `${process.env.REACT_APP_API_URL}verification`;

  const { fetchApi } = useFetch();
  const { token } = useLocalStorage();

  const verification = (id, type, setStatus) => {
    fetchApi(
      {
        url: verificationApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          connection_id: id,
          type: type,
        },
      },
      (data) => {
        if (data.message === "yes") {
          setStatus(true);
        }
      }
    );
  };
  return { verification };
};

export default useVerificationApi;
