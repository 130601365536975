import React, { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useFetch from "../../../hooks/useFetch";
import useNotificationApi from "../../../hooks/useNotificationApi";
import { useToastContext } from "../../../context/ToastContext";
import { SignedAgreements } from "./signedAgreements";
import { AllAgreements } from "./allAgreements";
import { UnSignedAgreements } from "./unsignedAgreements";
import { Loader } from "../../../components/ui/Loader";
import { Error } from "../../../components/ui/Error";
import Navbar from "../../../components/layout/Navbar";
import Sidebar from "../../../components/layout/Sidebar";
import { EditAgreementModal } from "../../../components/ui/modals/investment/edtiAgreementModal";
import { WithdrawInvestment } from "../../../components/ui/modals/investment/withdrawInvestment";
import { ViewInvestmentHistory } from "../../../components/ui/modals/investment/viewInvestmentHistory";
import { WithdrawReq } from "./withdrawReq";
import { Button } from "../../../components/ui/Button";
import { Dropdown } from "../../../components/ui/inputs/dropdown";
import { getCurrentDate } from "../../../utils/getCurrentDate";

export const StartupInvestments = () => {
  const getAgreementsApi = `${process.env.REACT_APP_API_URL}get-agreements`;
  const editAgreementApi = `${process.env.REACT_APP_API_URL}edit-agreement`;
  const getWithdrawReqApi = `${process.env.REACT_APP_API_URL}get-withdraw-requests`;
  const withdrawInvestmentApi = `${process.env.REACT_APP_API_URL}startup-withdraw-request`;
  const cancelWithdrawApi = `${process.env.REACT_APP_API_URL}cancel-startup-withdraw-request`;

  const navigate = useNavigate();
  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole, name } = useLocalStorage();
  const toast = useToastContext();

  const ref = useRef(true);
  const { sendNotification } = useNotificationApi();
  const [agreementDetails, setAgreementDetails] = useState([]);
  const [accountDetails, setAccountDetails] = useState({
    balance: 0,
    debit: 0,
    total: 0,
  });
  const [withdrawReqs, setWithdrawReqs] = useState([]);
  const [propDetails, setPropDetails] = useState({});
  const [tableType, setTableType] = useState("All");
  const [openSidebar, setOpenSidebar] = useState(false);
  const [open, setOpen] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [investmentData, setInvestmentData] = useState();

  const getAgreements = useCallback(() => {
    fetchApi(
      {
        url: getAgreementsApi,
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setAgreementDetails(data.agreement_details);
        setAccountDetails({
          balance: data.balance,
          debit: data.debitedAmount,
          total: data.raised_amount,
          withdrawable: data.total_amount,
        });
      }
    );
  }, [getAgreementsApi, token, fetchApi]);

  const getWithdrawReq = useCallback(() => {
    fetchApi(
      {
        url: getWithdrawReqApi,
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setWithdrawReqs(data.withdraw_requests);
      }
    );
  }, [getWithdrawReqApi, token, fetchApi]);

  const handleFilter = useCallback(
    (value) => {
      setTableType(value);
      if (value === "Withdraw Requests") {
        getWithdrawReq();
      }
    },
    [getWithdrawReq]
  );

  const editAgreement = useCallback(
    (agreementId, data, userId) => {
      fetchApi(
        {
          url: editAgreementApi,
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            id: agreementId,
            amount: data.editAmount,
          },
        },
        (data) => {
          if (data.message === "agreement not found") {
            toast.open("false", "Update failed. Retry.");
          } else {
            toast.open("true", "Update successfull.");
            sendNotification(
              userId,
              `${name} has edited the agreement. Please check details`,
              "investments"
            );
            getAgreements();
          }
        }
      );
    },
    [
      editAgreementApi,
      token,
      name,
      fetchApi,
      getAgreements,
      sendNotification,
      toast,
    ]
  );

  const withdrawInvestment = useCallback(
    (amount) => {
      fetchApi(
        {
          url: withdrawInvestmentApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            withdraw_amount: amount,
            date: getCurrentDate(),
          },
        },
        (data) => {
          if (data.message === "bank details not found") {
            toast.open(
              "warning",
              "Please enter bank details before withdrawing"
            );
            navigate("/startup-profile");
            return;
          }
          toast.open("true", "Withdraw request has been sent successfully!");
          handleFilter("Withdraw Requests");
        }
      );
    },
    [fetchApi, token, withdrawInvestmentApi, toast, navigate, handleFilter]
  );

  const cancelWithdraw = useCallback(
    (id) => {
      fetchApi(
        {
          url: cancelWithdrawApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            id: id,
          },
        },
        (data) => {
          if (data.message === "approved") {
            toast.open(
              "warning",
              "This withdraw request has already been approved by admin. Cannot cancel now."
            );
            return;
          }
          toast.open("true", "Withdraw request cancelled successfully!.");
          getAgreements();
          handleFilter("Withdraw Requests");
        }
      );
    },
    [fetchApi, token, cancelWithdrawApi, toast, getAgreements, handleFilter]
  );

  useEffect(() => {
    if (userRole !== "f@!3A") {
      navigate("/");
      return;
    }
    if (ref.current) {
      getAgreements();
    }
    return () => {
      ref.current = false;
    };
  }, [getAgreements, userRole, navigate]);

  const handleEditModal = (amount, agreementId, userId) => {
    setPropDetails({
      amount: amount,
      agreementId: agreementId,
      userId: userId,
    });
    setOpen(true);
  };

  const viewAgreement = (agreement) => {
    navigate("/view-agreement", {
      state: {
        agreement: agreement,
      },
    });
  };

  const openHistoryModal = (data) => {
    setInvestmentData(data);
    setHistoryModal(true);
  };

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="p-3 md:p-6">
          <div className="flex justify-end">
            <Button
              onClick={() => setWithdrawModal(true)}
              className="px-2 py-1 mx-1 text-xs"
              bgColor="bg-green-600"
            >
              Withdraw
            </Button>
            <Button
              onClick={() => handleFilter("Withdraw Requests")}
              className="px-2 py-1 mx-1 text-xs"
            >
              Withdraw Requests
            </Button>
          </div>
          <div>
            <p className="font-semibold text-sm">
              Total Raised :
              <span className="ms-1 font-normal">₹ {accountDetails.total}</span>
            </p>
            <p className="font-semibold text-sm">
              Total Withdrawable Amount :
              <span className="ms-1 font-normal">
                ₹ {accountDetails.withdrawable}
              </span>
            </p>
            <p className="font-semibold text-sm">
              Debited Amount :
              <span className="ms-1 font-normal">₹ {accountDetails.debit}</span>
            </p>
            <p className="font-semibold text-sm">
              Balance Amount :
              <span className="ms-1 font-normal">
                ₹ {accountDetails.balance}
              </span>
            </p>
          </div>
          <div className="flex flex-col md:flex-row justify-between md:items-center mt-3 md:mt-0">
            <h4 className="mt-2 text-lg font-semibold order-2 md:order-1">
              {tableType} Agreements
            </h4>
            <div className="flex items-center order-1 md:order-2">
              <label
                htmlFor="startup-investment-filter"
                className="text-lg font-medium mr-2 mb-0"
              >
                Filter :{" "}
              </label>
              <Dropdown
                options={[
                  { label: "All", value: "All" },
                  { label: "Signed", value: "Signed" },
                  { label: "Un-Signed", value: "Un-Signed" },
                ]}
                onChange={(selectedOption) =>
                  handleFilter(selectedOption.value)
                }
                styles={{
                  menuList: (baseStyles) => ({
                    ...baseStyles,
                    maxHeight: "200px",
                    overflowY: "auto",
                  }),
                  control: (baseStyles) => ({
                    ...baseStyles,
                    width: "200px",
                  }),
                }}
                defaultValue={{ label: "All", value: "All" }}
                id="startup-investment-filter"
              />
            </div>
          </div>
          {error && <Error />}
          {isLoading && <Loader />}
          {!isLoading && !error && (
            <div className="overflow-auto w-full md:overflow-none">
              {tableType === "All" ? (
                <AllAgreements
                  agreementDetails={agreementDetails}
                  handleEditModal={handleEditModal}
                  viewAgreement={viewAgreement}
                  openHistoryModal={openHistoryModal}
                />
              ) : tableType === "Signed" ? (
                <SignedAgreements
                  agreementDetails={agreementDetails}
                  handleEditModal={handleEditModal}
                  viewAgreement={viewAgreement}
                  openHistoryModal={openHistoryModal}
                />
              ) : tableType === "Un-Signed" ? (
                <UnSignedAgreements
                  agreementDetails={agreementDetails}
                  handleEditModal={handleEditModal}
                  viewAgreement={viewAgreement}
                  openHistoryModal={openHistoryModal}
                />
              ) : (
                <WithdrawReq
                  withdrawReqs={withdrawReqs}
                  cancelWithdraw={cancelWithdraw}
                />
              )}
            </div>
          )}
        </div>
        <EditAgreementModal
          open={open}
          onClose={() => setOpen(false)}
          propDetails={propDetails}
          editAgreement={editAgreement}
        />
        <WithdrawInvestment
          open={withdrawModal}
          onClose={() => setWithdrawModal(false)}
          accountDetails={accountDetails}
          withdrawInvestment={withdrawInvestment}
        />
        <ViewInvestmentHistory
          open={historyModal}
          onClose={() => setHistoryModal(false)}
          agreementDetails={investmentData}
          userRole={userRole}
        />
      </div>
    </div>
  );
};
