import React, { useState } from "react";
import { Pagination } from "../../components/ui/Button";
import { CircleXmarkIcon, EyeIcon } from "../../icons/icons";
import FavIcon from "../../images/favicon.png";

export const OutgoingRequests = ({
  outGoingRequests,
  handleConfirmationModal,
  viewProfile,
}) => {
  const [page, setPage] = useState(1);

  return (
    <>
      <h4 className="mb-1 font-semibold md:mb-4 md:text-lg">
        Outgoing Connection Requests
      </h4>
      <div className="overflow-auto w-full md:overflow-none">
        <table className="table-auto min-w-max w-full mt-4 border shadow-sm border-slate-300">
          <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
            <tr>
              <th className="p-3 font-medium text-xs md:text-sm">User Name</th>
              <th className="p-3 font-medium text-xs md:text-sm">
                Requested On
              </th>
              <th className="p-3 font-medium text-xs md:text-sm">Actions</th>
            </tr>
          </thead>
          <tbody>
            {outGoingRequests.length > 0 ? (
              outGoingRequests.slice(page * 7 - 7, page * 7).map((user) => (
                <tr
                  key={user.reciever_id}
                  className="bg-white border-b border-slate-300 align-middle h-auto"
                >
                  <td className="p-3 text-xs md:text-sm">
                    <div
                      onClick={() =>
                        viewProfile(user.name, user.role, user.reciever_id)
                      }
                      className="flex items-center gap-x-2 cursor-pointer"
                    >
                      <img
                        src={user?.logo.length > 0 ? user.logo : FavIcon}
                        alt="User"
                        width={50}
                        className="h-[50px] rounded-full border"
                        loading="lazy"
                      />
                      <div>
                        <p className="font-medium">{user.name}</p>
                        <span
                          className={`px-1 text-xs ${
                            user.role === "f@!3A"
                              ? "blue-badge"
                              : user.role === "R$7s2"
                              ? "orange-badge"
                              : user.role === "Sw%aa"
                              ? "green-badge"
                              : "indigo-badge"
                          }`}
                        >
                          {user.role === "f@!3A"
                            ? "Startup"
                            : user.role === "R$7s2"
                            ? "Mentor"
                            : user.role === "Sw%aa"
                            ? "Investor"
                            : "Vendor"}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="p-3 text-xs md:text-sm">{user.date}</td>
                  <td id="requestorId" className="p-3 text-xs md:text-sm">
                    <div className="flex items-center justify-evenly gap-2 h-full md:w-7/12">
                      <span title="View Profile">
                        <EyeIcon
                          width="20px"
                          height="20px"
                          onClick={() =>
                            viewProfile(user.name, user.role, user.reciever_id)
                          }
                          className="cursor-pointer fill-green-500 transition-colors ease-in duration-50 hover:fill-green-800"
                        />
                      </span>
                      <span title="Cancel Connection Request">
                        <CircleXmarkIcon
                          width="20px"
                          height="20px"
                          onClick={() =>
                            handleConfirmationModal(
                              "cancel",
                              user.reciever_id,
                              "outgoing",
                              user.role
                            )
                          }
                          className="cursor-pointer fill-red-500 transition-colors ease-in duration-50 hover:fill-red-800"
                        />
                      </span>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="bg-white text-center">
                <td colSpan="3" className="p-3 text-xs md:text-sm">
                  No Outgoing Requests
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {outGoingRequests.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          totalDataCount={outGoingRequests.length}
        />
      )}
    </>
  );
};
