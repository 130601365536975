import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateNotiStatus } from "../../store/slices/NotificationSlice";

const SingleNotification = ({
  noti,
  userRole,
  memoizedNotiType,
  updateNotification,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNotificationClick = () => {
    dispatch(updateNotiStatus({ notificationId: noti.id }));
    updateNotification(noti.id);
    navigate(memoizedNotiType(noti, userRole));
  };

  return (
    <li
      key={noti.id}
      onClick={handleNotificationClick}
      className={`block rounded p-1 mb-1 ${
        noti.status === "1"
          ? "bg-gray-200"
          : "bg-white text-black xl:hover:bg-[#ff3f00] xl:hover:text-white"
      }`}
    >
      <p className="text-left text-xs">{noti.heading}</p>
      <p className="text-right text-[10px]">{noti.date}</p>
    </li>
  );
};

export default SingleNotification;
