import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import useUpdateProfile from "../../hooks/useUpdateProfile";
import useFetch from "../../hooks/useFetch";
import useUploadImage from "../../hooks/useUploadImage";
import useDisplayIndustries from "../../hooks/useDisplayIndustries";
import { ImportImage } from "../../utils/importImage";
import { randomId } from "../../utils/randomId";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { Loader } from "../../components/ui/Loader";
import { EditVendorProfile } from "../../components/ui/modals/profile/editVendorProfile";
import { DeleteModal } from "../../components/ui/modals/utility-modals/deleteModal";
import { DescriptionModal } from "../../components/ui/modals/profile/DescriptionModal";
import { AddPortfolioModal } from "../../components/ui/modals/profile/addPortfolio";
import { BankAccountModal } from "../../components/ui/modals/bank/bankAccountModal";
import { EditBankAccountModal } from "../../components/ui/modals/bank/editBankAccountModal";
import { capitalizeText } from "../../utils/capitalizeText";
import "./Profile.css";
import FavIcon from "../../images/favicon.png";
import banner from "../../images/banner.svg";
import { CameraIcon, DeleteIcon, PenIcon } from "../../icons/icons";

const VendorProfile = () => {
  const vendorDetailsApi = `${process.env.REACT_APP_API_URL}display-vendor-profile`;
  const editVendorProfileApi = `${process.env.REACT_APP_API_URL}update-vendor-profile`;
  const vendorPortfolioApi = `${process.env.REACT_APP_API_URL}vendor-portfolio`;
  const deletePortfolioFileApi = `${process.env.REACT_APP_API_URL}delete-portfolio-file`;
  const deleteBankDetailsApi = `${process.env.REACT_APP_API_URL}delete-bank-details`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const { updateProfile } = useUpdateProfile();
  const { uploadImage } = useUploadImage();
  const { displayIndustries } = useDisplayIndustries();
  const navigate = useNavigate();

  const [vendorDetails, setVendorDetails] = useState({
    name: "",
    email: "",
    phone: 0,
    companyName: "",
    address: "",
    logo: "",
    industry: [],
    website: "",
    documents_count: 0,
    documents: [],
  });
  const ref = useRef(true);
  const [desc, setDesc] = useState("");
  const [bank, setBank] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [limitError, setLimitError] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [portfolioModal, setPortfolioModal] = useState(false);
  const [id, setId] = useState("");
  const [editData, setEditData] = useState({});
  const [singleBank, setSingleBank] = useState([]);
  const [openEditBank, setOpenEditBank] = useState(false);
  const [addBank, setAddBank] = useState(false);
  const [type, setType] = useState("portfolio");

  const getVendorData = useCallback(() => {
    fetchApi(
      {
        url: vendorDetailsApi,
        method: "GET",
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setVendorDetails({
          name: data.name,
          email: data.email,
          phone: data.phone,
          companyName: data.company_name,
          city: data.city,
          country: data.country,
          industry: data.expert_in,
          logo: data.logo,
          website: data.website_url,
          documents_count: data.documents_count,
          documents: data.documents,
          country_code: data.country_code,
        });
        setDesc(data.description);
        setEditData({
          name: data.name,
          phone: data.phone,
          companyName: data.company_name,
          industry: data.expert_in,
        });
        setBank(data.bank_details);
      }
    );
  }, [vendorDetailsApi, fetchApi, token]);

  const editVendorDetails = (data, expertise) => {
    let dataObj = {
      name: data.vendorName,
      phone: data.vendorPhone,
      company_name: data.vendorCompany,
    };
    if (expertise.length > 0) {
      dataObj = { ...dataObj, expert_in: expertise };
    }
    updateProfile(editVendorProfileApi, dataObj, getVendorData, "vendor");
  };

  useEffect(() => {
    if (userRole !== "V&iR8") {
      navigate("/error-403");
      return;
    }
    if (ref.current) {
      getVendorData();
      displayIndustries(
        (data) => setIndustries(data.industries),
        "service provider"
      );
    }
    return () => {
      ref.current = false;
    };
  }, [getVendorData, navigate, userRole, displayIndustries]);

  const vendorPortfolio = (url, file, setPortfolioFile) => {
    setLimitError(false);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("website_url", url);
    axios
      .post(vendorPortfolioApi, formData, {
        headers: { "x-token": token },
      })
      .then((data) => {
        if (data.data.message === "over limit") {
          setLimitError(true);
        }
        setPortfolioFile();
        getVendorData();
      })
      .catch((err) => {
        if (err.response.status) {
          alert("File must be of type ( .png, .jpg, .jpeg, pdf )");
        }
      });
  };

  const deletePortfolioFile = useCallback(
    (id) => {
      fetchApi(
        {
          url: deletePortfolioFileApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            id: id,
          },
        },
        () => {
          getVendorData();
        }
      );
    },
    [fetchApi, token, deletePortfolioFileApi, getVendorData]
  );

  const deleteBankDetails = useCallback(
    (bankId) => {
      fetchApi(
        {
          url: deleteBankDetailsApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            id: bankId,
          },
        },
        () => getVendorData()
      );
    },
    [fetchApi, token, deleteBankDetailsApi, getVendorData]
  );

  const handleModal = (type, data) => {
    if (type === "edit") {
      setSingleBank(data);
      setOpenEditBank(true);
    } else {
      setAddBank(true);
    }
  };

  const handleDeleteModal = (id, type) => {
    setId(id);
    setType(type);
    setOpenDeleteModal(true);
  };

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="p-3 md:p-4">
          {isLoading && !error ? (
            <Loader />
          ) : (
            <div>
              <div
                className="relative hidden md:block bg-cover h-[250px]"
                style={{
                  backgroundImage: `url(${banner})`,
                }}
              ></div>
              <div>
                <div className="grid grid-cols-5 px-3 gap-x-1 bg-white shadow-md rounded-md">
                  <div className="col-span-5 ml-auto md:hidden">
                    <button
                      type="button"
                      className="profile-btns bg-primary text-white mt-2 text-center px-2 py-1 rounded-md text-xs md:text-sm"
                      onClick={() => setOpen(true)}
                    >
                      Edit
                    </button>
                  </div>
                  <div className="col-span-5 md:col-span-1 relative flex items-end justify-center">
                    <img
                      src={
                        vendorDetails.logo.length > 0
                          ? vendorDetails.logo
                          : FavIcon
                      }
                      className="profile-img shadow w-[90px] h-[90px] md:w-[140px] md:h-[140px]"
                      alt="profile-img"
                    />
                    <div className="img-icon bg-primary rounded-full p-1">
                      <label
                        htmlFor="uploadImg"
                        className="mb-0 cursor-pointer"
                      >
                        <CameraIcon fill="#fff" />
                      </label>
                      <input
                        type="file"
                        id="uploadImg"
                        name="uploadImg"
                        accept="image/*"
                        className="hidden"
                        onChange={(e) =>
                          uploadImage(e.target.files[0], getVendorData)
                        }
                      />
                    </div>
                    {vendorDetails.logo.length < 1 && (
                      <p className="text-red-500 font-medium absolute top-[100px] md:top-[85px]">
                        Upload image
                      </p>
                    )}
                  </div>
                  <div className="relative col-span-5 md:col-span-4 pb-4 mb-5 md:pl-[3.5rem] 2xl:pl-0">
                    <button
                      type="button"
                      className="hidden md:block profile-btns bg-primary text-white mt-2 text-center px-2 py-1 rounded-md absolute right-0 md:top-unset md:bottom-2.5 text-xs md:text-sm"
                      onClick={() => setOpen(true)}
                    >
                      Edit
                    </button>
                    <div className="grid grid-cols-1 md:grid-cols-3 mt-10 gap-y-1 md:mt-2">
                      <div className="md:col-span-3 grid grid-cols-2">
                        <p className="col-span-2 md:col-span-1 font-bold">
                          Name :{" "}
                          <span className="uppercase font-medium">
                            {vendorDetails.name}
                          </span>
                        </p>
                        <p className="col-span-2 md:col-span-1 font-bold">
                          Email :{" "}
                          <span className="font-medium">
                            {vendorDetails.email}
                          </span>
                        </p>
                        <p className="col-span-2 md:col-span-1 font-bold">
                          Company :{" "}
                          <span className="font-medium">
                            {vendorDetails.companyName}
                          </span>
                        </p>
                      </div>
                      <p className="md:col-span-2 font-bold flex items-center">
                        Address :{" "}
                        <span className="flex items-center ms-1 font-medium capitalize">
                          {vendorDetails.country?.length > 0 ? (
                            <>
                              <span className="pe-1 capitalize">
                                {vendorDetails.city}
                              </span>
                              <img
                                src={
                                  vendorDetails.country_code
                                    ? `https://flagsapi.com/${vendorDetails.country_code}/flat/64.png`
                                    : "https://flagsapi.com/IN/flat/64.png"
                                }
                                alt={`${vendorDetails.country} flag`}
                                width={30}
                                title={capitalizeText(vendorDetails.country)}
                                loading="lazy"
                              />
                            </>
                          ) : (
                            <span className="text-red-500 text-sm">
                              Missing
                            </span>
                          )}
                        </span>
                      </p>
                      <p className="md:col-span-3 font-bold">
                        Industries :{" "}
                        <span className="ms-1 font-medium">
                          {vendorDetails.industry.map((industry, i) => (
                            <span className="outline-tags" key={i}>
                              {industry.replace(new RegExp("_", "g"), " ")}
                            </span>
                          ))}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-3 mt-2 mx-0 px-0">
                  <div className="ideaContainer col-span-2 md:col-span-1 bg-white shadow-md rounded-md p-3 my-2 relative">
                    <div className="flex items-center justify-between">
                      <h5 className="font-semibold">Company Bio</h5>
                      <p
                        className="outline-btn text-xs md:text-sm"
                        type="button"
                        onClick={() => setDescriptionModal(true)}
                      >
                        Write here
                      </p>
                    </div>
                    {!desc ? (
                      <p id="noIdea" className="mt-3 text-sm">
                        Tell us about your company.
                      </p>
                    ) : (
                      <div className="mt-3 text-sm">{desc}</div>
                    )}
                  </div>
                  <div className="portfolioContainer col-span-2 md:col-span-1 bg-white shadow-md rounded-md p-3 my-2 relative">
                    <div className="flex items-center justify-between">
                      <h5 className="font-semibold">Portfolio</h5>
                      <p
                        className="outline-btn text-xs md:text-sm"
                        type="button"
                        onClick={() => setPortfolioModal(true)}
                      >
                        Add
                      </p>
                    </div>
                    {vendorDetails.website.length < 1 &&
                    vendorDetails.documents_count < 1 ? (
                      <div id="noIdea" className="mt-3 text-sm">
                        Upload portfolio
                      </div>
                    ) : (
                      <div className="mt-3 text-sm">
                        <p className="font-medium">
                          Website url :{" "}
                          <a
                            href={vendorDetails.website}
                            target="_blank"
                            rel="noreferrer"
                            className="font-normal"
                          >
                            {vendorDetails.website || (
                              <span className="text-secondary text-sm font-medium">
                                Missing
                              </span>
                            )}
                          </a>
                        </p>
                        <div className="mt-2">
                          <p className="font-medium">
                            Files uploaded ({vendorDetails.documents_count}/5)
                          </p>
                          {limitError &&
                            vendorDetails.documents_count === 5 && (
                              <p className="text-red-500 text-xs">
                                You have already uploaded 5 files
                              </p>
                            )}
                          {vendorDetails.documents.length > 0 &&
                            vendorDetails.documents.map((file) => (
                              <div
                                key={file._id}
                                className="profile-detail-card relative"
                              >
                                <Link
                                  to={file.path}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="flex justify-between items-center px-4 py-2 mt-2 rounded-md bg-gray-200 hover:opacity-50 text-xs md:text-sm"
                                >
                                  {file.file_name}
                                </Link>
                                <DeleteIcon
                                  className="delete-account-btn absolute right-0 top-0 cursor-pointer"
                                  onClick={() =>
                                    handleDeleteModal(file._id, "portfolio")
                                  }
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="bankDetailsContainer col-span-2 md:col-span-1 bg-white shadow-md rounded-md p-3 relative">
                    <div className="flex items-center justify-between mb-3">
                      <h5 className="font-semibold">Bank Details</h5>
                      {bank.length < 1 && (
                        <p
                          className="outline-btn text-xs md:text-sm"
                          onClick={() => handleModal("add")}
                          type="button"
                        >
                          Add new
                        </p>
                      )}
                    </div>
                    <div className="grid grid-cols-1 mt-3" id="bank-details">
                      {bank.length > 0 ? (
                        bank.map((bank) => (
                          <div
                            className="profile-detail-card relative"
                            key={bank._id}
                          >
                            <div className="flex justify-between items-center px-4 py-2 mt-2 rounded-md bg-gray-200 hover:opacity-50">
                              <div>
                                <p className="font-semibold text-xs md:text-sm leading-relaxed">
                                  Account Holder:
                                  <span className="ps-2 font-medium whitespace-normal">
                                    {bank.name}
                                  </span>
                                </p>
                                <p className="font-semibold text-xs md:text-sm leading-relaxed">
                                  Account Number:
                                  <span className="ps-2 font-medium whitespace-normal">
                                    {bank.account_number}
                                  </span>
                                </p>
                                <p className="font-semibold text-xs md:text-sm leading-relaxed">
                                  IFSC Code:
                                  <span className="ps-2 font-medium whitespace-normal">
                                    {bank.ifsc}
                                  </span>
                                </p>
                              </div>
                              <ImportImage
                                imageName={bank.bank_name}
                                width={45}
                                alt={bank.bank_name}
                              />
                            </div>
                            <DeleteIcon
                              className="delete-account-btn absolute right-0 top-0 cursor-pointer"
                              onClick={() =>
                                handleDeleteModal(bank._id, "bank")
                              }
                            />
                            <PenIcon
                              className="edit-account-details absolute right-[10%] top-0 cursor-pointer"
                              onClick={() => handleModal("edit", bank)}
                            />
                          </div>
                        ))
                      ) : (
                        <p id="noBankDetails">No Bank Account found.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
      <EditVendorProfile
        key={randomId()}
        open={open}
        onClose={() => setOpen(false)}
        editVendorDetails={editVendorDetails}
        editData={editData}
        industries={industries}
      />
      <DeleteModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        deletePortfolioFile={deletePortfolioFile}
        deleteBankDetails={deleteBankDetails}
        id={id}
        type={type}
      />
      <DescriptionModal
        key={randomId()}
        open={descriptionModal}
        onClose={() => setDescriptionModal(false)}
        callBackFn={() => getVendorData()}
        url={editVendorProfileApi}
        data={desc}
      />
      <AddPortfolioModal
        key={randomId()}
        open={portfolioModal}
        onClose={() => setPortfolioModal(false)}
        vendorPortfolio={vendorPortfolio}
        url={vendorDetails.website}
      />
      <BankAccountModal
        open={addBank}
        onClose={() => setAddBank(false)}
        callBackFn={getVendorData}
      />
      <EditBankAccountModal
        key={singleBank._id}
        open={openEditBank}
        onClose={() => setOpenEditBank(false)}
        singleBank={singleBank}
        bankId={singleBank._id}
        callBackFn={getVendorData}
      />
    </div>
  );
};

export default VendorProfile;
