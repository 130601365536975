import { Button } from "../../Button";
import { FormError } from "../../FormError";
import { numberRegex, textWithSpaceRegex } from "../../../../constants";
import { DeleteIcon, PenIcon, PlusIcon } from "../../../../icons/icons";
import { useState } from "react";
import { randomId } from "../../../../utils/randomId";

const PlanForm = ({
  submitModal,
  register,
  handleSubmit,
  errors,
  features,
  setFeatures,
}) => {
  const [featureId, setFeatureId] = useState(null);

  const addFeature = () => {
    const newFeature = document.getElementById("planFeatures").value;
    if (featureId) {
      updateFeature(featureId, newFeature);
      document.getElementById("planFeatures").value = "";
      return;
    }
    if (newFeature.trim() !== "") {
      document.getElementById("feature-error").classList.add("hidden");
      setFeatures((prev) => [
        ...prev,
        { key: randomId(), value: newFeature.trim() },
      ]);
      document.getElementById("planFeatures").value = "";
    } else {
      document.getElementById("feature-error").classList.remove("hidden");
    }
  };

  const removeFeature = (id) => {
    const updatedList = features.filter((feature) => feature.key !== id);
    setFeatures(updatedList);
  };

  const editFeature = (data) => {
    setFeatureId(data.key);
    document.getElementById("planFeatures").value = data.value;
  };

  const updateFeature = (id, newValue) => {
    setFeatureId(null);
    setFeatures((prevItems) =>
      prevItems.map((item) => {
        if (item.key === id) {
          return { ...item, value: newValue };
        }
        return item;
      })
    );
  };

  return (
    <form
      onSubmit={handleSubmit((data) => submitModal(data, features))}
      className="block md:grid md:grid-cols-3 gap-3 pb-0 mt-6"
    >
      <div className="flex flex-col">
        <label htmlFor="planFor" className="mb-1 font-medium text-sm required">
          Subscription For
        </label>
        <div className="select-input mb-1 w-full border border-slate-300 m-auto rounded-md">
          <select
            name="planFor"
            id="planFor"
            className="border-0 w-full text-gray-500"
            {...register("planFor", {
              required: "Select a user type",
            })}
          >
            <option value="startup">Startup</option>
            <option value="vendor">Service Provider</option>
          </select>
        </div>
        {errors.planFor && (
          <div>
            <FormError message={errors.planFor.message} />
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <label htmlFor="planName" className="mb-1 font-medium text-sm required">
          Plan Name
        </label>
        <input
          type="text"
          id="planName"
          name="planName"
          className="p-[0.7rem] border rounded-md"
          placeholder="Enter plan name"
          {...register("planName", {
            required: "Name is required",
            pattern: {
              value: textWithSpaceRegex,
              message: "Name must only contain alphabets",
            },
          })}
        />
        {errors.planName && (
          <div>
            <FormError message={errors.planName.message} />
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <label
          htmlFor="planPrice"
          className="mb-1 font-medium text-sm required"
        >
          Plan Price
        </label>
        <input
          type="number"
          id="planPrice"
          name="planPrice"
          className="p-[0.7rem] border rounded-md"
          placeholder="Enter plan price"
          {...register("planPrice", {
            required: "price is required",
            pattern: {
              value: numberRegex,
              message: "Price must only contain digits",
            },
          })}
        />
        {errors.planPrice && (
          <div>
            <FormError message={errors.planPrice.message} />
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <label
          htmlFor="planTokens"
          className="mb-1 font-medium text-sm required"
        >
          Tokens
        </label>
        <input
          type="number"
          id="planTokens"
          name="planTokens"
          className="p-[0.7rem] border rounded-md"
          placeholder="Enter tokens"
          {...register("planTokens", {
            required: "tokens is required",
            pattern: {
              value: numberRegex,
              message: "Tokens must only contain digits",
            },
            validate: {
              notZero: (fieldValue) => {
                return fieldValue > 0 || "Tokens cannot be 0";
              },
            },
          })}
        />
        {errors.planTokens && (
          <div>
            <FormError message={errors.planTokens.message} />
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <label htmlFor="discount" className="mb-1 font-medium text-sm required">
          Discount
        </label>
        <div className="select-input mb-1 w-full border border-slate-300 m-auto rounded-md">
          <select
            name="discount"
            id="discount"
            className="border-0 w-full text-gray-500"
            {...register("discount", {
              required: "Set discount status",
            })}
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
        {errors.discount && (
          <div>
            <FormError message={errors.discount.message} />
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <label htmlFor="type" className="mb-1 font-medium text-sm required">
          Discount Type
        </label>
        <div className="select-input mb-1 w-full border border-slate-300 m-auto rounded-md">
          <select
            name="type"
            id="type"
            className="border-0 w-full text-gray-500"
            {...register("type", {
              required: "Select a discount type",
            })}
          >
            <option value="flat">Flat</option>
            <option value="percentage">Percentage</option>
          </select>
        </div>
        {errors.type && (
          <div>
            <FormError message={errors.type.message} />
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <label
          htmlFor="discountAmount"
          className="mb-1 font-medium text-sm required"
        >
          Discount Amount/Percentage
        </label>
        <input
          type="text"
          id="discountAmount"
          name="discountAmount"
          className="p-[0.7rem] border rounded-md"
          placeholder="Enter discount"
          {...register("discountAmount", {
            required: "amount/percentage is required",
            pattern: {
              value: numberRegex,
              message: "amount/percentage must only contain digits",
            },
          })}
        />
        {errors.discountAmount && (
          <div>
            <FormError message={errors.discountAmount.message} />
          </div>
        )}
      </div>
      <div className="md:col-span-2 flex flex-col">
        <label
          htmlFor="planFeatures"
          className="mb-1 font-medium text-sm required"
        >
          Plan Features
        </label>
        <div className="flex items-center justify-between">
          <input
            type="text"
            id="planFeatures"
            name="planFeatures"
            className="w-11/12 p-[0.7rem] border rounded-md"
            placeholder="Enter feature"
          />
          <PlusIcon
            width="22px"
            height="22px"
            className="bg-primary p-1 rounded-full"
            onClick={() => addFeature()}
          />
        </div>
        <p id="feature-error" className="hidden text-xs text-red-500">
          Feature cannot be empty
        </p>
        <p id="feature-required" className="hidden text-xs text-red-500">
          Feature are required
        </p>
        {features.length > 0 && (
          <div className="flex flex-col items-start gap-1 mt-2">
            {features.map((feature) => (
              <div
                key={feature.key}
                className="flex justify-between items-center w-full"
              >
                <div className="bg-slate-200 p-1 rounded-md max-w-[90%] break-normal">
                  {feature.value}
                </div>
                <div className="flex items-center gap-x-2">
                  <PenIcon
                    fill="#12d512"
                    onClick={() => editFeature(feature)}
                  />
                  <DeleteIcon onClick={() => removeFeature(feature.key)} />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <Button
        type="submit"
        className="col-span-3 w-fit ml-auto px-3 py-1.5 mx-1 mt-3"
      >
        Update
      </Button>
    </form>
  );
};

export default PlanForm;
