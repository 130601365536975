import ReactDom from "react-dom";
import { Button } from "../../Button";
import { ConfirmationIcon } from "../../../../icons/icons";

export const ConfirmationModal = ({
  open,
  onClose,
  message = "Are you sure you want to disconnect?",
  callBackFn,
}) => {
  if (!open) return null;

  const submitModal = () => {
    callBackFn();
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`min-w-[90%] md:max-w-[400px] md:min-w-[400px] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <div className="p-3 pb-0 mt-2 text-center">
          <ConfirmationIcon
            width="70px"
            height="70px"
            fill="#E11919"
            className="mx-auto"
          />
          <p className="mt-4">{message}</p>
        </div>
        <div className="flex justify-center p-3">
          <Button className="px-2 py-1 mx-1" onClick={submitModal}>
            Confirm
          </Button>
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
