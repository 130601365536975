import { Link } from "react-router-dom";

const WalletTable = ({ data }) => {
  return (
    <div className="bg-white shadow-lg rounded-xl overflow-auto w-full md:overflow-none">
      <div className="flex items-center justify-between py-4 px-2">
        <h6 className="font-medium text-lg">Your Wallet</h6>
        <Link
          to="/mentor-wallet"
          className="px-3 py-1 text-xs font-medium rounded-full bg-primary text-white"
        >
          View All
        </Link>
      </div>
      <table className="table-auto min-w-max w-full rounded-b-xl overflow-hidden">
        <thead className="!bg-transparent !text-gray-400 text-left">
          <tr>
            <th className="p-2 font-medium text-xs">Startup</th>
            <th className="p-2 font-medium text-xs">Date</th>
            <th className="p-2 font-medium text-xs">Amount</th>
            <th className="p-2 font-medium text-xs">Status</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.slice(0, 5).map((transaction) => (
              <tr
                key={transaction.id}
                className="bg-white border-t border-slate-200"
              >
                <td className="p-3 text-xs sm:text-sm capitalize font-medium">
                  {transaction.name}
                </td>
                <td className="p-3 text-xs sm:text-sm">{transaction.date}</td>
                <td className="p-3 text-xs sm:text-sm">
                  <span className="font-medium mr-1">&#8377;</span>
                  {transaction.freeze_points}
                </td>
                <td className="p-3">
                  <span
                    className={`px-2 py-0.5 text-xs rounded-md border ${
                      transaction.status === "0"
                        ? "blue-badge"
                        : transaction.status === "1"
                        ? "orange-badge"
                        : "green-badge"
                    }`}
                  >
                    {transaction.status === "0"
                      ? "Freezed"
                      : transaction.status === "1"
                      ? "Withdrawable"
                      : "Withdrawn"}
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr className="bg-white text-center">
              <td colSpan={4} className="p-3 text-xs sm:text-sm text-gray-400">
                No transaction found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default WalletTable;
