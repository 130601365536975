import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import useFetch from "../../hooks/useFetch";
import { randomId } from "../../utils/randomId";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import { Button } from "../../components/ui/Button";
import { ResetPassword } from "../../components/ui/modals/utility-modals/resetPassword";
import FavIcon from "../../images/favicon.png";
import AdminNavbar from "../../components/layout/adminNavbar";
import AdminSidebar from "../../components/layout/adminSidebar";

const Settings = () => {
  const settingsApi = `${process.env.REACT_APP_API_URL}settings-admin`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const [open, setOpen] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [userData, setUserData] = useState({ name: "", email: "", logo: "" });
  const navigate = useNavigate();

  const getSettings = useCallback(() => {
    fetchApi(
      {
        url: settingsApi,
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setUserData({
          name: data.name,
          email: data.email,
          logo: data.logo,
        });
      }
    );
  }, [token, fetchApi, settingsApi]);

  useEffect(() => {
    if (userRole !== "admin") {
      navigate("/error-403");
      return;
    }
    const fetchSettings = setTimeout(() => {
      getSettings();
    });

    return () => {
      clearTimeout(fetchSettings);
    };
  }, [getSettings, navigate, userRole]);

  return (
    <div className="grid grid-cols-5">
      <AdminSidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <AdminNavbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="p-4">
          <h3 className="font-semibold text-xl">Settings</h3>
          {isLoading && <Loader />}
          {error && <Error />}
          {!isLoading && !error && userData ? (
            <div className="grid grid-cols-4 mt-5 bg-white shadow rounded-md">
              <div className="col-span-4 md:col-span-1 flex flex-col items-center p-4 border-r">
                <img
                  src={userData.logo.length > 0 ? userData.logo : FavIcon}
                  alt="profile-img"
                  width={150}
                  className="h-[150px] rounded-full p-1 border border-slate-400"
                  loading="lazy"
                />
              </div>
              <div className="col-span-4 md:col-span-3 p-3 flex flex-col md:flex-row justify-between">
                <div>
                  <div className="flex items-center my-1">
                    <p className="font-medium md:text-base">Name :</p>
                    <p className="ms-1 md:text-base uppercase">
                      {userData.name}
                    </p>
                  </div>
                  <div className="flex items-center my-1">
                    <p className="font-medium md:text-base">Email :</p>
                    <p className="ms-1 md:text-base">{userData.email}</p>
                  </div>
                  <div className="flex items-center justify-between my-1">
                    <p className="flex items-center w-fit">
                      <span className="font-medium md:text-base">
                        Password :
                      </span>
                      <span className="ms-1 md:text-base">********</span>
                    </p>
                    <Button
                      onClick={() => setOpen(true)}
                      className="md:hidden p-0.5 h-fit text-xs"
                    >
                      Change Password
                    </Button>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <Button
                    onClick={() => setOpen(true)}
                    className="hidden md:block p-1 h-fit text-sm"
                  >
                    Change Password
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </section>
      </div>
      <ResetPassword
        open={open}
        onClose={() => setOpen(false)}
        key={randomId()}
      />
    </div>
  );
};

export default Settings;
