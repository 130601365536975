import React, { useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimension";
import useLocalStorage from "../../hooks/useLocalStorage";
import { BarsIcon, GearIcon, LogoutIcon, UserIcon } from "../../icons/icons";
import { ResetPassword } from "../ui/modals/utility-modals/resetPassword";
import { randomId } from "../../utils/randomId";
import pitchHereLogo from "../../images/pitch-here-logo.png";
import FavIcon from "../../images/favicon.png";
import { Link } from "react-router-dom";

const AdminNavbar = (props) => {
  const { width } = useWindowDimensions();
  const { name } = useLocalStorage();

  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  return (
    <div className="sticky top-0 m-0 z-[1] px-4 py-2 bg-white md:relative shadow-[5px_1px_20px_-6px_rgba(0,0,0,0.4)] flex items-center justify-between md:block">
      {width < 768 && (
        <img src={pitchHereLogo} alt="pitchere-logo" width={100} />
      )}
      <div className="hidden lg:flex lg:justify-end lg:items-center">
        <div className="inline-block relative group">
          <div className="flex justify-center items-center gap-x-2 lg:justify-normal cursor-pointer ms-4">
            <p className="text-lg font-medium capitalize">{name}</p>
            <img
              src={FavIcon}
              className="rounded-full h-[50px]"
              alt="profile-img"
              width={50}
            />
          </div>
          <div className="hidden group-hover:block absolute top-[100%] right-[1%] w-max bg-primary text-center shadow-md rounded-md px-3 py-1">
            <ul className="fa-ul">
              <li className="flex items-center mt-1 mb-2 ps-1 text-sm capitalize text-white hover:text-customOrange">
                <span className="fa-li pr-2">
                  <UserIcon fill="#fff" />
                </span>
                {name}
              </li>
              <li className="mt-1 mb-2 ps-1 text-sm text-white hover:text-customOrange">
                <Link
                  to="/admin/settings"
                  className="flex items-center hover:text-customOrange"
                >
                  <span className="fa-li pr-2">
                    <GearIcon fill="#fff" />
                  </span>
                  Settings
                </Link>
              </li>
              <li className="mt-1 mb-2 ps-1 text-sm text-white hover:text-customOrange">
                <Link
                  to="/admin"
                  className="flex items-center hover:text-customOrange"
                >
                  <span className="fa-li pr-2">
                    <LogoutIcon fill="#fff" />
                  </span>
                  LogOut
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className="flex items-center justify-end lg:hidden"
        id="sidebar-icon"
      >
        <BarsIcon onClick={props.handleSidebar} />
      </div>
      <ResetPassword
        key={randomId()}
        open={openPasswordModal}
        onClose={() => setOpenPasswordModal(false)}
      />
    </div>
  );
};

export default AdminNavbar;
