import React from "react";
import { Link } from "react-router-dom";

const NotificationItem = ({ data, userRole, fn, updateNotification }) => {
  return (
    <Link
      to={fn(data, userRole)}
      onClick={() => updateNotification(data.id)}
      className={`p-2 rounded-md shadow-md ${
        data.status === "0" ? "bg-[#e4e4e4]" : "bg-white"
      }`}
    >
      <div className="flex flex-col items-start md:flex-row md:items-center md:justify-between">
        <p className="md:mb-0 md:me-4 font-medium">{data.heading}</p>
        <span className="ml-auto text-xs md:text-sm">{data.date}</span>
      </div>
    </Link>
  );
};

export default NotificationItem;
