import { useCallback } from "react";
import useFetch from "./useFetch";

const useSearchApi = () => {
  const { fetchApi } = useFetch();
  const search = useCallback(
    (url, token, searchInput, filterObj, callBackFn) => {
      let bodyObj = {
        ...filterObj,
        search_data: searchInput,
      };
      fetchApi(
        {
          url: url,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: bodyObj,
        },
        (data) => callBackFn(data)
      );
    },
    [fetchApi]
  );
  return { search };
};

export default useSearchApi;
