import { useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updateCredits } from "../store/slices/FetchCreditsSlice";
import { addTransaction } from "../store/slices/dashboard/StartupDashboardSlice";
import useLocalStorage from "./useLocalStorage";
import { useToastContext } from "../context/ToastContext";
import CompanyLogo from "../images/favicon.png";
import { getCurrentDate } from "../utils/getCurrentDate";

const useCheckout = () => {
  const checkoutApi = `${process.env.REACT_APP_API_URL}checkout`;
  const insertTransactionApi = `${process.env.REACT_APP_API_URL}insert-transaction-details`;

  const { token, name } = useLocalStorage();
  const toast = useToastContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const abortControllerRef = useRef(null);

  const createCheckoutSession = useCallback(
    async (bodyData, callbackFn) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;

      const response = await axios
        .post(checkoutApi, bodyData, {
          headers: { "x-token": token },
          signal,
        })
        .catch(() => {
          toast.open("false", "Error creating checkout session");
          return null;
        });

      if (!response || response.data.message === "deactivated") {
        callbackFn();
        toast.open("false", "Sorry, This plan has been deactivated.");
        return;
      }

      const order = response.data;

      const options = {
        key: order.key,
        amount: order.final_amount,
        currency: "INR",
        name: "PitchHere",
        image: CompanyLogo,
        order_id: order.order.id,
        handler: async function (response) {
          let bodyData = {
            ...response,
            ...order,
            date: getCurrentDate(),
          };

          await axios
            .post(insertTransactionApi, bodyData, {
              headers: { "x-token": token },
              signal,
            })
            .then((res) => {
              if (res.data.message === "deactivated") {
                callbackFn();
                toast.open("false", "Sorry, This plan has been deactivated.");
                return;
              } else {
                if (bodyData.type !== "market") {
                  dispatch(
                    addTransaction({
                      id: res.data.transaction_details._id,
                      transaction_id:
                        res.data.transaction_details.transaction_id,
                      plantype: res.data.transaction_details.plan_type,
                      date: res.data.transaction_details.date,
                      status: res.data.transaction_details.status,
                      amount: res.data.transaction_details.amount,
                      credits: res.data.transaction_details.credits,
                    })
                  );
                  dispatch(
                    updateCredits({ type: "add", amount: bodyData.credits })
                  );
                }
                navigate(`/payment/${bodyData.type}/${res.data.message}`);
                return;
              }
            })
            .catch(() => {
              toast.open("false", "Error creating checkout session");
              return null;
            });
        },
        prefill: {
          name: name,
          email: "",
          contact: "",
        },
        theme: {
          color: "#17335E",
        },
      };
      const razor = new window.Razorpay(options);
      razor.open();
    },
    [checkoutApi, insertTransactionApi, token, name, toast, navigate, dispatch]
  );

  return { createCheckoutSession };
};

export default useCheckout;
