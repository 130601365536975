import BarLoader from "react-spinners/BarLoader";

export const Loader = (props) => {
  const { loading, pt = "160px", classes = "" } = props;
  return (
    <div
      className={`flex justify-center items-center ${classes}`}
      style={{ paddingTop: pt }}
    >
      <BarLoader color="#36d7b7" loading={loading} />
    </div>
  );
};
