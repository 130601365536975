import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";
import useFetch from "../hooks/useFetch";
import useUploadImage from "../hooks/useUploadImage";
import { useToastContext } from "../context/ToastContext";
import { randomId } from "../utils/randomId";
import { Loader } from "../components/ui/Loader";
import { Error } from "../components/ui/Error";
import { Button } from "../components/ui/Button";
import Sidebar from "../components/layout/Sidebar";
import Navbar from "../components/layout/Navbar";
import { ResetPassword } from "../components/ui/modals/utility-modals/resetPassword";
import { ConfirmationModal } from "../components/ui/modals/utility-modals/confirmationModal";
import { DeactivateModal } from "../components/ui/modals/utility-modals/deactivateModal";
import FavIcon from "../images/favicon.png";

const Settings = () => {
  const settingsApi = `${process.env.REACT_APP_API_URL}settings`;
  const deactivateApi = `${process.env.REACT_APP_API_URL}deactivate`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token } = useLocalStorage();
  const toast = useToastContext();
  const { uploadImage } = useUploadImage();
  const [open, setOpen] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [userData, setUserData] = useState({ name: "", email: "", logo: "" });
  const ref = useRef(true);
  const navigate = useNavigate();

  const getSettings = useCallback(() => {
    fetchApi(
      {
        url: settingsApi,
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setUserData({
          name: data.name,
          email: data.email,
          logo: data.logo,
        });
      }
    );
  }, [token, fetchApi, settingsApi]);

  const deactivate = useCallback(
    (reason) => {
      fetchApi(
        {
          url: deactivateApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            reason: reason,
          },
        },
        (data) => {
          if (data.message === "account deactivated") {
            navigate("/");
          } else {
            toast.open(
              "warning",
              `Sorry, de-activation failed, ${data.message}`
            );
            return;
          }
        }
      );
    },
    [fetchApi, token, navigate, toast, deactivateApi]
  );

  useEffect(() => {
    if (ref.current) {
      getSettings();
    }
    return () => {
      ref.current = false;
    };
  }, [getSettings]);

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="p-4">
          <h3 className="font-semibold text-xl">Settings</h3>
          {isLoading && <Loader />}
          {error && <Error />}
          {!isLoading && !error && userData ? (
            <div className="grid grid-cols-4 mt-5 bg-white shadow rounded-md">
              <div className="col-span-4 md:col-span-1 flex flex-col items-center p-4 border-r">
                <img
                  src={userData.logo.length > 0 ? userData.logo : FavIcon}
                  alt="profile-img"
                  width={150}
                  className="h-[150px] rounded-full p-1 border border-slate-400"
                  loading="lazy"
                />
                <label
                  htmlFor="upload-profile"
                  className="p-1 mt-3 bg-primary text-white rounded-md cursor-pointer text-sm"
                >
                  Upload
                </label>
                <input
                  type="file"
                  id="upload-profile"
                  className="hidden"
                  accept="image/png, image/jpeg"
                  onChange={(e) => uploadImage(e.target.files[0], getSettings)}
                />
              </div>
              <div className="col-span-4 md:col-span-3 p-3 flex flex-col md:flex-row justify-between">
                <div>
                  <div className="flex items-center my-1">
                    <p className="font-medium md:text-base">Name :</p>
                    <p className="ms-1 md:text-base uppercase">
                      {userData.name}
                    </p>
                  </div>
                  <div className="flex items-center my-1">
                    <p className="font-medium md:text-base">Email :</p>
                    <p className="ms-1 md:text-base">{userData.email}</p>
                  </div>
                  <div className="flex items-center justify-between my-1">
                    <p className="flex items-center w-fit">
                      <span className="font-medium md:text-base">
                        Password :
                      </span>
                      <span className="ms-1 md:text-base">********</span>
                    </p>
                    <Button
                      onClick={() => setOpen(true)}
                      className="md:hidden p-0.5 h-fit text-xs"
                    >
                      Change Password
                    </Button>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <Button
                    onClick={() => setOpen(true)}
                    className="hidden md:block p-1 h-fit text-sm"
                  >
                    Change Password
                  </Button>
                  <Button
                    onClick={() => setDeactivateModal(true)}
                    className="hidden md:block bg-secondary p-1 h-fit text-sm"
                  >
                    Deactivate Account
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </section>
      </div>
      <ResetPassword
        open={open}
        onClose={() => setOpen(false)}
        key={randomId()}
      />
      <ConfirmationModal
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        message="Are you sure you want to deactivate your account ? For reactivation just login again."
        callBackFn={() => deactivate()}
      />
      <DeactivateModal
        open={deactivateModal}
        onClose={() => setDeactivateModal(false)}
        callBackFn={deactivate}
      />
    </div>
  );
};

export default Settings;
