import { useEffect, useRef, useState } from "react";
import ReactDom from "react-dom";
import { Button } from "../../Button";
import { useForm } from "react-hook-form";
import { FormError } from "../../FormError";
import {
  bannedInputs,
  numberRegex,
  textWithSpaceRegex,
  urlRegex,
} from "../../../../constants";
import {
  LinkedInIcon,
  MoneyUpTrendIcon,
  PhoneIcon,
  UserIcon,
} from "../../../../icons/icons";
import { Dropdown } from "../../inputs/dropdown";

export const EditInvestorProfile = ({
  open,
  onClose,
  editInvestorDetails,
  editData,
  industries,
}) => {
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const ref = useRef(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      investorName: editData[0]?.name,
      investorPhone: editData[0]?.phone,
      investorLinkedIn: editData[0]?.linked_in,
      investedCompanyCount: editData[0]?.companies_invested_count,
      fillTotalInvestment: editData[0]?.invested_amount,
      futureInvestmentAmount: editData[0]?.future_investment_amount,
    },
  });

  useEffect(() => {
    if (!ref.current) {
      if (editData[0]?.investor_industries) {
        setSelectedIndustry([...editData[0].investor_industries]);
      }
    }
    return () => {
      ref.current = true;
    };
  }, [editData]);

  if (!open) return null;

  const submitModal = (data) => {
    document.getElementById("industry-error").classList.add("hidden");
    document.getElementById("industry-limit").classList.add("hidden");
    if (selectedIndustry.length < 1) {
      document.getElementById("industry-error").classList.remove("hidden");
      return;
    }
    if (selectedIndustry.length > 3) {
      document.getElementById("industry-limit").classList.remove("hidden");
      return;
    }
    editInvestorDetails(data, selectedIndustry);
    setSelectedIndustry([]);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`min-w-[90%] max-h-[95vh] overflow-y-auto md:min-w-[70%] md:max-w-[70%] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading font-semibold text-center border-b p-3 text-base">
          Update Profile
        </h4>
        <form
          onSubmit={handleSubmit(submitModal)}
          className="grid grid-cols-1 md:grid-cols-2 gap-2 p-3 mt-3"
        >
          <div>
            <label htmlFor="investorName" className="mb-0 text-sm">
              Edit Name
            </label>
            <div
              className="flex mb-3 rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="border-0 w-full ps-3"
                id="investorName"
                name="investorName"
                autoFocus
                {...register("investorName", {
                  validate: {
                    validLength: (fieldValue) => {
                      return fieldValue.trim().length > 0
                        ? fieldValue.trim().length > 2 ||
                            "minimum required length is 3"
                        : null;
                    },
                    validName: (fieldValue) => {
                      return (
                        !bannedInputs.some((value) =>
                          fieldValue.includes(value)
                        ) || "Enter a different name"
                      );
                    },
                  },
                  pattern: {
                    value: textWithSpaceRegex,
                    message:
                      "Invalid name, must not start or end with space and must contain only alphabets",
                  },
                })}
              />
              <span className="input-icons icon-span">
                <UserIcon />
              </span>
            </div>
            {errors.investorName && (
              <div className="col-span-2 ps-3">
                <FormError message={errors.investorName.message} />
              </div>
            )}
          </div>
          <div>
            <label htmlFor="investorPhone" className="mb-0 text-sm">
              Change Contact Number
            </label>
            <div
              className="flex mb-3 rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                maxLength="10"
                className="border-0 w-full ps-3"
                id="investorPhone"
                name="investorPhone"
                {...register("investorPhone", {
                  validate: {
                    minValue: (fieldValue) => {
                      return fieldValue.length > 0
                        ? fieldValue.length > 6 ||
                            "Number should be atleast 7 digits"
                        : null;
                    },
                    maxValue: (fieldValue) => {
                      return fieldValue.length > 0
                        ? fieldValue.length < 13 ||
                            "Number cannot be more than 12 digits"
                        : null;
                    },
                  },
                  pattern: {
                    value: numberRegex,
                    message: "Number must only contain digits",
                  },
                })}
              />
              <span className="input-icons icon-span">
                <PhoneIcon />
              </span>
            </div>
            {errors.investorPhone && (
              <div className="col-span-2 ps-3">
                <FormError message={errors.investorPhone.message} />
              </div>
            )}
          </div>
          <div>
            <label htmlFor="investorLinkedIn" className="mb-0 text-sm">
              LinkedIn
            </label>
            <div
              className="flex mb-3 rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="border-0 w-full ps-3"
                id="investorLinkedIn"
                name="investorLinkedIn"
                {...register("investorLinkedIn", {
                  validate: {
                    validUrl: (fieldValue) => {
                      return fieldValue.trim().length > 0
                        ? urlRegex.test(fieldValue) ||
                            "Please enter a valid url"
                        : null;
                    },
                    bannedUrl: (fieldValue) => {
                      return fieldValue.trim().length > 0
                        ? !bannedInputs.some((value) =>
                            fieldValue.includes(value)
                          ) || "Enter a different url"
                        : null;
                    },
                  },
                })}
              />
              <span className="input-icons icon-span">
                <LinkedInIcon />
              </span>
            </div>
            {errors.investorLinkedIn && (
              <div className="col-span-2">
                <FormError message={errors.investorLinkedIn.message} />
              </div>
            )}
          </div>
          <div>
            <label htmlFor="investedCompanyCount" className="mb-0 text-sm">
              Total Companies invested in ?
            </label>
            <div
              className="flex mb-3 rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="number"
                className="border-0 w-full ps-3"
                id="investedCompanyCount"
                name="investedCompanyCount"
                {...register("investedCompanyCount", {
                  pattern: {
                    value: numberRegex,
                    message: "Count must only contain digits",
                  },
                })}
              />
              <span className="input-icons icon-span">
                <MoneyUpTrendIcon />
              </span>
            </div>
            {errors.investedCompanyCount && (
              <div className="col-span-2 ps-3">
                <FormError message={errors.investedCompanyCount.message} />
              </div>
            )}
          </div>
          <div className="flex-col">
            <p className="text-sm">
              Industry <span>({selectedIndustry.length + "/3"})</span>
              <span
                id="industry-limit"
                className="hidden text-red-500 text-sm ms-2"
              >
                Limit exceeded
              </span>
            </p>
            <Dropdown
              options={industries?.map((industry) => ({
                label: industry.industry_name
                  .replace(new RegExp("_", "g"), " ")
                  .toUpperCase(),
                value: industry.industry_name,
              }))}
              value={selectedIndustry.map((industry) => ({
                label: industry
                  .replace(new RegExp("_", "g"), " ")
                  .toUpperCase(),
                value: industry,
              }))}
              onChange={(selectedOptions) =>
                setSelectedIndustry(
                  selectedOptions.map((option) => option.value)
                )
              }
              isMulti
            />
            <small className="text-red-500 mb-2 hidden" id="industry-error">
              This field is mandatory.
            </small>
          </div>
          <div>
            <label htmlFor="fillTotalInvestment" className="mb-0 text-sm">
              Total Investment done till now
            </label>
            <div className="select-input w-full rounded-md m-auto border border-slate-300">
              <select
                name="fillTotalInvestment"
                id="fillTotalInvestment"
                className="border-0 w-full"
                {...register("fillTotalInvestment")}
              >
                <option value="">Select Amount</option>
                <option value="Upto 10Lakh">Upto 10Lakh</option>
                <option value="10Lakh-25Lakh">10Lakh-25Lakh</option>
                <option value="25Lakh-50Lakh">25Lakh-50Lakh</option>
                <option value="50Lakh-1Cr">50Lakh-1Cr</option>
                <option value="More than 1Cr">1Cr+</option>
              </select>
            </div>
          </div>
          <div className="flex-col">
            <label htmlFor="futureInvestmentAmount" className="mb-0 text-sm">
              How much are you looking to invest
            </label>
            <div className="select-input w-full rounded-md m-auto border border-slate-300">
              <select
                name="futureInvestmentAmount"
                id="futureInvestmentAmount"
                className="border-0 w-full"
                {...register("futureInvestmentAmount")}
              >
                <option value="">Select Amount</option>
                <option value="Upto 10Lakh">Upto 10Lakh</option>
                <option value="10Lakh-25Lakh">10Lakh-25Lakh</option>
                <option value="25Lakh-50Lakh">25Lakh-50Lakh</option>
                <option value="50Lakh-1Cr">50Lakh-1Cr</option>
                <option value="More than 1Cr">1Cr+</option>
              </select>
            </div>
          </div>
          <div className="md:col-span-2 flex items-center justify-end p-3">
            <Button
              onClick={onClose}
              className="px-2 py-1 mx-1 text-sm"
              bgColor="bg-secondary"
            >
              Close
            </Button>
            <Button type="submit" className="px-2 py-1 mx-1 text-sm">
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
