import { ResponsiveLine } from "@nivo/line";

const LineChart = ({ data = [], mainType, subType, height = "h-[300px]" }) => {
  const formatCurrency = (value) => {
    if (mainType === "revenue") {
      return `₹${value.toLocaleString()}`;
    } else return value;
  };
  const filterData = () => {
    if (mainType === "user") {
      return subType === "totalUsers"
        ? data.totalUsers
        : subType === "startups"
        ? data.startups
        : subType === "mentors"
        ? data.mentors
        : subType === "investors"
        ? data.investors
        : data.vendors;
    } else {
      return subType === "total"
        ? data.total
        : subType === "market"
        ? data.market
        : subType === "subscription"
        ? data.subscription
        : data.connection;
    }
  };
  const updatedData = filterData();

  const getColor = () => {
    if (subType === "total" || subType === "totalUsers") return "#17335E";
    if (subType === "subscription" || subType === "startups") return "#B02444";
    if (subType === "market" || subType === "mentors") return "#22C55E";
    if (subType === "connection" || subType === "investors") return "#F97316";
    return "#627BFA";
  };

  const axisLeftLegend =
    mainType === "user"
      ? "USER COUNT"
      : mainType === "revenue"
      ? "REVENUE( ₹ )"
      : null;

  return (
    <div className={`${height} w-full`}>
      <ResponsiveLine
        data={updatedData}
        margin={{ top: 30, right: 40, bottom: 50, left: 70 }}
        theme={{
          text: {
            fontSize: "14px",
            fill: "#ffffff",
          },
          axis: {
            ticks: {
              text: {
                fontSize: "12px",
                fill: "#000000",
              },
            },
            legend: {
              text: {
                fill: "#000000",
                fontSize: "13px",
              },
            },
            domain: {
              line: {
                stroke: "#777777",
              },
            },
          },
          legends: {
            text: {
              fill: "#000000",
              fontSize: "12px",
              textTransform: "cap",
            },
          },
        }}
        colors={getColor}
        enableArea={true}
        curve="catmullRom"
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: 0,
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        yFormat={formatCurrency}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: axisLeftLegend,
          legendOffset: -50,
          legendPosition: "middle",
          tickValues: 4,
        }}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        tooltip={({ point }) => (
          <CustomTooltip point={point} mainType={mainType} />
        )}
      />
    </div>
  );
};

export default LineChart;

const CustomTooltip = ({ point, mainType }) => {
  return (
    <div className="bg-white shadow rounded-md p-1">
      <div>
        <span className="font-medium me-1">Month: </span>
        {point?.data?.xFormatted}
      </div>
      <div>
        <span className="font-medium me-1">
          {mainType === "user" ? "Count" : "Revenue"}:
        </span>{" "}
        {point?.data?.yFormatted}
      </div>
    </div>
  );
};
