import React, { useState, useCallback, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useFetch from "../../../hooks/useFetch";
import useNotificationApi from "../../../hooks/useNotificationApi";
import { useToastContext } from "../../../context/ToastContext";
import { SignedAgreements } from "./signedAgreements";
import { AllAgreements } from "./allAgreements";
import { UnSignedAgreements } from "./unsignedAgreements";
import { Loader } from "../../../components/ui/Loader";
import { Error } from "../../../components/ui/Error";
import { EditAgreementModal } from "../../../components/ui/modals/investment/edtiAgreementModal";
import { Button } from "../../../components/ui/Button";
import { ConfirmInvestment } from "../../../components/ui/modals/investment/confirmInvestment";
import Navbar from "../../../components/layout/Navbar";
import Sidebar from "../../../components/layout/Sidebar";
import { ViewInvestmentHistory } from "../../../components/ui/modals/investment/viewInvestmentHistory";
import { getCurrentDate } from "../../../utils/getCurrentDate";

export const InvestorInvestments = () => {
  const getAgreementsApi = `${process.env.REACT_APP_API_URL}get-agreements`;
  const editAgreementApi = `${process.env.REACT_APP_API_URL}edit-agreement`;
  const confirmInvestmentApi = `${process.env.REACT_APP_API_URL}confirm-investment`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole, name } = useLocalStorage();
  const { sendNotification } = useNotificationApi();
  const navigate = useNavigate();
  const toast = useToastContext();

  const [agreementDetails, setAgreementDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [agreementId, setAgreementId] = useState("");
  const [transactionData, setTransactionData] = useState({
    total: 0,
    pending: 0,
    released: 0,
  });
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [propDetails, setPropDetails] = useState({});
  const [tableType, setTableType] = useState("All");
  const ref = useRef(true);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [investmentData, setInvestmentData] = useState();

  const getAgreements = useCallback(() => {
    fetchApi(
      {
        url: getAgreementsApi,
        headers: {
          "x-token": token,
        },
      },
      (data) => setAgreementDetails(data.agreement_details)
    );
  }, [getAgreementsApi, token, fetchApi]);

  const editAgreement = useCallback(
    (agreementId, data, userId) => {
      fetchApi(
        {
          url: editAgreementApi,
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            id: agreementId,
            amount: data.editAmount,
          },
        },
        (data) => {
          if (data.message === "agreement not found") {
            toast.open("false", "Update failed. Retry.");
          } else {
            toast.open("true", "Update successfull.");
            sendNotification(
              userId,
              `${name} has edited the agreement. Please check details`,
              "investments"
            );
            getAgreements();
          }
        }
      );
    },
    [
      editAgreementApi,
      token,
      name,
      fetchApi,
      getAgreements,
      sendNotification,
      toast,
    ]
  );

  const confirmInvestmentDetails = (agreementId, transferDetails) => {
    const formData = new FormData();
    formData.append("document[]", transferDetails.proof);
    formData.append("transaction_id", transferDetails.transactionId);
    formData.append("amount", transferDetails.amount);
    formData.append("type", transferDetails.transactionType);
    formData.append("agreement_id", agreementId);
    formData.append("date", getCurrentDate());
    axios
      .post(confirmInvestmentApi, formData, {
        headers: { "x-token": token },
      })
      .then(() => {
        toast.open("true", "Funds released successfully!.");
        getAgreements();
      })
      .catch(() =>
        toast.open("false", "Failed to release funds, Retry again.")
      );
  };

  useEffect(() => {
    if (userRole !== "Sw%aa") {
      navigate("/");
      return;
    }
    if (ref.current) {
      getAgreements();
    }
    return () => {
      ref.current = false;
    };
  }, [getAgreements, navigate, userRole]);

  const handleEditModal = (amount, agreementId, userId) => {
    setPropDetails({
      amount: amount,
      agreementId: agreementId,
      userId: userId,
    });
    setOpen(true);
  };

  const viewAgreement = (agreement) => {
    navigate("/view-agreement", {
      state: {
        agreement: agreement,
      },
    });
  };

  const confirmTransaction = (id, total, pending, released) => {
    setAgreementId(id);
    setTransactionData({
      total,
      pending,
      released,
    });
    setOpenTransactionModal(true);
  };

  const openHistoryModal = (data) => {
    setInvestmentData(data);
    setHistoryModal(true);
  };

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="p-3 md:p-6">
          <div className="flex justify-between items-center">
            <h4 className="font-medium">{tableType} Agreements</h4>
            <div className="flex">
              <Button
                onClick={() => setTableType("All")}
                className="px-2 py-1 mx-1 text-sm"
              >
                All
              </Button>
              <Button
                onClick={() => setTableType("Signed")}
                className="px-2 py-1 mx-1 text-sm"
                bgColor="bg-green-600"
              >
                Signed
              </Button>
              <Button
                onClick={() => setTableType("Unsigned")}
                className="px-2 py-1 mx-1 text-sm"
                bgColor="bg-[#F97316]"
              >
                Un-Signed
              </Button>
            </div>
          </div>
          <div className="bg-white shadow-md p-2 rounded-md my-3">
            <h5 className="font-semibold">Steps to transfer funds</h5>
            <div className="space-y-2">
              <p className="text-xs font-medium">
                1. Make sure you have recieved all the neccessary legal
                investment documents from startup.
              </p>
              <div className="text-xs font-medium">
                2. Transfer funds to the platform through "Net Banking" or
                "Cheque"
                <div className="ms-4">
                  <p className="text-xs font-medium">Transfer to :</p>
                  <p className="text-xs font-medium">
                    •{" "}
                    <span className="font-semibold me-1">
                      Account Holder Name:
                    </span>{" "}
                    SDBT Solutions (OPC) Private Limited
                  </p>
                  <p className="text-xs font-medium">
                    • <span className="font-semibold me-1">Bank Name:</span>{" "}
                    ICICI Bank
                  </p>
                  <p className="text-xs font-medium">
                    •{" "}
                    <span className="font-semibold me-1">
                      RTGS/NEFT IFS Code:
                    </span>{" "}
                    ICIC0002795
                  </p>
                  <p className="text-xs font-medium">
                    • <span className="font-semibold me-1">Account No :</span>
                    279505000806
                  </p>
                </div>
              </div>
              <p className="text-xs font-medium">
                3. Click the Release Fund button of that specific agreement and
                enter the transaction details.
              </p>
              <p className="text-xs font-medium">
                4. You will be notified once the admin has confirmed your
                transaction.
              </p>
            </div>
          </div>
          {error && <Error />}
          {isLoading && <Loader />}
          {!isLoading && !error && (
            <div className="overflow-auto w-full md:overflow-none">
              {tableType === "All" ? (
                <AllAgreements
                  agreementDetails={agreementDetails}
                  handleEditModal={handleEditModal}
                  viewAgreement={viewAgreement}
                  confirmTransaction={confirmTransaction}
                  openHistoryModal={openHistoryModal}
                />
              ) : tableType === "Signed" ? (
                <SignedAgreements
                  agreementDetails={agreementDetails}
                  handleEditModal={handleEditModal}
                  viewAgreement={viewAgreement}
                  confirmTransaction={confirmTransaction}
                  openHistoryModal={openHistoryModal}
                />
              ) : (
                <UnSignedAgreements
                  agreementDetails={agreementDetails}
                  handleEditModal={handleEditModal}
                  viewAgreement={viewAgreement}
                  confirmTransaction={confirmTransaction}
                  openHistoryModal={openHistoryModal}
                />
              )}
            </div>
          )}
        </div>
        <EditAgreementModal
          open={open}
          onClose={() => setOpen(false)}
          propDetails={propDetails}
          editAgreement={editAgreement}
        />
        <ConfirmInvestment
          open={openTransactionModal}
          onClose={() => setOpenTransactionModal(false)}
          agreementId={agreementId}
          transactionData={transactionData}
          confirmInvestmentDetails={confirmInvestmentDetails}
        />
        <ViewInvestmentHistory
          open={historyModal}
          onClose={() => setHistoryModal(false)}
          agreementDetails={investmentData}
          userRole={userRole}
        />
      </div>
    </div>
  );
};
