import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const startupDashboardApi = `${process.env.REACT_APP_API_URL}startup-dashboard`;

const initialState = {
  name: "",
  logo: "",
  description: "",
  claim_status: null,
  profile_completion: 0,
  connected_mentors: 0,
  connected_investors: 0,
  used_credits: 0,
  investors: 0,
  mentors: 0,
  startups: 0,
  vendors: 0,
  userTransactions: [],
  userConnections: [],
  barGraphData: [],
  dashboardStatus: "idle",
  dashboardError: null,
};

export const startupDashboard = createAsyncThunk(
  "startup/fetchDashboardData",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(startupDashboardApi, {
        headers: {
          "x-token": token,
        },
      });
      const data = response.data;

      return {
        name: data.name,
        logo: data.logo,
        description: data.description,
        claim_status: data.claim_status,
        profile_completion: data.profile_completion,
        connected_mentors: data.connected_mentors,
        connected_investors: data.connected_investors,
        used_credits: data.used_credit,
        investors: data.investor_count,
        mentors: data.mentor_count,
        startups: data.startup_count,
        vendors: data.vendor_count,
        userTransactions: data.transactions,
        userConnections: data.connections,
        barGraphData: data.industries_count,
      };
    } catch (error) {
      if (error.response.data.message === "Invalid token") {
        window.location.href = "/error-401";
      }
      let errorMessage = "An error occurred while fetching dashboard data.";
      if (error.response && error.response.data) {
        errorMessage = error.response.data.message || errorMessage;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

const startupDashboardSlice = createSlice({
  name: "startupDashboard",
  initialState,
  reducers: {
    clearDashboard: (state) => {
      Object.assign(state, initialState);
    },
    updateClaimStatus: (state, action) => {
      state.claim_status = action.payload;
    },
    addTransaction: (state, action) => {
      state.userTransactions.unshift(action.payload);
    },
    updateProfileCompletion: (state, action) => {
      state.profile_completion = action.payload;
    },
    updateTokensUsed: (state, action) => {
      state.used_credits =
        action.payload.type === "remove"
          ? state.used_credits - action.payload.amount
          : state.used_credits + action.payload.amount;

      state.used_credits = state.used_credits >= 0 ? state.used_credits : 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startupDashboard.pending, (state) => {
        state.dashboardStatus = "loading";
      })
      .addCase(startupDashboard.fulfilled, (state, action) => {
        state.dashboardStatus = "succeeded";
        state.name = action.payload.name;
        state.logo = action.payload.logo;
        state.description = action.payload.description;
        state.claim_status = action.payload.claim_status;
        state.profile_completion = action.payload.profile_completion;
        state.connected_mentors = action.payload.connected_mentors;
        state.connected_investors = action.payload.connected_investors;
        state.used_credits = action.payload.used_credits;
        state.investors = action.payload.investors;
        state.mentors = action.payload.mentors;
        state.startups = action.payload.startups;
        state.vendors = action.payload.vendors;
        state.userTransactions = action.payload.userTransactions;
        state.userConnections = action.payload.userConnections;
        state.barGraphData = action.payload.barGraphData;
      })
      .addCase(startupDashboard.rejected, (state, action) => {
        state.dashboardStatus = "failed";
        state.dashboardError = action.payload;
      });
  },
});

export const {
  clearDashboard,
  updateClaimStatus,
  addTransaction,
  updateProfileCompletion,
  updateTokensUsed,
} = startupDashboardSlice.actions;
export default startupDashboardSlice.reducer;
