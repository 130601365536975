import {
  RocketIcon,
  ShoppingCartIcon,
  UserGraduateIcon,
  UserIcon,
  UserTieIcon,
} from "../../../icons/icons";

const SmallCardWithoutButton = ({
  data,
  icon,
  gradient,
  font = "normal",
  titleColor = "",
}) => {
  const iconType = () => {
    switch (icon) {
      case "startup":
        return <RocketIcon height="20px" fill="#fff" />;
      case "mentor":
        return <UserGraduateIcon height="20px" fill="#fff" />;
      case "investor":
        return <UserTieIcon height="20px" fill="#fff" />;
      case "vendor":
        return <ShoppingCartIcon height="20px" fill="#fff" />;
      case "total":
        return <UserIcon height="20px" fill="#fff" />;
      default:
        return <UserIcon height="20px" fill="#fff" />;
    }
  };
  return (
    <div className="card course-box h-full">
      <div className="flex items-center justify-between h-full">
        <div className={`course-icon ${gradient}`}>{iconType()}</div>
        <div className={`flex flex-col font-${font} text-end`}>
          <h4 className="mb-0 text-xl">{data.count}+</h4>
          <span className={`${titleColor} font-medium`}>{data.title}</span>
        </div>
      </div>
    </div>
  );
};

export default SmallCardWithoutButton;
