import { useCallback } from "react";
import useFetch from "./useFetch";
import useLocalStorage from "./useLocalStorage";

const useCheckConnection = () => {
  const checkConnectionApi = `${process.env.REACT_APP_API_URL}check-connection`;

  const { token } = useLocalStorage();
  const { fetchApi } = useFetch();

  const checkConnection = useCallback(
    (id, setConnectionStatus) => {
      fetchApi(
        {
          url: checkConnectionApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            connection_id: id,
          },
        },
        (data) => {
          if (data.message === "connected") {
            setConnectionStatus((prev) => ({ ...prev, status: "connected" }));
          } else if (data.message === "pending") {
            setConnectionStatus((prev) => ({
              ...prev,
              status: "pending",
              senderId: data.sender_id,
            }));
          } else if (data.message === "disconnect") {
            setConnectionStatus((prev) => ({ ...prev, status: "disconnect" }));
          } else {
            setConnectionStatus((prev) => ({ ...prev, status: "blocked" }));
          }
        }
      );
    },
    [checkConnectionApi, fetchApi, token]
  );
  return { checkConnection };
};

export default useCheckConnection;
