import { Button } from "../../Button";
import { FormError } from "../../FormError";
import { numberRegex, numberWithDecimalRegex } from "../../../../constants";

const TopUpForm = ({ submitModal, register, handleSubmit, errors, type }) => {
  return (
    <form
      onSubmit={handleSubmit((data) => submitModal(data))}
      className="block md:grid md:grid-cols-3 gap-3 pb-0 mt-6"
    >
      <div className="flex flex-col">
        <label htmlFor="role" className="mb-1 font-medium text-sm required">
          TopUp Plan For
        </label>
        <div className="select-input mb-1 w-full border border-slate-300 m-auto rounded-md">
          <select
            name="role"
            id="role"
            className="border-0 w-full text-gray-500"
            {...register("role", {
              required: "Select a user role",
            })}
          >
            <option value="startup">Startup</option>
            <option value="service provider">Service Provider</option>
          </select>
        </div>
        {errors.role && (
          <div>
            <FormError message={errors.role.message} />
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <label htmlFor="amount" className="mb-1 font-medium text-sm required">
          Plan Price
        </label>
        <input
          type="number"
          id="amount"
          name="amount"
          className="p-[0.7rem] border rounded-md"
          placeholder="Enter plan price"
          {...register("amount", {
            required: "price is required",
            pattern: {
              value: numberRegex,
              message: "Price must only contain digits",
            },
          })}
        />
        {errors.amount && (
          <div>
            <FormError message={errors.amount.message} />
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <label htmlFor="tokens" className="mb-1 font-medium text-sm required">
          Tokens
        </label>
        <input
          type="number"
          id="tokens"
          name="tokens"
          className="p-[0.7rem] border rounded-md"
          placeholder="Enter tokens"
          {...register("tokens", {
            required: "tokens is required",
            pattern: {
              value: numberRegex,
              message: "Tokens must only contain digits",
            },
            validate: {
              notZero: (fieldValue) => {
                return fieldValue > 0 || "Tokens cannot be 0";
              },
            },
          })}
        />
        {errors.tokens && (
          <div>
            <FormError message={errors.tokens.message} />
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <label
          htmlFor="discount_status"
          className="mb-1 font-medium text-sm required"
        >
          Discount Status
        </label>
        <div className="select-input mb-1 w-full border border-slate-300 m-auto rounded-md">
          <select
            name="discount_status"
            id="discount_status"
            className="border-0 w-full text-gray-500"
            {...register("discount_status", {
              required: "Set discount status",
            })}
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
        {errors.discount_status && (
          <div>
            <FormError message={errors.discount_status.message} />
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <label
          htmlFor="discount_type"
          className="mb-1 font-medium text-sm required"
        >
          Discount Type
        </label>
        <div className="select-input mb-1 w-full border border-slate-300 m-auto rounded-md">
          <select
            name="discount_type"
            id="discount_type"
            className="border-0 w-full text-gray-500"
            {...register("discount_type", {
              required: "Select a discount type",
            })}
          >
            <option value="flat">Flat</option>
            <option value="percentage">Percentage</option>
          </select>
        </div>
        {errors.discount_type && (
          <div>
            <FormError message={errors.discount_type.message} />
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <label htmlFor="discount" className="mb-1 font-medium text-sm required">
          Discount Amount/Percentage
        </label>
        <input
          type="text"
          id="discount"
          name="discount"
          className="p-[0.7rem] border rounded-md"
          placeholder="Enter discount"
          {...register("discount", {
            required: "amount/percentage is required",
            pattern: {
              value: numberWithDecimalRegex,
              message: "amount/percentage must only contain digits",
            },
          })}
        />
        {errors.discount && (
          <div>
            <FormError message={errors.discount.message} />
          </div>
        )}
      </div>
      <Button
        type="submit"
        className="col-span-3 w-fit ml-auto px-3 py-1.5 mx-1"
      >
        {type === "add" ? "Add" : "Update"}
      </Button>
    </form>
  );
};

export default TopUpForm;
