import ReactDom from "react-dom";
import parse from "html-react-parser";

const ViewSummary = ({ open, onClose, data, userId }) => {
  if (!open) return null;

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`max-w-[90%] md:min-w-[750px] md:max-w-[750px] bg-[#f9f9f9] rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="py-2 border-b text-center font-semibold text-base">
          Meeting Summary
        </h4>
        <div className="h-[450px] overflow-auto p-5 flex flex-col items-start gap-y-5">
          {data.length > 0 &&
            data.map((summary) => (
              <div key={summary._id}>
                <h5 className="font-medium text-lg mb-1">
                  {summary.user_id === userId
                    ? "My Summary"
                    : "Other Users Summary"}
                </h5>
                <div>{parse(summary.summary)}</div>
              </div>
            ))}
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};

export default ViewSummary;
