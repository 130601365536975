import React, { useCallback, useEffect, useMemo } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotifications,
  markAllNotificationsAsRead,
} from "../../store/slices/NotificationSlice";
import useCheckRole from "../../hooks/useCheckRole";
import useLocalStorage from "../../hooks/useLocalStorage";
import useWindowDimensions from "../../hooks/useWindowDimension";
import { useToastContext } from "../../context/ToastContext";
import NotiType from "../../utils/notificationRoute";
import SingleNotification from "../ui/singleNotification";
import {
  BarsIcon,
  BellIcon,
  CircleExclamationIcon,
  ClipBoardIcon,
  CommentIcon,
  CreditCardIcon,
  GearIcon,
  HelpIcon,
  LogoutIcon,
  UserIcon,
  WalletIcon,
} from "../../icons/icons";
import pitchHereLogo from "../../images/pitch-here-logo.png";
import FavIcon from "../../images/favicon.png";
import PHcoin from "../../images/PH-coin.svg";
import {
  fetchCredits,
  fetchFreezePoints,
} from "../../store/slices/FetchCreditsSlice";

const Navbar = (props) => {
  const markAsReadApi = `${process.env.REACT_APP_API_URL}mark-as-read`;
  const updateNotificationApi = `${process.env.REACT_APP_API_URL}update-notification`;

  const { role, CheckRole } = useCheckRole();
  const navigate = useNavigate();
  const { token, userRole, name } = useLocalStorage();
  const { width } = useWindowDimensions();
  const toast = useToastContext();

  const dispatch = useDispatch();
  const {
    notifications,
    notificationCount,
    unReadMsg,
    profileImg,
    status,
    error,
  } = useSelector((state) => state.notifications);
  const { credits, freezePoints, creditsStatus, creditsError } = useSelector(
    (state) => state.credits
  );

  const memoizedNotiType = useMemo(() => NotiType, []);

  const checkedRole = () => {
    if (role === 1) {
      navigate("/startup-profile");
    } else if (role === 2) {
      navigate("/mentor-profile");
    } else if (role === 3) {
      navigate("/investor-profile");
    } else {
      navigate("/vendor-profile");
    }
  };

  const goToWallet = () => {
    if (role === 1) {
      navigate("/startup-wallet");
    } else if (role === 2) {
      navigate("/mentor-wallet");
    } else {
      navigate("/vendor-wallet");
    }
  };

  const navigateToNotifications = useCallback(() => {
    dispatch(markAllNotificationsAsRead());
    axios
      .put(markAsReadApi, null, {
        headers: {
          "x-token": token,
        },
      })
      .then((res) => {})
      .catch(() =>
        toast.open(
          "false",
          "Sorry, something went wrong while updating notification"
        )
      );
    navigate("/notifications");
  }, [toast, token, markAsReadApi, dispatch, navigate]);

  const updateNotification = useCallback(
    (notificationId) => {
      axios
        .put(
          updateNotificationApi,
          { id: notificationId },
          {
            headers: {
              "x-token": token,
            },
          }
        )
        .then((res) => {})
        .catch(() =>
          toast.open(
            "false",
            "Sorry, something went wrong while updating notification"
          )
        );
    },
    [token, updateNotificationApi, toast]
  );

  useEffect(() => {
    const navbarDependencies = setTimeout(() => {
      CheckRole(userRole);
      if (status === "idle") {
        dispatch(fetchNotifications(token));
      }
      if (status === "failed" || creditsStatus === "failed") {
        toast.open("false", error || creditsError);
      }
      if ((role === 1 || role === 4) && creditsStatus === "idle") {
        dispatch(fetchCredits(token));
      }
      if (role === 2 && creditsStatus === "idle") {
        dispatch(fetchFreezePoints(token));
      }
    });
    return () => {
      clearTimeout(navbarDependencies);
    };
  }, [
    CheckRole,
    role,
    userRole,
    dispatch,
    token,
    status,
    error,
    toast,
    creditsStatus,
    creditsError,
  ]);

  return (
    <div
      className="sticky top-0 m-0 z-[1] px-4 py-2 bg-white md:relative shadow-[5px_1px_20px_-6px_rgba(0,0,0,0.4)] grid grid-cols-3 md:gap-x-4"
      style={props.style}
    >
      {width < 1024 && (
        <img src={pitchHereLogo} alt="pitchere-logo" width={100} />
      )}
      <div className="hidden col-start-3 lg:flex lg:justify-end lg:items-center lg:gap-x-3">
        <div className="relative group me-2 cursor-pointer">
          <HelpIcon width="24px" height="24px" fill="#adadad" />
          <div className="hidden group-hover:block absolute right-[10%] top-[90%] card w-[160px] bg-primary shadow-md">
            <ul className="fa-ul text-white space-y-3">
              <li className="text-sm text-white hover:text-customOrange">
                <Link to="/faq" className="flex items-center">
                  <span className="fa-li pr-2">
                    <CircleExclamationIcon fill="#fff" />
                  </span>
                  FAQ
                </Link>
              </li>
              <li className="text-sm text-white hover:text-customOrange">
                <Link to="/tickets" className="flex items-center w-max">
                  <span className="fa-li pr-2">
                    <ClipBoardIcon fill="#fff" />
                  </span>
                  Support
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="relative group me-2 cursor-pointer">
          <div className="relative">
            <BellIcon width="24px" height="24px" fill="#adadad" />
            {notificationCount !== 0 && (
              <div className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 border-2 border-transparent rounded-full -top-2 -right-2">
                {notificationCount}
              </div>
            )}
          </div>
          <div className="hidden group-hover:block absolute right-[10%] top-[90%] card w-[250px] bg-primary text-center shadow-md">
            <ul>
              {notifications.length > 0 ? (
                notifications
                  .slice(0, 5)
                  .map((noti) => (
                    <SingleNotification
                      key={noti.id}
                      noti={noti}
                      userRole={userRole}
                      memoizedNotiType={memoizedNotiType}
                      updateNotification={updateNotification}
                    />
                  ))
              ) : (
                <li className="text-white">No new notifications</li>
              )}
              <li className="mt-3">
                <p
                  onClick={() => navigateToNotifications()}
                  className="bg-primary mt-2 px-2 py-1 rounded text-white hover:text-white"
                >
                  View all
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="relative">
          <Link to="/chat">
            <CommentIcon
              width="24px"
              height="24px"
              fill="#adadad"
              className="cursor-pointer"
            />
          </Link>
          {unReadMsg !== 0 && (
            <div className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 border-2 border-transparent rounded-full -top-2 -right-2">
              {unReadMsg}
            </div>
          )}
        </div>
        <div className="relative group flex justify-center lg:justify-normal cursor-pointer">
          <div className="h-[50px] w-[50px] border flex items-center justify-center rounded-full">
            <img
              src={profileImg.length > 0 ? profileImg : FavIcon}
              className="rounded-full p-0.5 h-[50px]"
              alt="profile-img"
              width={50}
            />
          </div>
          <div className="hidden group-hover:block absolute right-[5%] mt-[50px] card w-max shadow-md bg-primary">
            <ul className="fa-ul">
              <li
                onClick={checkedRole}
                className="flex items-center mt-1 mb-2 ps-1 text-sm text-white capitalize hover:text-customOrange"
              >
                <span className="fa-li pr-2">
                  <UserIcon fill="#fff" />
                </span>
                {name}
              </li>
              {role !== 3 && (
                <li
                  onClick={() => {
                    if (role !== 4) {
                      goToWallet();
                    }
                  }}
                  className="flex items-center mt-1 mb-2 ps-1 text-sm text-white hover:text-customOrange"
                >
                  <span className="fa-li pr-2">
                    {role === 2 ? (
                      <CreditCardIcon fill="#fff" />
                    ) : (
                      <img src={PHcoin} alt="PH coin" width={20} />
                    )}
                  </span>
                  {role === 2 ? <>&#8377; {freezePoints}</> : credits}
                </li>
              )}
              {role === 4 && (
                <li
                  onClick={goToWallet}
                  className="flex items-center mt-1 mb-2 ps-1 text-sm text-white hover:text-customOrange"
                >
                  <span className="fa-li pr-2">
                    <WalletIcon fill="#fff" />
                  </span>
                  Wallet
                </li>
              )}
              <li className="mt-1 mb-2 ps-1 text-sm text-white hover:text-customOrange">
                <Link
                  to="/settings"
                  className="flex items-center hover:text-customOrange"
                >
                  <span className="fa-li pr-2">
                    <GearIcon fill="#fff" />
                  </span>
                  Settings
                </Link>
              </li>
              <li className="font-medium mt-1 ps-1 text-sm text-white hover:text-customOrange">
                <Link
                  to="/"
                  className="flex items-center hover:text-customOrange"
                >
                  <span className="fa-li pr-2">
                    <LogoutIcon fill="#fff" />
                  </span>
                  LogOut
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className="col-span-2 flex items-center justify-end gap-x-5 lg:hidden"
        id="sidebar-icon"
      >
        <div className="relative group">
          <HelpIcon width="20px" height="20px" fill="#adadad" />
          <div className="hidden group-hover:block absolute right-[10%] top-[90%] card w-[160px] bg-primary shadow-md">
            <ul className="fa-ul text-white space-y-3">
              <li className="text-sm text-white hover:text-customOrange">
                <Link to="/faq" className="flex items-center">
                  <span className="fa-li pr-2">
                    <CircleExclamationIcon fill="#fff" />
                  </span>
                  FAQ
                </Link>
              </li>
              <li className="text-sm text-white hover:text-customOrange">
                <Link to="/tickets" className="flex items-center w-max">
                  <span className="fa-li pr-2">
                    <ClipBoardIcon fill="#fff" />
                  </span>
                  Support
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="relative group">
          <div className="relative">
            <Link to="/notifications">
              <BellIcon
                width="20px"
                height="20px"
                fill="#adadad"
                className="cursor-pointer"
              />
            </Link>
            {notificationCount !== 0 && (
              <div className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 border-2 border-transparent rounded-full -top-2 -right-2">
                {notificationCount}
              </div>
            )}
          </div>
        </div>
        <div className="relative">
          <Link to="/chat">
            <CommentIcon
              width="20px"
              height="20px"
              fill="#adadad"
              className="cursor-pointer"
            />
          </Link>
          {unReadMsg !== 0 && (
            <div className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 border-2 border-transparent rounded-full -top-2 -right-2">
              {unReadMsg}
            </div>
          )}
        </div>
        <BarsIcon onClick={props.handleSidebar} />
      </div>
    </div>
  );
};

export default Navbar;
