import ReactDom from "react-dom";
import parse from "html-react-parser";
import { Button } from "../Button";
import PolicyAndTerms from "../../../json-data/policyAndTerms.json";

export const PrivacyPolicy = ({ open, onClose }) => {
  if (!open) return null;

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`w-[90%] md:max-w-[50%] h-[400px] overflow-auto bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <h3 className="font-medium text-2xl">Privacy Policy</h3>
        <div className="my-2">{parse(PolicyAndTerms.privacyPolicy)}</div>
        <h3 className="font-medium text-2xl mt-5">Terms of Use</h3>
        <div className="my-2">{parse(PolicyAndTerms.terms)}</div>
        <Button onClick={onClose} className="block px-2 py-1 my-4 ml-auto">
          Mark as Read
        </Button>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
