import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Pagination } from "../../components/ui/Button";
import { EyeIcon } from "../../icons/icons";

export const WalletTable = ({ wallet, processedAmount, walletDetails }) => {
  const [page, setPage] = useState(1);
  const { freezePoints } = useSelector((state) => state.credits);

  const { userRole } = useLocalStorage();

  return (
    <>
      <p className="mt-2 mb-0 md:mt-0">
        <span className="me-2 font-bold text-xs md:text-sm">
          Total Earning :{" "}
        </span>
        {userRole === "V&iR8" ? walletDetails.total : processedAmount.total}
      </p>
      <p>
        <span className="me-2 font-bold text-xs md:text-sm">
          Balance Amount :{" "}
        </span>
        {userRole === "V&iR8" ? walletDetails.balance : freezePoints}
      </p>
      {userRole === "R$7s2" && (
        <p className="mt-2 mb-0 md:mt-0">
          <span className="me-2 font-bold text-xs md:text-sm">
            Freezed Amount :{" "}
          </span>
          {processedAmount.freezed}
        </p>
      )}
      {userRole === "V&iR8" && (
        <p>
          <span className="me-2 font-bold text-xs md:text-sm">
            Debited Amount :{" "}
          </span>
          {walletDetails.debited}
        </p>
      )}
      {userRole !== "V&iR8" && (
        <p>
          <span className="me-2 font-bold text-xs md:text-sm">
            Withdraw Request :{" "}
          </span>
          {processedAmount.withdraw_request}
        </p>
      )}
      <div className="flex items-center mt-2">
        {userRole === "V&iR8" && (
          <>
            <span className="me-2 font-semibold text-xs">Note :</span>
            <span className="mb-0 font-medium text-xs">
              The awarded amount is freezed and is only transfered to your
              account once the Project is marked as completed by startup.
            </span>
          </>
        )}
      </div>
      <div className="overflow-auto w-full md:overflow-none">
        <table className="table-auto min-w-max w-full mt-4 border shadow-sm border-slate-300">
          <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
            <tr>
              <th className="p-3 font-medium text-xs md:text-sm">Name</th>
              {userRole === "V&iR8" && (
                <th className="p-3 font-medium text-xs md:text-sm">
                  Project Title
                </th>
              )}
              <th className="p-3 font-medium text-xs md:text-sm">Date</th>
              <th className="p-3 font-medium text-xs md:text-sm">Amount</th>
              <th className="p-3 font-medium text-xs md:text-sm">Status</th>
              {userRole === "V&iR8" ? null : (
                <th className="p-3 font-medium text-xs md:text-sm">Action</th>
              )}
            </tr>
          </thead>
          <tbody>
            {wallet.length > 0 ? (
              wallet.slice(page * 7 - 7, page * 7).map((transaction) => (
                <tr
                  key={transaction.id}
                  className="bg-white border-b border-slate-300 align-middle h-auto"
                >
                  <td className="p-3 text-xs md:text-sm capitalize font-medium">
                    {transaction.name}
                  </td>
                  {userRole === "V&iR8" && (
                    <td className="p-3 text-xs md:text-sm capitalize">
                      {transaction.title}
                    </td>
                  )}

                  <td className="p-3 text-xs md:text-sm">{transaction.date}</td>
                  <td className="p-3 text-xs md:text-sm">
                    <span className="font-medium mr-1">&#8377;</span>
                    {userRole === "V&iR8"
                      ? transaction.amount
                      : transaction.freeze_points}
                  </td>
                  <td className="p-3">
                    <span
                      className={`p-1 text-xs ${
                        transaction.status === "0"
                          ? "blue-badge"
                          : transaction.status === "1"
                          ? "green-badge"
                          : "red-badge"
                      }
                      `}
                    >
                      {transaction.status === "0"
                        ? "Freezed"
                        : transaction.status === "1"
                        ? "Completed"
                        : transaction.status === "-1"
                        ? "Cancelled"
                        : null}
                    </span>
                  </td>
                  {userRole !== "V&iR8" && (
                    <td className="p-3 text-xs md:text-sm">
                      <div className="flex items-center justify-start h-full">
                        <Link
                          to={`/view-startup/${transaction.name
                            .split(" ")
                            .join("-")}/${transaction.startup_id}`}
                          title="View Profile"
                        >
                          <EyeIcon className="cursor-pointer fill-green-500 transition-colors ease-in duration-50 hover:fill-green-800" />
                        </Link>
                      </div>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr className="bg-white text-center">
                <td
                  colSpan={userRole === "V&iR8" ? 6 : 5}
                  className="text-xs md:text-sm p-3"
                >
                  No transaction found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {wallet.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          totalDataCount={wallet.length}
        />
      )}
    </>
  );
};
