import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useFetch from "../../../hooks/useFetch";
import useNotificationApi from "../../../hooks/useNotificationApi";
import { updateStatus } from "../../../store/slices/ChatSlice";
import { useToastContext } from "../../../context/ToastContext";
import { ConnectedUsers } from "../connectedUsers";
import { IncomingRequests } from "../incomingRequests";
import { OutgoingRequests } from "../outgoingRequests";
import { BlockedUsers } from "../blockedUsers";
import { Error } from "../../../components/ui/Error";
import { Loader } from "../../../components/ui/Loader";
import Navbar from "../../../components/layout/Navbar";
import Sidebar from "../../../components/layout/Sidebar";
import { Button } from "../../../components/ui/Button";
import { ConfirmationModal } from "../../../components/ui/modals/utility-modals/confirmationModal";
import { getCurrentDate } from "../../../utils/getCurrentDate";

export const MentorConnections = () => {
  const displayConnectionsApi = `${process.env.REACT_APP_API_URL}display-connections`;
  const blockListApi = `${process.env.REACT_APP_API_URL}block-list`;
  const incomingRequestApi = `${process.env.REACT_APP_API_URL}incoming-requests`;
  const outGoingConnectionReqApi = `${process.env.REACT_APP_API_URL}outgoing-requests`;
  const acceptOrCancelReqApi = `${process.env.REACT_APP_API_URL}accept-cancel-connect-request`;
  const blockApi = `${process.env.REACT_APP_API_URL}block`;
  const unBlockApi = `${process.env.REACT_APP_API_URL}unblock`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole, name } = useLocalStorage();
  const { sendNotification } = useNotificationApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToastContext();

  const [connections, setConnections] = useState([]);
  const [incomingRequest, setIncomingRequest] = useState([]);
  const [outGoingRequests, setOutGoingRequests] = useState([]);
  const [blockedConnections, setBlockedConnections] = useState([]);
  const [tableType, setTableType] = useState("connected");
  const [openSidebar, setOpenSidebar] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState({
    callbackFn: null,
    message: "",
  });
  const ref = useRef(true);

  const displayFriends = useCallback(() => {
    setTableType("connected");
    fetchApi(
      {
        url: displayConnectionsApi,
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setConnections(data.response);
      }
    );
  }, [displayConnectionsApi, token, fetchApi]);

  const blockList = useCallback(() => {
    setTableType("block");
    fetchApi(
      {
        url: blockListApi,
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setBlockedConnections(data.block_users);
      }
    );
  }, [blockListApi, token, fetchApi]);

  const getIncomingRequests = useCallback(() => {
    setTableType("incoming");
    fetchApi(
      { url: incomingRequestApi, headers: { "x-token": token } },
      (data) => setIncomingRequest(data.response)
    );
  }, [incomingRequestApi, fetchApi, token]);

  const getOutGoingRequests = useCallback(() => {
    setTableType("outgoing");
    fetchApi(
      {
        url: outGoingConnectionReqApi,
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setOutGoingRequests(data.response);
      }
    );
  }, [outGoingConnectionReqApi, token, fetchApi]);

  const block = useCallback(
    (id) => {
      fetchApi(
        {
          url: blockApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            connection_id: id,
            date: getCurrentDate(),
          },
        },
        (data) => {
          sendNotification(
            id,
            `You have been blocked by ${data.name}`,
            "connections"
          );
          toast.open("true", "Connection blocked successfully!");
          blockList();
          dispatch(updateStatus("-2"));
        }
      );
    },
    [blockApi, fetchApi, token, blockList, dispatch, sendNotification, toast]
  );

  const unBlock = useCallback(
    (id) => {
      fetchApi(
        {
          url: unBlockApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            connection_id: id,
            date: getCurrentDate(),
          },
        },
        () => {
          sendNotification(id, `${name} has un-blocked you`, "connections");
          toast.open("true", "Connection un-blocked successfully!");
          blockList();
          dispatch(updateStatus("1"));
        }
      );
    },
    [
      unBlockApi,
      fetchApi,
      token,
      blockList,
      dispatch,
      name,
      sendNotification,
      toast,
    ]
  );

  const acceptOrCancelRequest = useCallback(
    (type, connection_id) => {
      fetchApi(
        {
          url: acceptOrCancelReqApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            connection_id: connection_id,
            type: type,
            date: getCurrentDate(),
          },
        },
        (data) => {
          if (data.message === "already cancelled") {
            toast.open("false", "Request was already cancelled");
          }
          if (data.message === "connected") {
            toast.open("warning", "Request was already accepted");
          }
          if (tableType === "incoming") {
            if (data.message === "cancelled") {
              toast.open("true", "Connection request cancelled successfully!");
              sendNotification(
                connection_id,
                `Connection request declined by ${data.name}`,
                "connections"
              );
            }
            if (data.message === "accepted") {
              sendNotification(
                connection_id,
                `Connection request accepted by ${data.name}`,
                "connections"
              );
              toast.open("true", "Connection request accepted successfully!");
            }
            getIncomingRequests();
          } else {
            if (data.message === "cancelled") {
              toast.open("true", "Connection request cancelled successfully!");
              sendNotification(
                connection_id,
                `Connection request was cancelled by ${data.name}`,
                "connections"
              );
            }
            getOutGoingRequests();
          }
        }
      );
    },
    [
      acceptOrCancelReqApi,
      fetchApi,
      token,
      getIncomingRequests,
      getOutGoingRequests,
      sendNotification,
      toast,
      tableType,
    ]
  );

  useEffect(() => {
    if (userRole !== "R$7s2") {
      navigate("/");
    }
    if (ref.current) {
      displayFriends();
    }
    return () => {
      ref.current = false;
    };
  }, [displayFriends, navigate, userRole]);

  const handleConfirmationModal = (type, id, tableType, role) => {
    setConfirmationModal(true);
    if (type === "cancel") {
      setConfirmModalData({
        callbackFn: () => acceptOrCancelRequest(type, id, tableType, role),
        message: "Are you sure you want to cancel this connection request.",
      });
    } else if (type === "accept") {
      setConfirmModalData({
        callbackFn: () => acceptOrCancelRequest(type, id, tableType),
        message: "Are you sure you want to accept this connection request.",
      });
    } else if (type === "block") {
      setConfirmModalData({
        callbackFn: () => block(id),
        message: "Are you sure you want to block this user?",
      });
    } else {
      setConfirmModalData({
        callbackFn: () => unBlock(id),
        message: "Are you sure you want to un-block this user?",
      });
    }
  };

  const viewProfile = (name, role, user_id) => {
    if (role === "f@!3A") {
      navigate(`/view-startup/${name.split(" ").join("-")}/${user_id}`);
    } else if (role === "R$7s2") {
      navigate(`/view-mentor/${name.split(" ").join("-")}/${user_id}`);
    } else if (role === "Sw%aa") {
      navigate(`/view-investor/${name.split(" ").join("-")}/${user_id}`);
    } else {
      navigate(`/view-vendor/${name.split(" ").join("-")}/${user_id}`);
    }
  };

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="p-4">
          <div className="text-end">
            <Button
              onClick={displayFriends}
              className="px-2 py-1 m-1 text-xs md:text-sm "
            >
              Connected users
            </Button>
            <Button
              onClick={getIncomingRequests}
              className="px-2 py-1 m-1 text-xs md:text-sm "
              bgColor="bg-green-600"
            >
              Incoming requests
            </Button>
            <Button
              onClick={getOutGoingRequests}
              className="px-2 py-1 m-1 text-xs md:text-sm "
              bgColor="bg-orange-600"
            >
              Outgoing requests
            </Button>
            <Button
              onClick={blockList}
              className="px-2 py-1 m-1 text-xs md:text-sm "
              bgColor="bg-secondary"
            >
              Blocked users
            </Button>
          </div>
          {isLoading && <Loader />}
          {error && <Error />}
          {!isLoading &&
            !error &&
            (tableType === "connected" ? (
              <ConnectedUsers
                connections={connections}
                handleConfirmationModal={handleConfirmationModal}
                viewProfile={viewProfile}
              />
            ) : tableType === "incoming" ? (
              <IncomingRequests
                incomingRequest={incomingRequest}
                handleConfirmationModal={handleConfirmationModal}
                viewProfile={viewProfile}
              />
            ) : tableType === "outgoing" ? (
              <OutgoingRequests
                outGoingRequests={outGoingRequests}
                handleConfirmationModal={handleConfirmationModal}
                viewProfile={viewProfile}
              />
            ) : (
              <BlockedUsers
                blockedConnections={blockedConnections}
                handleConfirmationModal={handleConfirmationModal}
                viewProfile={viewProfile}
              />
            ))}
        </div>
      </div>
      <ConfirmationModal
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        callBackFn={confirmModalData.callbackFn}
        message={confirmModalData.message}
      />
    </div>
  );
};
