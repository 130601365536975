import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteChatRoom, setChatRoom } from "../../store/slices/ChatSlice";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Error } from "../../components/ui/Error";
import { Loader } from "../../components/ui/Loader";
import { BidCard } from "../../components/ui/cards/bidCard";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { getCurrentDate } from "../../utils/getCurrentDate";

const Bids = () => {
  const viewAdApi = `${process.env.REACT_APP_API_URL}display-ad`;
  const viewBidsApi = `${process.env.REACT_APP_API_URL}view-bids`;
  const connectApi = `${process.env.REACT_APP_API_URL}connect`;
  const getRoomIdApi = `${process.env.REACT_APP_API_URL}get-roomid`;

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userRole, token } = useLocalStorage();
  const { isLoading, error, fetchApi } = useFetch();

  const [bids, setBids] = useState([]);
  const [singleAd, setSingleAd] = useState([]);
  const [milestoneAmount, setMilestoneAmount] = useState(null);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [awardedBidId, setAwardedBidId] = useState(null);

  const getRoomId = useCallback(
    (userId) => {
      fetchApi(
        {
          url: getRoomIdApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            connection_id: userId,
            role: "V&iR8",
          },
        },
        (data) => {
          dispatch(deleteChatRoom());
          dispatch(
            setChatRoom({
              roomId: data.room_id,
              recieverId: userId,
              recieverRole: "V&iR8",
              status: "1",
            })
          );
        }
      );
    },
    [getRoomIdApi, fetchApi, token, dispatch]
  );

  const connect = useCallback(
    (userId) => {
      fetchApi(
        {
          url: connectApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            connection_id: userId,
            date: getCurrentDate(),
          },
        },
        (data) => {
          if (data.message === "connection already established") {
            getRoomId(userId);
            navigate("/chat");
          } else {
            dispatch(deleteChatRoom());
            dispatch(
              setChatRoom({
                roomId: data.room_id,
                recieverId: userId,
                recieverRole: "V&iR8",
                status: "1",
              })
            );
            navigate("/chat");
          }
        }
      );
    },
    [fetchApi, token, dispatch, navigate, connectApi, getRoomId]
  );

  useEffect(() => {
    const viewAd = () => {
      fetchApi(
        {
          url: viewAdApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            id: id,
          },
        },
        (data) => {
          setSingleAd(data.ad);
          setMilestoneAmount(data.amount);
        }
      );
    };

    const viewBids = () => {
      fetchApi(
        {
          url: viewBidsApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            ad_id: id,
          },
        },
        (data) => {
          setBids(data.bid_details);
        }
      );
    };

    const timer = setTimeout(() => {
      viewAd();
      viewBids();
    });
    return () => {
      clearTimeout(timer);
    };
  }, [token, viewAdApi, viewBidsApi, fetchApi, id]);

  useEffect(() => {
    const awardedBid = bids.find(
      (bid) => bid.status === "1" || bid.status === "2"
    );
    if (awardedBid) {
      setAwardedBidId(awardedBid.bid_id);
    }
  }, [bids]);

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        {isLoading && <Loader />}
        {error && (
          <div className="p-4">
            <Error />
          </div>
        )}
        {!isLoading && !error && (
          <div className="p-3">
            <div className="flex items-center justify-between mb-3">
              <h4 className="text-xl font-semibold">Project Details</h4>
              {awardedBidId && (
                <Link
                  to={`/milestone/${id}/${awardedBidId}`}
                  className="px-3 py-1 bg-primary text-white rounded-md"
                >
                  Milestones
                </Link>
              )}
            </div>
            <div className="mt-1 px-2 md:px-3 py-3 shadow rounded-lg bg-white">
              <div className="flex flex-col md:flex-row justify-between mb-2">
                <span className="my-2 font-medium text-lg md:my-0">
                  {singleAd.title}
                </span>
                <div className="flex flex-wrap items-center justify-between md:block">
                  <p className="text-xs">
                    <span className="font-semibold pe-1">Budget :</span>
                    &#8377;
                    {singleAd.min_budget} - &#8377;
                    {singleAd.max_budget}
                  </p>
                  <p className="text-xs">
                    <span className="font-semibold pe-1">Duration :</span>
                    {singleAd.deadline}
                    days
                  </p>
                </div>
              </div>
              <p
                className={`${
                  singleAd.description?.length > 150 ? "description-text" : ""
                } text-xs md:text-sm`}
              >
                {singleAd.description}
              </p>
              {singleAd.description?.length > 150 && (
                <input type="checkbox" className="expand-btn" />
              )}
              <p className="mt-3">
                {singleAd.tags?.map((tag, i) => (
                  <span key={i} className="outline-tags text-xs">
                    {tag.replace(new RegExp("_", "g"), " ")}
                  </span>
                ))}
              </p>
            </div>
            <div
              className={`my-2 flex items-center ${
                singleAd.status !== "0" && "justify-between"
              }`}
            >
              <p
                className={`font-medium ${
                  singleAd.status === "0"
                    ? "text-blue-500"
                    : singleAd.status === "1"
                    ? "text-orange-500"
                    : "text-green-500"
                }`}
              >
                <span className="font-medium text-black pe-1">Status :</span>
                {singleAd.status === "0"
                  ? "Active"
                  : singleAd.status === "1"
                  ? "Awarded"
                  : "Completed"}
              </p>
              <p className={`${singleAd.status === "0" && "ms-5"}`}>
                <span className="font-medium">Average bid amount :</span>{" "}
                &#8377;
                {singleAd.average_bid}
              </p>
              {singleAd.status !== "0" && (
                <p>
                  <span className="font-medium text-green-500">
                    Milestone{" "}
                    {`${singleAd.status === "1" ? "in progress" : "completed"}`}{" "}
                    :
                  </span>{" "}
                  ₹{milestoneAmount}
                </p>
              )}
            </div>
            {userRole === "f@!3A" && (
              <p className="text-xs md:text-sm">
                <span className="font-medium pe-1">Note :</span> The awarded
                amount will be frozen and released to the service provider only
                upon completion of the project.
              </p>
            )}
            {bids
              ?.filter((bid) => bid.status === "1" || bid.status === "2")
              .map((bid) => (
                <div key={bid.bid_id}>
                  <h4 className="mt-4 text-lg font-medium">
                    {bid.status === "1" ? "Awarded" : "Completed"} Bid
                  </h4>
                  <BidCard
                    data={bid}
                    vendorName={bid.name}
                    role={userRole}
                    connect={connect}
                    ad_status={singleAd.status}
                    ad_id={id}
                    title={singleAd.title}
                  />
                </div>
              ))}
            <h4 className="mt-4 text-lg font-medium">All Bids</h4>
            {bids.length > 0 &&
            bids.filter((bid) => bid.status === "0").length > 0 ? (
              bids
                .filter((bid) => bid.status === "0")
                .map((bid) => (
                  <BidCard
                    key={bid.bid_id}
                    data={bid}
                    vendorName={bid.name}
                    role={userRole}
                    connect={connect}
                    ad_status={singleAd.status}
                    ad_id={id}
                    title={singleAd.title}
                  />
                ))
            ) : (
              <p>No bid found</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Bids;
