import { useState } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import useFetch from "../../../../hooks/useFetch";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import { useToastContext } from "../../../../context/ToastContext";
import { FormError } from "../../FormError";
import { Loader } from "../../Loader";
import { Button } from "../../Button";
import { EyeIcon, SlashedEyeIcon } from "../../../../icons/icons";
import "../../../../custom.css";

export const ResetPassword = ({ open, onClose }) => {
  const updatePasswordApi = `${process.env.REACT_APP_API_URL}update-password`;
  const updateAdminPasswordApi = `${process.env.REACT_APP_API_URL}update-password-admin`;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const toast = useToastContext();
  const { token, userRole } = useLocalStorage();
  const { fetchApi, isLoading } = useFetch();

  const [showCurrPass, setShowCurrPass] = useState(false);
  const [show, setShow] = useState(false);

  if (!open) return null;

  const submitModal = (data) => {
    document.getElementById("missMatch").classList.add("hidden");
    updatePassword(data);
  };

  const updatePassword = (data) => {
    fetchApi(
      {
        url: userRole === "admin" ? updateAdminPasswordApi : updatePasswordApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          current_password: data.currentPassword,
          new_password: data.newPassword,
        },
      },
      (data) => {
        if (data.message === "invalid password") {
          document.getElementById("missMatch").classList.remove("hidden");
        } else {
          toast.open("true", "Password updated successfully!");
          onClose();
        }
      }
    );
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`w-[90%] md:w-[40%] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading font-semibold text-center border-b p-3 text-base">
          Reset Password
        </h4>
        <form onSubmit={handleSubmit(submitModal)} className="p-3 text-left">
          <div className="mb-1">
            <label className="mb-0 text-sm required" htmlFor="currentPassword">
              Current Password
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type={showCurrPass ? "text" : "password"}
                className="border-0 w-full ps-3"
                id="currentPassword"
                name="currentPassword"
                {...register("currentPassword", {
                  required: "Current Password is required",
                })}
                autoFocus
              />
              <span
                className="input-icons cursor-pointer icon-span"
                onClick={() => setShowCurrPass(!showCurrPass)}
              >
                {showCurrPass ? <EyeIcon /> : <SlashedEyeIcon />}
              </span>
            </div>
            {errors.currentPassword && (
              <div>
                <FormError message={errors.currentPassword.message} />
              </div>
            )}
          </div>
          <div className="mb-1">
            <label className="mb-0 text-sm required" htmlFor="newPassword">
              New Password
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type={show ? "text" : "password"}
                className="border-0 w-full ps-3"
                id="newPassword"
                name="newPassword"
                {...register("newPassword", {
                  required: "New Password is required",
                  minLength: {
                    value: 7,
                    message: "Password must be at least 7 characters",
                  },
                })}
              />
              <span
                className="input-icons cursor-pointer icon-span"
                onClick={() => setShow(!show)}
              >
                {show ? <EyeIcon /> : <SlashedEyeIcon />}
              </span>
            </div>
            {errors.newPassword && (
              <div>
                <FormError message={errors.newPassword.message} />
              </div>
            )}
          </div>
          <span id="missMatch" className="hidden text-red-500 text-xs">
            Current password does not match
          </span>
          {isLoading && <Loader pt="10px" />}
          <div className="float-right py-3">
            <Button
              onClick={onClose}
              className="px-3 py-1 me-2 text-sm"
              bgColor="bg-secondary"
            >
              Close
            </Button>
            <Button type="submit" className="px-3 py-1 text-sm">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
