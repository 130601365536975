import React, { useCallback, useEffect, useState } from "react";
import Sidebar from "../components/layout/Sidebar";
import Navbar from "../components/layout/Navbar";
import Accordion from "../components/ui/Accordian";
import useFetch from "../hooks/useFetch";
import useLocalStorage from "../hooks/useLocalStorage";
import { Error } from "../components/ui/Error";
import { Loader } from "../components/ui/Loader";

const Faq = () => {
  const displayFaqApi = `${process.env.REACT_APP_API_URL}display-faq`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token } = useLocalStorage();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [Faq, setFaq] = useState([]);

  const getCreditHistory = useCallback(() => {
    fetchApi({ url: displayFaqApi, headers: { "x-token": token } }, (data) => {
      setFaq(data.details);
    });
  }, [fetchApi, displayFaqApi, token]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getCreditHistory();
    }, 20);
    return () => {
      clearTimeout(timer);
    };
  }, [getCreditHistory]);

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="m-4">
          {error && <Error />}
          {isLoading && <Loader />}
          {!error && !isLoading && (
            <>
              <h4 className="text-lg font-semibold mb-5">
                Frequently asked questions
              </h4>
              {Faq.length > 0
                ? Faq.map((data) => (
                    <div
                      key={data.question}
                      className="p-4 mb-2 shadow-md rounded-md bg-white"
                    >
                      <Accordion title={data.question} answer={data.answer} />
                    </div>
                  ))
                : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Faq;
