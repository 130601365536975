import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useFetch from "../../../hooks/useFetch";
import { updateFreezePoints } from "../../../store/slices/FetchCreditsSlice";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { WalletTable } from "../walletTable";
import { useToastContext } from "../../../context/ToastContext";
import { WithdrawReqTable } from "../withdrawReqTable";
import { Loader } from "../../../components/ui/Loader";
import { Error } from "../../../components/ui/Error";
import { AmountWithdraw } from "../../../components/ui/modals/utility-modals/amountWithdraw";
import Navbar from "../../../components/layout/Navbar";
import Sidebar from "../../../components/layout/Sidebar";
import "../transactionPages.css";
import { Button } from "../../../components/ui/Button";
import { getCurrentDate } from "../../../utils/getCurrentDate";

const MentorWallet = () => {
  const getMentorWalletApi = `${process.env.REACT_APP_API_URL}display-mentor-wallet`;
  const withdrawRequestApi = `${process.env.REACT_APP_API_URL}withdraw-request`;
  const getWithdrawRequestsApi = `${process.env.REACT_APP_API_URL}display-withdraw-requests`;
  const cancelWithdrawReqApi = `${process.env.REACT_APP_API_URL}cancel-withdraw-request`;

  const { token, userRole } = useLocalStorage();
  const { isLoading, error, fetchApi } = useFetch();
  const toast = useToastContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [mentorWallet, setMentorWallet] = useState([]);
  const [withdrawReq, setWithdrawReq] = useState([]);
  const [processedAmount, setProcessedAmount] = useState({
    balance: 0,
    freezed: 0,
    total: 0,
    withdraw_request: 0,
  });
  const [tableType, setTableType] = useState("wallet");
  const [open, setOpen] = useState(false);
  const effectRef = useRef(true);
  const [openSidebar, setOpenSidebar] = useState(false);

  const getMentorWallet = useCallback(() => {
    setTableType("wallet");
    fetchApi(
      { url: getMentorWalletApi, headers: { "x-token": token } },
      (data) => {
        setMentorWallet(data.freeze_user);
        setProcessedAmount({
          balance: data.accessible_points,
          freezed: data.freeze_points,
          total: data.total_earning,
          withdraw_request: data.withdraw_request,
        });
        dispatch(updateFreezePoints(data.accessible_points));
      }
    );
  }, [fetchApi, getMentorWalletApi, token, dispatch]);

  const withdrawRequest = useCallback(
    (amount) => {
      fetchApi(
        {
          url: withdrawRequestApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: { withdraw_amount: amount, date: getCurrentDate() },
        },
        (data) => {
          if (data.message === "bank details not found") {
            toast.open("warning", "Please enter bank details before withdrawl");
            navigate("/mentor-profile");
            return;
          }
          toast.open("true", "Withdraw request has been sent successfully!");
          getMentorWallet();
        }
      );
    },
    [fetchApi, token, toast, navigate, getMentorWallet, withdrawRequestApi]
  );

  const getWithdrawRequests = useCallback(() => {
    setTableType("withdraw");
    fetchApi(
      {
        url: getWithdrawRequestsApi,
        headers: {
          "x-token": token,
        },
      },
      (data) => setWithdrawReq(data.withdraw_requests)
    );
  }, [getWithdrawRequestsApi, token, fetchApi]);

  const cancelWithdrawReq = useCallback(
    (id) => {
      fetchApi(
        {
          url: cancelWithdrawReqApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: { id: id },
        },
        (data) => {
          if (data.message === "approved") {
            toast.open(
              "warning",
              "This withdraw request has already been approved by admin. Cannot cancel now."
            );
          } else {
            toast.open("true", "Request cancelled successfully!");
          }
          getMentorWallet();
        }
      );
    },
    [fetchApi, token, toast, getMentorWallet, cancelWithdrawReqApi]
  );

  useEffect(() => {
    if (userRole !== "R$7s2") {
      navigate("/");
      return;
    }
    if (effectRef.current) {
      getMentorWallet();
    }
    return () => {
      effectRef.current = false;
    };
  }, [getMentorWallet, navigate, userRole]);

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="p-4">
          <div className="text-end">
            <Button
              onClick={getMentorWallet}
              className="px-2 py-1 mx-1 text-xs md:text-sm"
            >
              Wallet
            </Button>
            <Button
              onClick={() => setOpen(!open)}
              className="px-2 py-1 mx-1 text-xs md:text-sm"
              bgColor="bg-green-600"
            >
              Withdraw
            </Button>
            <Button
              onClick={getWithdrawRequests}
              className="px-2 py-1 mx-1 text-xs md:text-sm"
              bgColor="bg-[#FC551E]"
            >
              Withdraw Requests
            </Button>
            <AmountWithdraw
              open={open}
              onClose={() => setOpen(false)}
              processedAmount={processedAmount}
              withdrawRequest={withdrawRequest}
            />
          </div>
          {error && <Error />}
          {isLoading && !error ? (
            <Loader />
          ) : tableType === "wallet" ? (
            <WalletTable
              wallet={mentorWallet}
              processedAmount={processedAmount}
            />
          ) : tableType === "withdraw" ? (
            <WithdrawReqTable
              processedAmount={processedAmount}
              withdrawReq={withdrawReq}
              cancelWithdrawReq={cancelWithdrawReq}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MentorWallet;
