import { useEffect } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Button";

export const DeactivateModal = ({ open, onClose, callBackFn }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: {
      reason: "",
    },
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  if (!open) return null;

  const submitModal = (data) => {
    callBackFn(data.reason);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`min-w-[90%] md:max-w-[500px] md:min-w-[500px] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading font-semibold text-center border-b p-3 text-base">
          De-Activate Account
        </h4>
        <form onSubmit={handleSubmit(submitModal)} className="p-3 pb-0 mt-2">
          <div>
            <textarea
              name="reason"
              id="reason"
              cols="30"
              rows="10"
              placeholder="Reason for de-activation..."
              className="border p-1.5 rounded-md"
              {...register("reason", {
                required: "Reason is required",
                minLength: {
                  value: 30,
                  message: "Reason must be atleast 30 characters",
                },
              })}
            ></textarea>
            {errors.reason && (
              <span className="my-1 text-secondary text-xs">
                {errors.reason.message}
              </span>
            )}
          </div>
          <Button type="submit" className="block ml-auto px-2 py-1 mx-1">
            Submit
          </Button>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
