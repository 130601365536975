import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { randomId } from "../utils/randomId";
import Toast from "../components/ui/toast/Toast";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);
  const toastsRef = useRef(toasts);

  useEffect(() => {
    toastsRef.current = toasts;
  }, [toasts]);

  const closeToast = useCallback((id) => {
    setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
  }, []);

  const openToast = useCallback(
    (success, msg) => {
      const newId = randomId();
      if (toastsRef.current.length === 5) {
        setToasts((toasts) => {
          const newToasts = [...toasts];
          newToasts.pop();
          return [{ id: newId, success, msg }, ...newToasts];
        });
      } else {
        setToasts((toasts) => [{ id: newId, success, msg }, ...toasts]);
      }
      setTimeout(() => closeToast(newId), 10000);
    },
    [closeToast]
  );

  const contextValue = useMemo(
    () => ({
      open: openToast,
      close: closeToast,
    }),
    [openToast, closeToast]
  );

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      <div className="space-y-2 z-10 fixed top-4 right-4">
        {toasts?.map(({ id, success, msg }) => (
          <Toast
            key={id}
            success={success}
            msg={msg}
            close={() => closeToast(id)}
          />
        ))}
      </div>
    </ToastContext.Provider>
  );
};

export const useToastContext = () => {
  return useContext(ToastContext);
};
