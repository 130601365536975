import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useFetch from "../../../hooks/useFetch";
import { Error } from "../../../components/ui/Error";
import { Loader } from "../../../components/ui/Loader";
import AdminNavbar from "../../../components/layout/adminNavbar";
import AdminSidebar from "../../../components/layout/adminSidebar";
import TicketTable from "./TicketTable";
import { Dropdown } from "../../../components/ui/inputs/dropdown";

const Tickets = () => {
  const getTicketsApi = `${process.env.REACT_APP_API_URL}display-tickets`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const navigate = useNavigate();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [category, setCategory] = useState("All");
  const [page, setPage] = useState(1);
  const [tickets, setTickets] = useState([]);

  const displayTickets = useCallback(() => {
    fetchApi(
      {
        url: getTicketsApi,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
      },
      (data) => {
        setTickets(data.details);
      }
    );
  }, [getTicketsApi, token, fetchApi]);

  useEffect(() => {
    if (userRole !== "admin") {
      navigate("/error-401");
    }
    const timer = setTimeout(() => {
      displayTickets();
    }, 20);
    return () => {
      clearTimeout(timer);
    };
  }, [navigate, userRole, displayTickets]);

  const changeTableType = (value) => {
    setCategory(value);
    setPage(1);
  };

  return (
    <div className="grid grid-cols-5">
      <AdminSidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <AdminNavbar handleSidebar={() => setOpenSidebar(true)} />
        {isLoading && <Loader />}
        {error && <Error />}
        {!error && !isLoading && (
          <div className="p-4">
            <div className="flex flex-col items-start gap-3 md:flex-row md:items-center md:justify-between md:mb-3 md:gap-0">
              <h3 className="text-lg font-semibold">Tickets</h3>
              <div className="flex items-center order-1 md:order-2">
                <label
                  htmlFor="ticket-filter"
                  className="text-lg font-medium mr-2 mb-0"
                >
                  Filter :{" "}
                </label>
                <Dropdown
                  options={[
                    { label: "All", value: "All" },
                    { label: "Website Bug", value: "website bug" },
                    { label: "Fund Transfer", value: "fund transfer" },
                    { label: "Startup", value: "startup" },
                    { label: "Mentor", value: "mentor" },
                    { label: "Investor", value: "investor" },
                    { label: "Service Provider", value: "service provider" },
                    { label: "Other", value: "other" },
                  ]}
                  onChange={(selectedOption) =>
                    changeTableType(selectedOption.value)
                  }
                  styles={{
                    menuList: (baseStyles) => ({
                      ...baseStyles,
                      maxHeight: "200px",
                      overflowY: "auto",
                    }),
                    control: (baseStyles) => ({
                      ...baseStyles,
                      width: "200px",
                    }),
                  }}
                  defaultValue={{ label: "All", value: "All" }}
                  id="ticket-filter"
                />
              </div>
            </div>
            <TicketTable
              page={page}
              setPage={setPage}
              data={tickets}
              type={category}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Tickets;
