import { DeleteIcon, PenIcon } from "../../../icons/icons";

const DocumentCard = ({
  data,
  setOpenConfirmModal,
  setOpenEditModal,
  type = "user",
}) => {
  return (
    <div className="relative group bg-white shadow-md rounded-lg p-3">
      <a
        href={data.file_path}
        target="_blank"
        rel="noreferrer"
        className="relative group mx-auto space-y-2"
      >
        <img
          src={data.thumbnail_path}
          alt="Thumbnail"
          className="max-h-[150px] mx-auto rounded-lg w-full"
          loading="lazy"
        />

        <h6 className="font-semibold text-lg">{data.title}</h6>
        <p className="text-slate-500 text-sm">{data.description}</p>
        <div className="flex items-center justify-start gap-2">
          {data.tags.split(",").map((tag) => (
            <p className="bg-gray-200 px-2 rounded-md font-medium" key={tag}>
              {tag}
            </p>
          ))}
        </div>
        {type === "admin" && (
          <div className="hidden group-hover:flex absolute right-0 top-0 items-center gap-x-2 cursor-pointer">
            <PenIcon
              fill="#12d512"
              onClick={() => setOpenEditModal({ open: true, data: data })}
            />
            <DeleteIcon
              onClick={() =>
                setOpenConfirmModal({
                  open: true,
                  id: data._id,
                })
              }
            />
          </div>
        )}
      </a>
    </div>
  );
};

export default DocumentCard;
