import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import useNotificationApi from "../../hooks/useNotificationApi";
import useCheckRole from "../../hooks/useCheckRole";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { Loader } from "../../components/ui/Loader";
import { Button } from "../../components/ui/Button";
import { SuccessModal } from "../../components/ui/modals/utility-modals/successModal";
import { getCurrentDate } from "../../utils/getCurrentDate";

export const ViewAgreement = () => {
  const acceptAgreementApi = `${process.env.REACT_APP_API_URL}accept-agreement`;
  const agreementApi = `${process.env.REACT_APP_API_URL}agreement`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const { sendNotification } = useNotificationApi();
  const { role, CheckRole } = useCheckRole();
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef(true);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [agreementData, setAgreementData] = useState({
    date: "",
    investor_name: "",
    startup_name: "",
    startup_company: "",
    startup_sign_date: "",
    investor_sign_date: "",
  });

  const agreement = useCallback(() => {
    fetchApi(
      {
        url: agreementApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          startup_id: location.state?.agreement.startup_id,
          investor_id: location.state?.agreement.investor_id,
        },
      },
      (data) => {
        setAgreementData({
          date: data.date,
          investor_name: data.investor_name,
          startup_name: data.startup_name,
          startup_company: data.startup_company,
          startup_sign_date: data.startup_agreed_date,
          investor_sign_date: data.investor_agreed_date,
        });
      }
    );
  }, [token, fetchApi, agreementApi, location.state]);

  useEffect(() => {
    if (ref.current) {
      agreement();
      CheckRole(userRole);
    }
    return () => {
      ref.current = false;
    };
  }, [agreement, CheckRole, userRole]);

  const acceptAgreement = (e) => {
    e.preventDefault();
    fetchApi(
      {
        url: acceptAgreementApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          agreement_id: location.state.agreement.id,
          date: getCurrentDate(),
        },
      },
      () => {
        if (role === 1) {
          sendNotification(
            location.state.agreement.investor_id,
            `${agreementData.startup_name} has accepted agreement`,
            "investments"
          );
          navigate("/startup-investments");
        } else {
          sendNotification(
            location.state.agreement.startup_id,
            `${agreementData.investor_name} has accepted agreement`,
            "investments"
          );
          setOpenSuccessModal(true);
        }
      }
    );
  };

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="p-4">
          <h4 className="pb-7 text-lg font-semibold text-center">
            Investment Declaration
          </h4>
          <p>
            This Declaration of Investment Agreement is made between{" "}
            <span className="font-medium capitalize">
              {agreementData.investor_name}
            </span>{" "}
            and{" "}
            <span className="font-medium">{agreementData.startup_company}</span>
            , represented by{" "}
            <span className="font-medium capitalize">
              {agreementData.startup_name}
            </span>
            , to confirm the intention of an investment amount of{" "}
            <span className="font-medium">
              &#8377;{location.state.agreement.amount}
            </span>
            . The investment will be used for key business purposes, such as
            product development, marketing, and scaling operations. This
            declaration serves as a formal acknowledgment of mutual interest in
            proceeding with the investment. It remains non-binding until a final
            investment agreement is signed. Both the Investor and Founder commit
            to confidentiality and will not disclose any details without written
            consent. Once the final agreement is reached, both parties will
            confirm the investment and proceed with the necessary steps to
            ensure business growth. The Investor and Founder look forward to
            working together for mutual success.
          </p>
          <div className="my-2">
            <p>
              <span className="font-medium">Payment: </span>The Investor shall
              transfer the investment amount to the designated bank account of
              the platform (PitchHere). The startup's account on the platform
              will be updated with the same amount as the available balance for
              withdrawal. The startup needs to make a withdrawal request to the
              platform, and the amount will be transferred to the startup’s
              designated bank account within 3-4 working days after the
              deduction of platform fees, as follows:
            </p>
            <table className="w-3/6 mx-auto mt-5 auto border-collapse border border-slate-300 text-center text-sm">
              <thead>
                <tr>
                  <th className="border border-slate-300 p-1.5">
                    Investment Amount (INR)
                  </th>
                  <th className="border border-slate-300 p-1.5">
                    Platform Fee
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-slate-300 p-1.5">
                    Amount &lt;= 10,00,000
                  </td>
                  <td className="border border-slate-300 p-1.5">5%</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 p-1.5">
                    10,00,000 &lt; Amount &lt;= 25,00,000{" "}
                  </td>
                  <td className="border border-slate-300 p-1.5">4%</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 p-1.5">
                    25,00,000 &lt; Amount &lt;= 50,00,000{" "}
                  </td>
                  <td className="border border-slate-300 p-1.5">3%</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 p-1.5">
                    50,00,000 &lt; Amount &lt;= 1,00,00,000{" "}
                  </td>
                  <td className="border border-slate-300 p-1.5">2.5%</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 p-1.5">
                    Amount &gt; 1,00,00,000{" "}
                  </td>
                  <td className="border border-slate-300 p-1.5">2%</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mb-2 mt-5 space-y-2">
            <p className="font-semibold">Investment Disclaimer: </p>
            <p>
              PitchHere is a facilitator for connecting startups with potential
              investors on its funding platform. We do not assume responsibility
              for any investment agreements entered into between startups and
              investors using our platform. The terms, conditions, and specifics
              of investment deals, including but not limited to term sheets, are
              to be discussed, negotiated, and agreed upon solely between the
              startup and the investor.
            </p>
            <p>
              Once both parties mutually agree on the terms outlined in the term
              sheet, it is the responsibility of the investor to release the
              funds as per the agreed-upon terms. PitchHere does not act as a
              party to any investment agreement and shall not be held liable for
              the outcomes or consequences arising from such agreements.
            </p>
            <p>
              Additionally, PitchHere disclaims any responsibility for the
              subsequent growth or performance of the startup and any associated
              investment profits or losses. The decision to invest rests solely
              with the investor, and the startup's success or failure is
              independent of PitchHere's involvement.
            </p>
            <p>
              Users of the PitchHere platform are advised to conduct thorough
              due diligence, seek legal advice, and exercise prudence when
              entering into investment agreements. PitchHere encourages
              transparency, communication, and fair dealings between startups
              and investors but does not warrant or guarantee any specific
              investment outcomes.
            </p>
            <p className="font-semibold">
              PitchHere emphasizes that this investment agreement is NOT a legal
              document and serves as a mutual agreement to initiate discussions.
              The final legal document, including but not limited to investment
              agreements and term sheets, should be discussed, negotiated, and
              agreed upon exclusively between the startup and the investor.
            </p>
            <p>
              By utilizing the PitchHere platform, users acknowledge and agree
              to the terms outlined in this investment disclaimer. It is
              recommended to carefully review and understand these terms before
              engaging in any investment activities on the platform.
            </p>
          </div>
          <p>
            <span className="font-medium">Declaration Date: </span>
            {agreementData.date}
          </p>
          <p>
            <span className="font-medium">Startup signed on: </span>
            {agreementData.startup_sign_date ? (
              agreementData.startup_sign_date
            ) : (
              <span className="text-red-500 font-medium">Not Signed</span>
            )}
          </p>
          <p>
            <span className="font-medium">Investor signed on: </span>
            {agreementData.investor_sign_date ? (
              agreementData.investor_sign_date
            ) : (
              <span className="text-red-500 font-medium">Not Signed</span>
            )}
          </p>
          <form className="pt-3" onSubmit={acceptAgreement}>
            <div className="my-2 flex items-start">
              <input
                type="checkbox"
                id="agreement-terms"
                className="mt-1"
                required
              />
              <label
                htmlFor="agreement-terms"
                className="mb-0 ps-1 font-medium"
              >
                I have carefully read and understood the declaration of
                investment agreement, including the investment disclaimer by
                PitchHere. I hereby acknowledge my agreement to these terms and
                conditions, and I am fully aware that this mutual agreement
                serves as a preliminary understanding. I recognize that the
                final legal document should be discussed, negotiated, and agreed
                upon solely between the startup and the investor. By signing
                this document, I commit to engaging in good faith discussions
                and negotiations in pursuit of a comprehensive and legally
                binding investment agreement.
              </label>
            </div>
            {isLoading && (
              <div className="my-1">
                <Loader />
              </div>
            )}
            {role === 1 && !agreementData.startup_sign_date && (
              <Button type="submit" className="px-4 py-1 my-2 text-sm">
                Agree
              </Button>
            )}
            {role === 1 && agreementData.startup_sign_date && (
              <Button
                className="disabled px-4 py-1 my-2 text-sm cursor-not-allowed"
                bgColor="bg-gray-400"
                disabled
              >
                Already Signed
              </Button>
            )}
            {role === 3 &&
              !agreementData.investor_sign_date &&
              agreementData.startup_sign_date && (
                <Button type="submit" className="px-4 py-1 my-2 text-sm">
                  Agree
                </Button>
              )}
            {role === 3 &&
              !agreementData.investor_sign_date &&
              !agreementData.startup_sign_date && (
                <>
                  <p className="text-red-500 text-sm">
                    <span className="font-medium text-black">Note :</span>{" "}
                    Startup needs to sign it first.
                  </p>
                  <Button
                    className="disabled px-4 py-1 my-2 text-sm cursor-not-allowed"
                    bgColor="bg-gray-400"
                    disabled
                  >
                    Agree
                  </Button>
                </>
              )}
            {role === 3 && agreementData.investor_sign_date && (
              <Button
                className="disabled px-4 py-1 my-2 text-sm cursor-not-allowed"
                bgColor="bg-gray-400"
                disabled
              >
                Already Signed
              </Button>
            )}
          </form>
          {error && (
            <p className="my-2 text-red-500 text-sm">
              Sorry we were unable to send the agreement. Please try again
              later.
            </p>
          )}
        </section>
      </div>
      <SuccessModal
        open={openSuccessModal}
        onClose={() => setOpenSuccessModal(false)}
        text="Agreement signed successfully. Follow the steps on investments page to transfer funds."
      />
    </div>
  );
};
