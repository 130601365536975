import axis from "./banks/axis.svg";
import hdfc from "./banks/hdfc.svg";
import icici from "./banks/icici.svg";
import citi from "./banks/citi.svg";
import yes from "./banks/yes.svg";
import kotak from "./banks/kotak.svg";
import idbi from "./banks/idbi.svg";
import indusind from "./banks/indusind.svg";
import rbl from "./banks/rbl.svg";
import sbi from "./banks/sbi.svg";
import union from "./banks/union.svg";
import uco from "./banks/uco.svg";
import pnb from "./banks/pnb.svg";
import indian from "./banks/indian.svg";
import canara from "./banks/canara.svg";
import idfc from "./banks/idfc.svg";
import central from "./banks/central.svg";
import baroda from "./banks/baroda.svg";
import dbs from "./banks/dbs.svg";

const bankImages = {
  axis,
  hdfc,
  icici,
  citi,
  yes,
  kotak,
  idbi,
  indusind,
  rbl,
  sbi,
  union,
  uco,
  pnb,
  indian,
  canara,
  idfc,
  central,
  baroda,
  dbs,
};

export default bankImages;
