import ReactDom from "react-dom";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useToastContext } from "../../../../context/ToastContext";
import { FormError } from "../../FormError";
import { Button } from "../../Button";
import "../../../../custom.css";
import { numberRegex } from "../../../../constants";
import { BuildingIcon, RupeeIcon } from "../../../../icons/icons";

export const InvestmentModal = ({
  open,
  onClose,
  startupBasic,
  connectionStatus,
}) => {
  const navigate = useNavigate();
  const toast = useToastContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      company: startupBasic?.company,
    },
  });

  if (!open) return null;

  const toEsign = (data) => {
    if (connectionStatus !== "connected") {
      toast.open(
        "warning",
        "Need to establish connection with startup before investing"
      );
      onClose();
      return;
    }
    navigate("/agreement", {
      state: {
        amount: data.amount,
        startupId: startupBasic.id,
      },
    });
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`w-[90%] md:w-[50%] lg:w-[40%] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading font-semibold text-center border-b p-3 text-base">
          Investment Details
        </h4>
        <form onSubmit={handleSubmit(toEsign)} className="p-3">
          <div className="mb-1">
            <label className="mb-0 text-sm required" htmlFor="amount">
              Investment Amount (&#8377;)
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="border-0 w-full ps-3"
                id="amount"
                name="amount"
                autoFocus
                {...register("amount", {
                  required: "amount is required",
                  pattern: {
                    value: numberRegex,
                    message: "Number must only contain digits",
                  },
                  validate: (fieldvalue) => {
                    return parseInt(fieldvalue) === 0
                      ? "Amount cannot be 0"
                      : null;
                  },
                })}
              />
              <span className="input-icons icon-span">
                <RupeeIcon />
              </span>
            </div>
            {errors.amount && (
              <div>
                <FormError message={errors.amount.message} />
              </div>
            )}
          </div>
          <div className="mb-1">
            <label className="mb-0 text-sm" htmlFor="company">
              Startup
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="border-0 w-full ps-3"
                id="company"
                name="company"
                {...register("company", {
                  required: "Company is required",
                })}
                disabled
              />
              <span className="input-icons icon-span">
                <BuildingIcon />
              </span>
            </div>
            {errors.company && (
              <div>
                <FormError message={errors.company.message} />
              </div>
            )}
          </div>
          <Button type="submit" className="px-2 py-1 m-2 float-right">
            Next
          </Button>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
