import { useEffect } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Button";
import { FormError } from "../../FormError";
import PHcoin from "../../../../images/PH-coin.svg";

export const EditPricingModal = ({ open, onClose, data, callBackFn }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: {
      price: data.currPrice,
    },
  });

  useEffect(() => {
    if (isSubmitSuccessful) reset();
  }, [reset, isSubmitSuccessful]);

  if (!open) return null;

  const submitModal = (data) => {
    callBackFn(data.price);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`min-w-[90%] md:min-w-[400px] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading font-semibold text-center border-b p-3 capitalize text-base">
          {data?.field?.replace(new RegExp("_", "g"), " ")}
        </h4>
        <form onSubmit={handleSubmit(submitModal)} className="p-3 text-left">
          <div className="flex flex-col mb-2">
            <label
              className="flex items-center gap-x-1 font-medium text-base required"
              htmlFor="price"
            >
              Price{" "}
              <span className="flex items-center gap-x-1 font-normal">
                (tokens <img src={PHcoin} alt="PH coin" width={20} />)
              </span>
            </label>
            <input
              type="number"
              id="price"
              name="price"
              className="w-full px-2 py-1 border border-gray-400 rounded-md focus:ring-1 focus:ring-indigo-600"
              {...register("price", {
                required: "Price is required",
              })}
            />
          </div>
          {errors.price && (
            <div>
              <FormError message={errors.price.message} />
            </div>
          )}
          <div className="modalFooter py-3">
            <Button type="submit" className="px-2 py-1 mx-1">
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
