const NavigateToDashboard = (role) => {
  switch (role) {
    case "f@!3A":
      return "/startup-dashboard";
    case "R$7s2":
      return "/mentor-dashboard";
    case "Sw%aa":
      return "/startup-dashboard";
    case "V&iR8":
      return "/vendor-profile";
    default:
      return "/error-500";
  }
};

export default NavigateToDashboard;
