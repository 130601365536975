export const formatInvestmentAmount = (amount) => {
  if (amount < 1000) {
    return `${amount}`;
  } else if (amount < 100000) {
    // less than 1 Lakh
    return `${(amount / 1000).toFixed(1)} K`;
  } else if (amount < 10000000) {
    // less than 1 Crore
    return `${(amount / 100000).toFixed(1)} Lakh`;
  } else {
    // 1 Crore and above
    return `${(amount / 10000000).toFixed(1)} Cr`;
  }
};
