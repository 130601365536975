import { useEffect, useState } from "react";
import ReactDom from "react-dom";
import useUploadImage from "../../../../hooks/useUploadImage";
import useAddDescription from "../../../../hooks/useAddDescription";
import { Button } from "../../Button";
import { FormError } from "../../FormError";

export const ClaimModal = ({ open, onClose, claimFields, callBackFn }) => {
  const updateStartupDescriptionApi = `${process.env.REACT_APP_API_URL}update-startup-description`;

  const { uploadImage } = useUploadImage();
  const { addDescription } = useAddDescription();

  const [data, setData] = useState({
    logo: null,
    description: "",
  });

  useEffect(() => {
    setData({ logo: claimFields.logo, description: claimFields.description });
  }, [claimFields]);

  if (!open) return null;

  const submitModal = (e) => {
    e.preventDefault();
    document.getElementById("claim-error").classList.add("hidden");
    document.getElementById("description-error").classList.add("hidden");
    if (!data.logo && !data.description) {
      document.getElementById("claim-error").classList.remove("hidden");
      return;
    }
    if (!data.logo) {
      document.getElementById("logo-error").classList.remove("hidden");
      return;
    }
    if (data.description && data.description.length < 30) {
      document.getElementById("description-error").classList.remove("hidden");
      return;
    }
    if (!claimFields.logo && !claimFields.description) {
      addDescription(
        updateStartupDescriptionApi,
        { description: data.description },
        () => uploadImage(data.logo, callBackFn)
      );
    } else if (!claimFields.logo && claimFields.description) {
      uploadImage(data.logo, callBackFn);
    } else if (claimFields.logo && !claimFields.description) {
      addDescription(
        updateStartupDescriptionApi,
        { description: data.description },
        callBackFn
      );
    }
    setData({
      logo: null,
      description: "",
    });
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`min-w-[90%] md:min-w-[400px] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading font-semibold text-center border-b p-3 text-base">
          Claim Status
        </h4>
        <form onSubmit={submitModal} className="p-3 text-left">
          {claimFields.logo ? (
            <p className="flex justify-between items-center mb-2">
              <span className="font-medium">Logo :</span>
              <span className="px-1.5 py-1 rounded-md bg-gray-200 text-[#31a831]">
                Added
              </span>
            </p>
          ) : (
            <div className="flex flex-col mb-2">
              <label
                className="cursor-pointer mb-0 text-sm font-medium"
                htmlFor="logo"
              >
                Logo
              </label>
              <input
                type="file"
                accept="image/*"
                id="logo"
                onChange={(e) =>
                  setData((prev) => ({ ...prev, logo: e.target.files[0] }))
                }
              />
            </div>
          )}
          {claimFields.description ? (
            <p className="flex justify-between items-center">
              <span className="font-medium">Description :</span>
              <span className="px-1.5 py-1 rounded-md bg-gray-200 text-[#31a831]">
                Added
              </span>
            </p>
          ) : (
            <div>
              <label
                className="cursor-pointer mb-0 text-sm font-medium"
                htmlFor="description"
              >
                Description
              </label>
              <textarea
                name="description"
                id="description"
                cols="30"
                rows="7"
                className="border rounded-md p-1"
                onChange={(e) =>
                  setData((prev) => ({ ...prev, description: e.target.value }))
                }
              ></textarea>
            </div>
          )}
          <div id="claim-error" className="hidden">
            <FormError message="Make sure to add logo and description" />
          </div>
          <div id="logo-error" className="hidden">
            <FormError message="Add logo" />
          </div>
          <div id="description-error" className="hidden">
            <FormError message="Description must be atleast 30 chrachters long" />
          </div>

          <div className="modalFooter py-3">
            <Button
              className="px-2 py-1 mx-1"
              bgColor="bg-secondary"
              onClick={onClose}
            >
              Close
            </Button>
            <Button type="submit" className="px-2 py-1 mx-1">
              Claim
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
