import { Pagination } from "../../../components/ui/Button";
import { DeleteIcon, PenIcon } from "../../../icons/icons";

const IndustryTable = ({
  page,
  setPage,
  industries,
  type,
  updateIndustry,
  handleIndustryModal,
  deleteIndustry,
}) => {
  let filterData = industries.filter((data) => data.role === type);

  return (
    <div className="overflow-auto w-full md:overflow-none">
      <table className="table-auto min-w-max w-full mt-4 border bg-white shadow-md border-slate-300">
        <thead className="bg-[#F9FAFC] text-[#5a5a5b] border border-gray-200 text-left">
          <tr>
            <th className="font-medium p-3 text-xs md:text-sm">SNo.</th>
            <th className="font-medium p-3 text-xs md:text-sm">Industry</th>
            <th className="font-medium p-3 text-xs md:text-sm">Status</th>
            <th className="font-medium p-3 text-xs md:text-sm">Action</th>
          </tr>
        </thead>
        <tbody>
          {filterData.length ? (
            filterData
              .slice(page * 15 - 15, page * 15)
              .map((industry, index) => (
                <tr
                  className="bg-white border-b border-slate-300 align-middle h-auto"
                  key={industry._id}
                >
                  <td className="p-3 text-sm font-medium">
                    {(page - 1) * 15 + index + 1}
                  </td>
                  <td className="p-3 text-sm uppercase">
                    {industry.industry_name.replace(new RegExp("_", "g"), " ")}
                  </td>
                  <td className="p-3 text-sm">
                    <label className="mb-0 cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={industry.status === "0" ? false : true}
                        onChange={() =>
                          updateIndustry(
                            industry._id,
                            industry.status === "0" ? "1" : "0",
                            "status"
                          )
                        }
                      />
                      <div className="relative w-9 h-5 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </td>
                  <td className="p-3">
                    <div className="flex items-center justify-evenly gap-2 h-full">
                      <span title="Edit Industry">
                        <PenIcon
                          className="cursor-pointer fill-blue-500 transition-colors ease-in duration-50 hover:fill-blue-800"
                          onClick={() =>
                            handleIndustryModal(
                              "edit",
                              industry.industry_name,
                              industry.role,
                              industry._id
                            )
                          }
                        />
                      </span>
                      <span title="Delete Industry">
                        <DeleteIcon
                          className="cursor-pointer fill-red-500 transition-colors ease-in duration-50 hover:fill-red-800"
                          onClick={() => deleteIndustry(industry._id)}
                        />
                      </span>
                    </div>
                  </td>
                </tr>
              ))
          ) : (
            <tr className="bg-white text-center">
              <td colSpan={4} className="p-3 text-xs md:text-sm">
                No Industries Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {filterData.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          totalDataCount={filterData.length}
          dataCount={15}
        />
      )}
    </div>
  );
};

export default IndustryTable;
