import React, { useState } from "react";
import { Pagination } from "../../../components/ui/Button";
import PHcoin from "../../../images/PH-coin.svg";

export function TransactionTable({ walletTransactions }) {
  const [page, setPage] = useState(1);

  return (
    <>
      <h4 className="font-medium text-xl mt-3">Payment Transactions</h4>
      <div className="overflow-auto w-full mt-3 md:overflow-none">
        <table className="table-auto min-w-max w-full mt-4 border shadow-sm border-slate-300">
          <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
            <tr>
              <th className="p-3 font-medium text-sm">Date</th>
              <th className="p-3 font-medium text-sm">Plan Name</th>
              <th className="p-3 font-medium text-sm">Amount</th>
              <th className="p-3 font-medium text-sm">Tokens</th>
              <th className="p-3 font-medium text-sm">TransactionId</th>
              <th className="p-3 font-medium text-sm">Status</th>
            </tr>
          </thead>
          <tbody>
            {walletTransactions.length > 0 ? (
              walletTransactions
                .slice(page * 9 - 9, page * 9)
                .map((transaction) => (
                  <tr
                    key={transaction.transaction_id}
                    className="bg-white border-b border-slate-300 align-middle h-auto"
                  >
                    <td className="p-3 text-xs md:text-sm">
                      {transaction.date}
                    </td>
                    <td className="p-3 text-xs md:text-sm">
                      <span
                        className={`px-1 capitalize text-xs md:text-sm ${
                          transaction.plan_type === "silver"
                            ? "blue-badge"
                            : transaction.plan_type === "gold"
                            ? "orange-badge"
                            : "indigo-badge"
                        }`}
                      >
                        {transaction.plan_type}
                      </span>
                    </td>
                    <td className="p-3 text-xs md:text-sm">
                      <span className="font-medium mr-1">&#8377;</span>
                      {transaction.amount}
                    </td>
                    <td className="p-3 text-xs md:text-sm">
                      <div className="flex items-center gap-x-1 text-[#5a5a5b]">
                        <img src={PHcoin} alt="PHcoin" />
                        {transaction.credits}
                      </div>
                    </td>
                    <td className="p-3 text-xs md:text-sm">
                      {transaction.transaction_id}
                    </td>
                    <td className="p-3">
                      <span
                        className={`px-1 text-xs md:text-sm capitalize ${
                          transaction.status === "200"
                            ? "green-badge"
                            : "red-badge"
                        }`}
                      >
                        {transaction.status === "200" ? "paid" : "failed"}
                      </span>
                    </td>
                  </tr>
                ))
            ) : (
              <tr className="bg-white text-center">
                <td colSpan="6" className="p-3 text-xs md:text-sm">
                  No transaction found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {walletTransactions.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          totalDataCount={walletTransactions.length}
          dataCount={9}
        />
      )}
    </>
  );
}
