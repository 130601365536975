import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useFetch from "../../../hooks/useFetch";
import { Error } from "../../../components/ui/Error";
import { Loader } from "../../../components/ui/Loader";
import AdminNavbar from "../../../components/layout/adminNavbar";
import AdminSidebar from "../../../components/layout/adminSidebar";
import RequestTable from "./RequestTable";
import { Dropdown } from "../../../components/ui/inputs/dropdown";

const Requests = () => {
  const getWithdrawReqsApi = `${process.env.REACT_APP_API_URL}display-withdraws`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const navigate = useNavigate();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [tableType, setTableType] = useState("All");
  const [page, setPage] = useState(1);
  const [requests, setRequests] = useState([]);

  const getWithdrawReqs = useCallback(() => {
    fetchApi(
      {
        url: getWithdrawReqsApi,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
      },
      (data) => {
        setRequests(data.withdraw_requests);
      }
    );
  }, [getWithdrawReqsApi, fetchApi, token]);

  useEffect(() => {
    if (userRole !== "admin") {
      navigate("/error-401");
    }
    const timer = setTimeout(() => {
      getWithdrawReqs();
    }, 20);
    return () => {
      clearTimeout(timer);
    };
  }, [getWithdrawReqs, navigate, userRole]);

  const changeTableType = (value) => {
    setTableType(value);
    setPage(1);
  };

  return (
    <div className="grid grid-cols-5">
      <AdminSidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <AdminNavbar handleSidebar={() => setOpenSidebar(true)} />
        {isLoading && <Loader />}
        {error && <Error />}
        {!error && !isLoading && (
          <div className="p-4">
            <div className="flex flex-col items-start gap-3 md:flex-row md:items-center md:justify-between md:mb-3 md:gap-0">
              <h3 className="text-lg font-medium">WithDraw Requests</h3>
              <div className="flex items-center order-1 md:order-2">
                <label
                  htmlFor="withdraw"
                  className="text-lg font-medium mr-2 mb-0"
                >
                  Filter :{" "}
                </label>
                <Dropdown
                  options={[
                    { label: "All", value: "All" },
                    { label: "Pending", value: "Pending" },
                    { label: "Completed", value: "Completed" },
                    { label: "Rejected", value: "Failed" },
                  ]}
                  onChange={(selectedOption) =>
                    changeTableType(selectedOption.value)
                  }
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      width: "150px",
                    }),
                  }}
                  defaultValue={{ label: "All", value: "All" }}
                  id="withdraw-filter"
                />
              </div>
            </div>
            <RequestTable
              page={page}
              setPage={setPage}
              type={tableType}
              data={requests}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Requests;
