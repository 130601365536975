import React, { useState, useCallback, useMemo } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useToastContext } from "../../context/ToastContext";
import {
  markAllNotificationsAsRead,
  updateNotiStatus,
} from "../../store/slices/NotificationSlice";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/layout/Navbar";
import NotiType from "../../utils/notificationRoute";
import NotificationItem from "./NotificationItem";
import { Error } from "../../components/ui/Error";
import { Button } from "../../components/ui/Button";

const Notifications = () => {
  const updateNotificationApi = `${process.env.REACT_APP_API_URL}update-notification`;
  const markAsReadApi = `${process.env.REACT_APP_API_URL}mark-as-read`;

  const { token, userRole } = useLocalStorage();
  const dispatch = useDispatch();
  const { notifications, notificationCount, error } = useSelector(
    (state) => state.notifications
  );
  const toast = useToastContext();

  const [openSidebar, setOpenSidebar] = useState(false);

  const memoizedNotiType = useMemo(() => NotiType, []);

  const updateNotification = useCallback(
    (notificationId) => {
      dispatch(updateNotiStatus({ notificationId: notificationId }));
      axios
        .put(
          updateNotificationApi,
          { id: notificationId },
          {
            headers: {
              "x-token": token,
            },
          }
        )
        .then((res) => {})
        .catch(() =>
          toast.open(
            "false",
            "Sorry, something went wrong while updating notification"
          )
        );
    },
    [token, updateNotificationApi, toast, dispatch]
  );

  const markAsRead = useCallback(() => {
    dispatch(markAllNotificationsAsRead());
    axios
      .put(markAsReadApi, null, {
        headers: {
          "x-token": token,
        },
      })
      .then((res) => {})
      .catch(() =>
        toast.open(
          "false",
          "Sorry, something went wrong while updating notification"
        )
      );
  }, [toast, token, markAsReadApi, dispatch]);

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="page-body p-4">
          {error && <Error />}
          {!error && (
            <>
              <Button
                onClick={() => markAsRead()}
                className={`px-2 py-1 text-sm block ms-auto mb-4 ${
                  notificationCount === 0 && "disabled "
                }`}
                disabled={notificationCount === 0}
              >
                Mark as Read
              </Button>
              <div className="grid gap-y-2">
                {notifications.length > 0 ? (
                  notifications.map((noti) => (
                    <NotificationItem
                      key={noti.id}
                      data={noti}
                      fn={memoizedNotiType}
                      userRole={userRole}
                      updateNotification={updateNotification}
                    />
                  ))
                ) : (
                  <p className="font-medium text-base">
                    You have no new notifications.
                  </p>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
