import React, { Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserContext } from "./context/videoContext";

import Signin from "./Pages/login-page/user/Signin";
import Register from "./Pages/register-page/Register";
import ResetPassword from "./Pages/login-page/resetPassword";
import ProfileCompletion from "./Pages/profile-pages/ProfileCompletion";
import StartupProfile from "./Pages/profile-pages/StartupProfile";
import MentorProfile from "./Pages/profile-pages/MentorProfile";
import InvestorProfile from "./Pages/profile-pages/InvestorProfile";
import VendorProfile from "./Pages/profile-pages/vendorProfile";
import ViewStartupProfile from "./Pages/profile-pages/ViewStartupProfile";
import ViewMentorProfile from "./Pages/profile-pages/ViewMentorProfile";
import ViewInvestorProfile from "./Pages/profile-pages/ViewInvestorProfile";
import ViewVendorProfile from "./Pages/profile-pages/ViewVendorProfile";
import StartupDashBoard from "./Pages/dashboard/startupsDashboard";
import MentorDashBoard from "./Pages/dashboard/mentorDashboard";
import InvestorDashBoard from "./Pages/dashboard/investorDashboard";
import { MentorConnections } from "./Pages/connections/mentor/mentorConnections";
import { StartupConnections } from "./Pages/connections/startup/startupConnections";
import { ComplaintTickets } from "./Pages/tickets/complaintTickets";
import { InvestorConnections } from "./Pages/connections/investor/investorConnections";
import StartupWallet from "./Pages/transaction-pages/startup/startupWallet";
import MentorWallet from "./Pages/transaction-pages/mentor/mentorWallet";
import Agreement from "./Pages/investments/agreement";
import { InvestorInvestments } from "./Pages/investments/investor/investorInvestments";
import { StartupInvestments } from "./Pages/investments/startup/startupInvestments";
import { ViewAgreement } from "./Pages/investments/viewAgreement";
import Subscription from "./Pages/subscription/Subscription";
import VendorPlan from "./Pages/subscription/vendorPlan";
import Market from "./Pages/market-place/Market";
import MyAds from "./Pages/market-place/MyAds";
import Bids from "./Pages/market-place/Bids";
import MyBids from "./Pages/market-place/MyBids";
import Milestone from "./Pages/market-place/Milestone";
import VendorWallet from "./Pages/transaction-pages/vendor/vendorWallet";
import ChatPage from "./Pages/chat-page/ChatPage";
import Meetings from "./Pages/video/Meetings";
import MeetingSummary from "./Pages/video/MeetingSummary";
import Session from "./Pages/video/Session";
import PaymentStatus from "./Pages/transaction-pages/paymentStatus";
import Faq from "./Pages/Faq";
import Reviews from "./Pages/reviews/Reviews";
import Notifications from "./Pages/notifications/Notifications";
import SampleDocument from "./Pages/sample-documents/SampleDocument";
import Settings from "./Pages/Settings";
import { Loader } from "./components/ui/Loader";
import { Error500 } from "./Pages/Error/Error500";
import { Error401 } from "./Pages/Error/Error401";
import { Error403 } from "./Pages/Error/Error403";
import { Error404 } from "./Pages/Error/Error404";

//Admin imports
import AdminLogin from "./Pages/login-page/admin/adminSignin";
import AdminDashboard from "./Pages/admin-portal/admin-dashboard/AdminDashboard";
import UserDetails from "./Pages/admin-portal/users/userDetails";
import Requests from "./Pages/admin-portal/withdraw/Requests";
import Tickets from "./Pages/admin-portal/tickets/Tickets";
import RequestDetails from "./Pages/admin-portal/withdraw/RequestDetails";
import Investments from "./Pages/admin-portal/transactions/Investments";
import InvestmentDetails from "./Pages/admin-portal/transactions/InvestmentDetails";
import Industries from "./Pages/admin-portal/industry/Industries";
import PlatformFees from "./Pages/admin-portal/platform-fees/platformFees";
import Store from "./Pages/admin-portal/token-store/Store";
import AdminSettings from "./Pages/admin-portal/Settings";
import Documents from "./Pages/admin-portal/sample-documents/documents";
import TicketDetails from "./Pages/tickets/ticketDetails";
import AdminTicketDetails from "./Pages/admin-portal/tickets/TicketDetails";

//Lazy loaded components
const StartupsPage = React.lazy(() =>
  import("./Pages/users-page/startupsPage")
);
const MentorsPage = React.lazy(() => import("./Pages/users-page/mentorsPage"));
const InvestorsPage = React.lazy(() =>
  import("./Pages/users-page/investorsPage")
);
const VendorsPage = React.lazy(() => import("./Pages/users-page/vendorsPage"));

const Users = React.lazy(() => import("./Pages/admin-portal/users/allUsers"));
const UserImages = React.lazy(() =>
  import("./Pages/admin-portal/users/userImages")
);

var meetingArgs = {
  videoSDKJWT: "",
  sessionName: "",
  userName: "",
  sessionPasscode: "",
  features: ["video", "audio", "settings", "users", "chat", "share"],
};

function App() {
  return (
    <Router>
      <Routes>
        {/* Common pages */}
        <Route path="/" element={<Signin />} />
        <Route path="/register" element={<Register />} />
        <Route path="/chat" element={<ChatPage />} />
        <Route path="/store" element={<Subscription />} />
        <Route path="/payment/:type/:status" element={<PaymentStatus />} />
        {/* <Route path="/reset-password/:token" element={<ResetPassword />} /> */}
        <Route
          path="/reset-password/:role/:token"
          element={<ResetPassword />}
        />
        <Route path="/profile-completion" element={<ProfileCompletion />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/tickets" element={<ComplaintTickets />} />
        <Route path="/ticket/:id" element={<TicketDetails />} />
        <Route path="/agreement" element={<Agreement />} />
        <Route path="/view-agreement" element={<ViewAgreement />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/market" element={<Market />} />
        <Route path="/bids/:id" element={<Bids />} />
        <Route path="/milestone/:ad_id/:bid_id" element={<Milestone />} />
        <Route path="/error-500" element={<Error500 />} />
        <Route path="/error-401" element={<Error401 />} />
        <Route path="/error-403" element={<Error403 />} />
        <Route path=":text" element={<Error404 />} />
        <Route path="/admin/:text" element={<Error404 />} />
        <Route
          path="/meetings"
          element={
            <UserContext.Provider value={meetingArgs}>
              <Meetings />
            </UserContext.Provider>
          }
        />
        <Route
          path="/session/:roomId/:meetingId/:userId"
          element={
            <UserContext.Provider value={meetingArgs}>
              <Session />
            </UserContext.Provider>
          }
        />
        <Route
          path="/meeting-summary/:meetingId"
          element={<MeetingSummary />}
        />
        {/* Startup */}
        <Route path="/startup-dashboard" element={<StartupDashBoard />} />
        <Route
          path="/startups"
          element={
            <Suspense
              fallback={
                <div className="p-28">
                  <Loader />
                </div>
              }
            >
              <StartupsPage />
            </Suspense>
          }
        />
        <Route path="/startup-profile" element={<StartupProfile />} />
        <Route
          path="/view-startup/:name/:user_id"
          element={<ViewStartupProfile />}
        />
        <Route path="/startup-connections" element={<StartupConnections />} />
        <Route path="/startup-wallet" element={<StartupWallet />} />
        <Route path="/startup-investments" element={<StartupInvestments />} />
        <Route path="/my-projects" element={<MyAds />} />
        <Route path="/templates" element={<SampleDocument />} />

        {/* Mentor */}
        <Route path="/mentor-dashboard" element={<MentorDashBoard />} />
        <Route
          path="/mentors"
          element={
            <Suspense
              fallback={
                <div className="p-28">
                  <Loader />
                </div>
              }
            >
              <MentorsPage />
            </Suspense>
          }
        />
        <Route path="/mentor-connections" element={<MentorConnections />} />
        <Route path="/mentor-wallet" element={<MentorWallet />} />
        <Route
          path="/view-mentor/:name/:user_id"
          element={<ViewMentorProfile />}
        />
        <Route path="/mentor-profile" element={<MentorProfile />} />

        {/* Investor */}
        <Route path="/investor-dashboard" element={<InvestorDashBoard />} />
        <Route
          path="/investors"
          element={
            <Suspense
              fallback={
                <div className="p-28">
                  <Loader />
                </div>
              }
            >
              <InvestorsPage />
            </Suspense>
          }
        />
        <Route path="/investor-connections" element={<InvestorConnections />} />
        <Route path="/investor-investments" element={<InvestorInvestments />} />
        <Route
          path="/view-investor/:name/:user_id"
          element={<ViewInvestorProfile />}
        />
        <Route path="/investor-profile" element={<InvestorProfile />} />

        {/* Vendor */}
        <Route
          path="/vendors"
          element={
            <Suspense
              fallback={
                <div className="p-28">
                  <Loader />
                </div>
              }
            >
              <VendorsPage />
            </Suspense>
          }
        />
        <Route path="/vendor-profile" element={<VendorProfile />} />
        <Route
          path="/view-vendor/:name/:user_id"
          element={<ViewVendorProfile />}
        />
        <Route path="/vendor-plan" element={<VendorPlan />} />
        <Route path="/my-bids" element={<MyBids />} />
        <Route path="/vendor-wallet" element={<VendorWallet />} />

        {/* Admin */}
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route
          path="/admin/users"
          element={
            <Suspense
              fallback={
                <div className="p-28">
                  <Loader />
                </div>
              }
            >
              <Users />
            </Suspense>
          }
        />
        <Route
          path="/admin/user-images"
          element={
            <Suspense
              fallback={
                <div className="p-28">
                  <Loader />
                </div>
              }
            >
              <UserImages />
            </Suspense>
          }
        />
        <Route path="/admin/user-details/:id" element={<UserDetails />} />
        <Route path="/admin/withdraw-requests" element={<Requests />} />
        <Route path="/admin/request/:id" element={<RequestDetails />} />
        <Route path="/admin/complaint-tickets" element={<Tickets />} />
        <Route path="/admin/ticket/:id" element={<AdminTicketDetails />} />
        <Route path="/admin/investments" element={<Investments />} />
        <Route
          path="/admin/investment-details/:id"
          element={<InvestmentDetails />}
        />
        <Route path="/admin/store" element={<Store />} />
        <Route path="/admin/industries" element={<Industries />} />
        <Route path="/admin/platform-fees" element={<PlatformFees />} />
        <Route path="/admin/settings" element={<AdminSettings />} />
        <Route path="/admin/templates" element={<Documents />} />
      </Routes>
    </Router>
  );
}

export default App;
