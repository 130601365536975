import { Link } from "react-router-dom";
import { Pagination } from "../../../components/ui/Button";
import { EyeIcon } from "../../../icons/icons";

const TicketTable = ({ page, setPage, data, type }) => {
  let filterData;
  if (type === "All") {
    filterData = data;
  } else {
    filterData = data.filter((data) => data.support_type === type);
  }

  return (
    <>
      <div className="overflow-auto w-full md:overflow-none">
        <table className="table-auto min-w-max w-full mt-4 border bg-white shadow-sm border-slate-300">
          <thead className="bg-[#F9FAFC] text-[#5a5a5b] border border-gray-200 text-left">
            <tr>
              <th className="font-medium p-3 text-xs md:text-sm">Name</th>
              <th className="font-medium p-3 text-xs md:text-sm">Date</th>
              <th className="font-medium p-3 text-xs md:text-sm">Category</th>
              <th className="max-w-[40%] font-medium p-3 text-xs md:text-sm">
                Title
              </th>
              <th className="font-medium p-3 text-xs md:text-sm">Status</th>
              <th className="font-medium p-3 text-xs md:text-sm">Action</th>
            </tr>
          </thead>
          <tbody>
            {filterData.length > 0 ? (
              filterData.slice(page * 10 - 10, page * 10).map((ticket) => (
                <tr
                  key={ticket.id}
                  className="bg-white border-b border-slate-300 align-middle h-auto"
                >
                  <td className="p-3 text-sm uppercase">
                    {ticket.name}{" "}
                    <p
                      className={`w-[15px] h-[15px] text-white rounded-full text-center flex items-center justify-center text-xs ${
                        ticket.role === "f@!3A"
                          ? "bg-primary"
                          : ticket.role === "R$7s2"
                          ? "bg-secondary"
                          : ticket.role === "Sw%aa"
                          ? "bg-orange-600"
                          : "bg-green-600"
                      }`}
                    >
                      {ticket.role === "f@!3A"
                        ? "S"
                        : ticket.role === "R$7s2"
                        ? "M"
                        : ticket.role === "Sw%aa"
                        ? "I"
                        : "V"}
                    </p>
                  </td>
                  <td className="p-3 text-sm">{ticket.date}</td>
                  <td className="p-3 text-sm capitalize">
                    {ticket.support_type}
                  </td>
                  <td className="p-3 text-sm">
                    {ticket.ticket_heading.length > 50
                      ? ticket.ticket_heading.slice(0, 50).concat("...")
                      : ticket.ticket_heading}
                  </td>
                  <td className="p-3 text-xs">
                    <span
                      className={`p-1 ${
                        ticket.status === "0"
                          ? "blue-badge"
                          : ticket.status === "1"
                          ? "orange-badge"
                          : "green-badge"
                      }`}
                    >
                      {ticket.status === "0"
                        ? "Pending"
                        : ticket.status === "1"
                        ? "Processing"
                        : "Solved"}
                    </span>
                  </td>
                  <td className="p-3 text-xs">
                    <Link
                      to={`/admin/ticket/${ticket.id}`}
                      title="View Ticket Details"
                    >
                      <EyeIcon fill="#3B82F6" />
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="bg-white text-center">
                <td colSpan={6} className="p-3 text-xs md:text-sm">
                  No New Ticket
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {filterData.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          totalDataCount={filterData.length}
          dataCount={10}
        />
      )}
    </>
  );
};

export default TicketTable;
