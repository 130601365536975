import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const displayIndustriesApi = `${process.env.REACT_APP_API_URL}display-industries`;

const initialState = {
  userIndustries: [],
  marketIndustries: [],
  status: "idle",
  isError: null,
};

export const fetchIndustries = createAsyncThunk(
  "industries/fetch",
  async ({ token, type }, { rejectWithValue }) => {
    try {
      const response = await axios.post(displayIndustriesApi, {
        headers: {
          "x-token": token,
        },
        type: type,
      });
      const data = response.data;
      return {
        industries: data.industries,
        type: type,
      };
    } catch (error) {
      if (error.response.data.message === "Invalid token") {
        window.location.href = "/error-401";
      }
      let errorMessage = "An error occurred while fetching industries.";
      if (error.response && error.response.data) {
        errorMessage = error.response.data.message || errorMessage;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

const fetchIndustriesSlice = createSlice({
  name: "industries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIndustries.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchIndustries.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.type === "other") {
          state.userIndustries = action.payload.industries;
        } else {
          state.marketIndustries = action.payload.industries;
        }
      })
      .addCase(fetchIndustries.rejected, (state, action) => {
        state.status = "failed";
        state.isError = action.payload;
      });
  },
});

export default fetchIndustriesSlice.reducer;
