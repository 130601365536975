import { Button } from "../../components/ui/Button";
import FavIcon from "../../images/favicon.png";

const SingleConnection = ({ data, ViewProfile, userRole, userId }) => {
  return (
    <div className="single-row">
      <div className="flex items-center">
        <div className="h-[45px] border flex items-center rounded-full">
          <img
            src={data.logo.length > 0 ? data.logo : FavIcon}
            alt="profile-img"
            width={45}
            className="h-full rounded-full p-[2px]"
          />
        </div>
        <div className="ps-2">
          <span className="text-sm font-medium capitalize">{data.name}</span>
          <p>
            {userRole === "f@!3A" ? (
              <span
                className={`px-1 text-xs rounded-md border ${
                  data.role === "R$7s2"
                    ? "border-blue-500 text-blue-500"
                    : data.role === "Sw%aa"
                    ? "border-green-500 text-green-500"
                    : "border-orange-500 text-orange-500"
                }`}
              >
                {data.role === "R$7s2"
                  ? "Mentor"
                  : data.role === "Sw%aa"
                  ? "Investor"
                  : "Service Provider"}
              </span>
            ) : (
              <span className="px-1 text-xs border border-blue-500 text-blue-500 rounded-md capitalize">
                {data.company_name}
              </span>
            )}
          </p>
        </div>
      </div>
      <Button
        className="px-3 py-1 !rounded-full text-xs"
        onClick={() =>
          ViewProfile(
            userId,
            data.role === "f@!3A" || data.role === "V&iR8"
              ? data.company_name
              : data.name,
            data.role
          )
        }
      >
        Details
      </Button>
    </div>
  );
};

export default SingleConnection;
