import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchReviews } from "../../store/slices/profile/ReviewsSlice";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/layout/Navbar";
import { SingleReview } from "./singleReview";
import RatingCircle from "./ratingCircle";

const Reviews = () => {
  const { token, userRole } = useLocalStorage();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const {
    reviews,
    role,
    average_rating,
    investor,
    mentor,
    vendor,
    startup,
    status,
    error,
  } = useSelector((state) => state.reviews);

  const [openSidebar, setOpenSidebar] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (status === "idle") {
        dispatch(
          fetchReviews({
            token,
            userId: state?.user_id || null,
            role: state?.role || userRole,
          })
        );
      }
    });
    return () => {
      clearTimeout(timer);
    };
  }, [token, userRole, state, dispatch, status]);

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="p-4">
          {status === "failed" && <Error />}
          {status === "loading" && <Loader />}
          {status === "succeeded" &&
            !error &&
            (reviews.length > 0 ? (
              <>
                <div className="flex items-center justify-center mb-5">
                  <RatingCircle rating={average_rating} />
                  {role === "f@!3A" && (
                    <RatingCircle
                      rating={mentor}
                      title="Rating given by mentor"
                      tag="Mentor"
                    />
                  )}
                  {role === "f@!3A" && (
                    <RatingCircle
                      rating={investor}
                      title="Rating given by investor"
                      tag="Investor"
                    />
                  )}
                  {role === "f@!3A" && (
                    <RatingCircle
                      rating={vendor}
                      title="Rating given by service provider"
                      tag="Vendor"
                    />
                  )}
                  {(role === "R$7s2" || role === "V&iR8") && (
                    <RatingCircle
                      rating={startup}
                      title="Rating given by startup"
                      tag="Startup"
                    />
                  )}
                </div>
                {reviews.map((review) => (
                  <SingleReview key={review.id} data={review} />
                ))}
              </>
            ) : (
              <p className="font-medium text-base mt-5">No reviews found.</p>
            ))}
        </section>
      </div>
    </div>
  );
};

export default Reviews;
