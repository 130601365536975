import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import { Loader } from "../../components/ui/Loader";
import { Button } from "../../components/ui/Button";
import { FormError } from "../../components/ui/FormError";
import { bannedInputs, numberWithDecimalRegex } from "../../constants";
import { BriefCaseIcon, BuildingIcon, UploadIcon } from "../../icons/icons";
import { useToastContext } from "../../context/ToastContext";
import { Dropdown } from "../../components/ui/inputs/dropdown";
import { getCurrentDate } from "../../utils/getCurrentDate";

export const MentorDetailsForm = ({ token, industries }) => {
  const mentorDetailsApi = `${process.env.REACT_APP_API_URL}insert-mentor-details`;

  const navigate = useNavigate();
  const toast = useToastContext();
  const { isLoading, error } = useFetch();

  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [isClicked, setIsClicked] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const MentorDetailsApiCall = (data) => {
    setIsClicked(true);
    document.getElementById("mentor-category-error").classList.add("hidden");
    document.getElementById("mentor-category-limit").classList.add("hidden");
    if (selectedIndustry.length < 1) {
      document
        .getElementById("mentor-category-error")
        .classList.remove("hidden");
      return;
    }
    if (selectedIndustry.length > 3) {
      document
        .getElementById("mentor-category-limit")
        .classList.remove("hidden");
      return;
    }
    let logo = document.getElementById("mentorLogo").files[0];

    const formData = new FormData();
    formData.append("file", logo);
    formData.append("work_experience", data.work_experience);
    formData.append("mentor_industry", selectedIndustry);
    formData.append("previous_company", data.previous_company);
    formData.append("short_description", data.short_description);
    formData.append("date", getCurrentDate());
    axios
      .post(mentorDetailsApi, formData, {
        headers: { "x-token": token },
      })
      .then((response) => {
        setIsClicked(false);
        if (response.data.message === "validation error") {
          toast.open("false", "Sorry, Failed to submit. Validation failed.");
          return;
        }
        navigate("/mentor-dashboard");
      })
      .catch((err) => toast.open("false", "Failed to submit"));
  };

  return (
    <div
      id="mentorDetailsForm"
      className="flex col-span-7 authentication-bg relative bg-white md:items-center pb-3 px-4 md:px-0 md:py-0 md:my-3 lg:my-0 lg:py-3 md:col-start-2 md:col-span-5 lg:col-span-2"
    >
      <form
        onSubmit={handleSubmit(MentorDetailsApiCall)}
        className="mx-auto p-5 rounded-md shadow-md md:w-full lg:p-3 lg:shadow-none lg:rounded-none lg:pt-0"
      >
        <h5 className="font-medium text-lg mb-2">
          Some details about your career
        </h5>
        <div className="mb-1">
          <label htmlFor="workExperience" className="mb-0 text-sm required">
            Work experience(in years)
          </label>
          <div
            className="flex rounded-md overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="text"
              className="border-0 w-full ps-3"
              id="work_experience"
              name="work_experience"
              placeholder="ex: 5"
              {...register("work_experience", {
                required: "Work experience is required",
                validate: (fieldvalue) => {
                  return fieldvalue > 0 || "Work experience cannot be zero";
                },
                pattern: {
                  value: numberWithDecimalRegex,
                  message: "Cannot start or end with decimal point",
                },
                maxLength: {
                  value: 4,
                  message: "Cannot exceed 4 characters",
                },
              })}
              autoFocus
            />
            <span className="input-icons icon-span">
              <BriefCaseIcon />
            </span>
          </div>
          {errors.work_experience && (
            <div>
              <FormError message={errors.work_experience.message} />
            </div>
          )}
        </div>
        <div className="mb-1 flex-col">
          <p>
            Industry
            <span className="required">({selectedIndustry.length + "/3"})</span>
            <span
              id="mentor-category-limit"
              className="hidden text-red-500 ms-2"
            >
              Limit exceeded
            </span>
          </p>
          <Dropdown
            options={industries?.map((industry) => ({
              label: industry.industry_name
                .replace(new RegExp("_", "g"), " ")
                .toUpperCase(),
              value: industry.industry_name,
            }))}
            onChange={(selectedOptions) =>
              setSelectedIndustry(selectedOptions.map((option) => option.value))
            }
            isMulti
          />
          <small
            className="text-red-500 mb-2 hidden"
            id="mentor-category-error"
          >
            This field is mandatory.
          </small>
        </div>
        <div className="mb-1">
          <label htmlFor="mentorCompany" className="mb-0 text-sm required">
            Current or Previous company Name
          </label>
          <div
            className="flex rounded-md overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="text"
              className="border-0 w-full ps-3"
              id="previous_company"
              name="previous_company"
              placeholder="Enter company name"
              {...register("previous_company", {
                required: "Company name is required",
                validate: {
                  minLength: (fieldvalue) => {
                    return (
                      fieldvalue.trim().length > 2 ||
                      "Company Name must be atleast 3 characters"
                    );
                  },
                  maxLength: (fieldvalue) => {
                    return (
                      fieldvalue.trim().length < 101 ||
                      "Cannot exceed 100 characters"
                    );
                  },
                  bannedValue: (fieldvalue) => {
                    return fieldvalue.trim().length > 2
                      ? !bannedInputs.some((value) =>
                          fieldvalue.includes(value)
                        ) || "Enter a different name"
                      : null;
                  },
                },
              })}
            />
            <span className="input-icons icon-span">
              <BuildingIcon />
            </span>
          </div>
          {errors.previous_company && (
            <div>
              <FormError message={errors.previous_company.message} />
            </div>
          )}
        </div>
        <div className="mb-1">
          <label htmlFor="short_description" className="mb-0 text-sm required">
            About me
          </label>
          <div className="border">
            <textarea
              name="short_description"
              id="short_description"
              cols="30"
              rows="4"
              className="border-0 w-full ps-3 pt-1 rounded-md"
              placeholder="Write here..."
              {...register("short_description", {
                required: "short description is required",
                validate: {
                  minLength: (fieldvalue) => {
                    return (
                      fieldvalue.trim().length > 20 ||
                      "Short description must be atleast 20 characters"
                    );
                  },
                  maxLength: (fieldvalue) => {
                    return (
                      fieldvalue.trim().length < 1001 ||
                      "Cannot exceed 1000 characters"
                    );
                  },
                },
              })}
            ></textarea>
          </div>
          {errors.short_description && (
            <div>
              <FormError message={errors.short_description.message} />
            </div>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="mentorLogo" className="text-sm mb-0">
            Profile Image
          </label>
          <div
            className="flex items-center rounded-md overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="file"
              accept="image/*"
              className="border-0 w-full ps-3"
              id="mentorLogo"
              name="mentorLogo"
              placeholder="Upload Profile Image"
            />
            <span className="input-icons icon-span">
              <UploadIcon />
            </span>
          </div>
        </div>
        {isLoading && !error ? <Loader pt="10px" classes="my-2" /> : null}
        <Button
          type="submit"
          className={`py-1.5 w-full ${
            isClicked && "disabled cursor-not-allowed"
          }`}
          bgColor={isClicked ? "bg-gray-400" : "bg-primary"}
          disabled={isClicked}
        >
          Next
        </Button>
      </form>
    </div>
  );
};
