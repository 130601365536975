import { useForm } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import { Button } from "../../components/ui/Button";
import { MailIcon } from "../../icons/icons";

export const FrogotPasswordForm = ({ backToLogin, role }) => {
  const forgetPasswordApi = `${process.env.REACT_APP_API_URL}forget-password`;
  const adminForgetPasswordApi = `${process.env.REACT_APP_API_URL}forget-password-admin`;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const { fetchApi } = useFetch();

  const forgetPasswordApiCall = (data) => {
    fetchApi(
      {
        url: role === "user" ? forgetPasswordApi : adminForgetPasswordApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: { email: data.resetEmail },
      },
      (data) => {
        if (data.message === "invalid") {
          document.getElementById("wrongResetEmail").classList.remove("hidden");
        } else {
          reset();
          document.getElementById("wrongResetEmail").classList.add("hidden");
          document.getElementById("forgotPasswordForm").classList.add("hidden");
          document
            .getElementById("confirmationText")
            .classList.remove("hidden");
        }
      }
    );
  };

  return (
    <form
      className="flex col-span-7 authentication-bg relative md:col-start-2 md:col-span-5 md:items-center pb-3 px-4 md:px-0 md:py-0 md:my-3 lg:col-span-2 lg:my-0 lg:py-0"
      id="forgotPasswordForm"
      onSubmit={handleSubmit(forgetPasswordApiCall)}
    >
      <div className="mx-auto p-6 rounded-md md:shadow-md md:w-full bg-white lg:p-3 lg:shadow-none lg:rounded-none lg:bg-transparent lg:pt-0">
        <h4 className="hidden lg:block mb-5 text-lg font-semibold text-center">
          {process.env.REACT_APP_COMPANY_NAME}! 👋
        </h4>
        <h5 className="mb-5 text-center">
          Forgot your password ? No, Worries we got you!
        </h5>
        <div>
          <label htmlFor="resetEmail" className="mb-0 text-sm required">
            Enter registered email
          </label>
          <div
            className="flex rounded-md overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="email"
              className="border-0 w-full ps-3"
              id="resetEmail"
              name="resetEmail"
              placeholder="Registered email"
              {...register("resetEmail", {
                required: true,
                validate: {
                  matchPattern: (value) =>
                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(
                      value
                    ) || "Please enter a valid email address",
                },
              })}
              autoFocus
            />
            <span className="input-icons icon-span">
              <MailIcon />
            </span>
          </div>
          {errors.resetEmail && (
            <span className="text-secondary">Email is required</span>
          )}
          {!errors.resetEmail && (
            <small className="text-red-500 hidden" id="wrongResetEmail">
              Invalid! Please enter a registered email address
            </small>
          )}
          <Button type="submit" className="py-1.5 mt-3 w-full">
            Next
          </Button>
          <div className="flex justify-center mt-3">
            <span>Missed Click your way here ? </span>
            <p
              className="text-blue-500 cursor-pointer m-0 ps-1"
              onClick={backToLogin}
            >
              Go Back
            </p>
          </div>
        </div>
      </div>
    </form>
  );
};
