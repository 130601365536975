import ReactDom from "react-dom";
import { useNavigate } from "react-router-dom";
import { CircleCheck } from "../../../../icons/icons";

export const SuccessModal = ({ open, onClose, text }) => {
  const navigate = useNavigate();
  if (!open) return null;

  const closeModal = () => {
    navigate("/investor-investments");
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`w-[90%] sm:w-[350px] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={() => closeModal()} className="closeBtn">
          X
        </p>
        <div className="p-3 pb-0 mt-2 text-center">
          <CircleCheck
            width="70px"
            height="70px"
            fill="#1bcf1b"
            className="mx-auto"
          />
          <p className="text-lg text-green-500 mb-2">Success</p>
          <p>{text}</p>
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
