import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteChatRoom, setChatRoom } from "../../store/slices/ChatSlice";
import useFetch from "../../hooks/useFetch";
import useNotificationApi from "../../hooks/useNotificationApi";
import useLocalStorage from "../../hooks/useLocalStorage";
import useCheckout from "../../hooks/useCheckout";
import { useToastContext } from "../../context/ToastContext";
import { Button } from "../../components/ui/Button";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/layout/Navbar";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import RatingModal from "../../components/ui/modals/rating-modal/RatingModal";
import { getCurrentDate } from "../../utils/getCurrentDate";

const Milestone = () => {
  const milestoneDataApi = `${process.env.REACT_APP_API_URL}display-milestone`;
  const markAsCompletedApi = `${process.env.REACT_APP_API_URL}mark-as-complete`;
  const submitReviewApi = `${process.env.REACT_APP_API_URL}review`;
  const connectApi = `${process.env.REACT_APP_API_URL}connect`;
  const getRoomIdApi = `${process.env.REACT_APP_API_URL}get-roomid`;

  const { token, name, userRole } = useLocalStorage();
  const { isLoading, error, fetchApi } = useFetch();
  const { ad_id, bid_id } = useParams();
  const { createCheckoutSession } = useCheckout();
  const { sendNotification } = useNotificationApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const toast = useToastContext();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [openRateModal, setOpenRateModal] = useState(false);

  const date = Date.now();
  const currDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(date);

  const milestoneData = useCallback(() => {
    fetchApi(
      {
        url: milestoneDataApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          ad_id: ad_id,
          bid_id: bid_id,
        },
      },
      (data) => {
        setProjectData(data.details[0]);
      }
    );
  }, [fetchApi, token, milestoneDataApi, ad_id, bid_id]);

  const markAsCompleted = useCallback(
    (vendor_id) => {
      fetchApi(
        {
          url: markAsCompletedApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            ad_id: ad_id,
            bid_id: bid_id,
            vendor_id: vendor_id,
          },
        },
        () => {
          toast.open("true", "Payment released successfully!");
          sendNotification(
            projectData?.vendor_id,
            `${name} has released your payment`,
            "market"
          );
          milestoneData();
        }
      );
    },
    [
      fetchApi,
      token,
      markAsCompletedApi,
      ad_id,
      bid_id,
      milestoneData,
      toast,
      name,
      projectData?.vendor_id,
      sendNotification,
    ]
  );

  const submitRating = useCallback(
    (rating, review) => {
      fetchApi(
        {
          url: submitReviewApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            reciever_id: projectData?.vendor_id,
            review: review,
            rating: rating,
            role: "vendor",
            ad_id: ad_id,
            date: getCurrentDate(),
          },
        },
        (data) => {
          if (data.message === "already reviewed") {
            alert(
              "Failed submission!. You have already given a review to this user."
            );
            setOpenRateModal(false);
            return;
          }
          sendNotification(
            projectData?.vendor_id,
            `${name} marked the project as complete and reviewed it. Please do the same by opening the project page`,
            "reviews"
          );
          markAsCompleted(projectData?.vendor_id);
        }
      );
    },
    [
      fetchApi,
      token,
      submitReviewApi,
      sendNotification,
      markAsCompleted,
      ad_id,
      name,
      projectData?.vendor_id,
    ]
  );

  const getRoomId = useCallback(
    (userId) => {
      fetchApi(
        {
          url: getRoomIdApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            connection_id: userId,
            role: "V&iR8",
          },
        },
        (data) => {
          dispatch(deleteChatRoom());
          dispatch(
            setChatRoom({
              roomId: data.room_id,
              recieverId: userId,
              recieverRole: "V&iR8",
              status: "1",
            })
          );
        }
      );
    },
    [getRoomIdApi, fetchApi, token, dispatch]
  );

  const connect = useCallback(
    (userId) => {
      fetchApi(
        {
          url: connectApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            connection_id: userId,
            date: getCurrentDate(),
          },
        },
        (data) => {
          if (data.message === "connection already established") {
            getRoomId(userId);
            navigate("/chat");
          } else {
            dispatch(deleteChatRoom());
            dispatch(
              setChatRoom({
                roomId: data.room_id,
                recieverId: userId,
                recieverRole: "V&iR8",
                status: "1",
              })
            );
            navigate("/chat");
          }
        }
      );
    },
    [fetchApi, token, connectApi, dispatch, navigate, getRoomId]
  );

  useEffect(() => {
    const fetchData = setTimeout(() => {
      milestoneData();
    });
    return () => {
      clearTimeout(fetchData);
    };
  }, [milestoneData, navigate, userRole]);

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        {isLoading && <Loader />}
        {error && (
          <div className="p-3 md:p-6">
            <Error />
          </div>
        )}
        {!isLoading && !error && projectData && (
          <div className="p-5 space-y-10">
            <h3 className="font-semibold text-xl">Payment Invoice</h3>
            <div className="grid md:grid-cols-2 gap-5">
              <div className="bg-white shadow-md rounded-lg p-3">
                <h6 className="font-medium text-lg mb-3">Project Details</h6>
                <div className="space-y-2">
                  <p>
                    <span className="font-medium me-1">Title :</span>
                    {projectData?.title}
                  </p>
                  <p className="max-h-[150px] overflow-y-auto">
                    <span className="font-medium me-1">Description :</span>
                    {projectData?.description}
                  </p>
                  <p>
                    <span className="font-medium me-1">Budget :</span>
                    <span className="font-medium">&#8377;</span>
                    {projectData?.min_budget} -{" "}
                    <span className="font-medium">&#8377;</span>
                    {projectData?.max_budget}
                  </p>
                </div>
              </div>
              <div className="bg-white shadow-md rounded-lg p-3">
                <div className="flex items-center justify-between mb-3">
                  <h6 className="font-medium text-lg">Bid Details</h6>
                  {userRole === "f@!3A" && (
                    <Button
                      className="px-2 py-0.5 text-sm"
                      bgColor="bg-green-500"
                      onClick={() => connect(projectData?.vendor_id)}
                    >
                      Chat
                    </Button>
                  )}
                </div>
                <div className="space-y-2">
                  <p>
                    <span className="font-medium me-1">Service Provider :</span>
                    {location.state?.companyName}
                  </p>
                  <p className="max-h-[150px] overflow-y-auto">
                    <span className="font-medium me-1">Description :</span>
                    {projectData?.bid_description}
                  </p>
                  <p>
                    <span className="font-medium me-1">Bid Amount :</span>
                    <span className="font-medium">&#8377;</span>
                    {projectData?.bid_amount}
                  </p>
                </div>
              </div>
            </div>
            <div className="max-w-fit overflow-auto md:max-w-none md:overflow-none md:w-full">
              <table className="table-auto min-w-max md:w-full border">
                <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
                  <tr>
                    <td className="p-3 text-sm font-medium">S.No</td>
                    <td className="p-3 text-sm font-medium">Date</td>
                    <td className="p-3 text-sm font-medium w-5/12">
                      Project Title
                    </td>
                    <td className="p-3 text-sm font-medium">Amount</td>
                    <td className="p-3 text-sm font-medium">Status</td>
                    {userRole !== "V&iR8" && (
                      <td className="p-3 text-sm font-medium">Action</td>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white border-b border-slate-300 align-middle h-auto">
                    <td className="p-3 text-xs md:text-sm font-medium">1</td>
                    <td className="p-3 text-xs md:text-sm font-medium">
                      {projectData?.milestone_date || currDate}
                    </td>
                    <td className="p-3 text-xs md:text-sm font-medium">
                      {projectData?.title}
                    </td>
                    <td className="p-3 text-xs md:text-sm font-medium">
                      <span className="font-medium">&#8377;</span>
                      {projectData?.milestone_amount || projectData?.bid_amount}
                    </td>
                    <td
                      className={`p-3 text-xs md:text-sm font-medium ${
                        projectData.milestone_status === "-1"
                          ? "text-orange-400"
                          : projectData.milestone_status === "0"
                          ? "text-blue-500"
                          : "text-green-500"
                      }`}
                    >
                      {projectData.milestone_status === "-1"
                        ? "Pending"
                        : projectData.milestone_status === "0"
                        ? "Awarded"
                        : "Released"}
                    </td>
                    {userRole !== "V&iR8" && (
                      <td className="p-3 text-xs md:text-sm font-medium">
                        {projectData.milestone_status === "-1" ? (
                          <Button
                            className="px-3 py-1"
                            bgColor="bg-orange-500"
                            title="Create milestone"
                            onClick={() =>
                              createCheckoutSession({
                                type: "market",
                                bid_id: bid_id,
                                ad_id: ad_id,
                                title: projectData?.title,
                              })
                            }
                          >
                            Create Milestone
                          </Button>
                        ) : projectData.milestone_status === "0" ? (
                          <Button
                            className="px-3 py-1"
                            title="Release funds"
                            onClick={() => setOpenRateModal(true)}
                          >
                            Release
                          </Button>
                        ) : (
                          <Button
                            className="px-3 py-1 disabled"
                            bgColor="bg-green-500"
                            disabled
                            title="Funds Released"
                          >
                            Released
                          </Button>
                        )}
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="bg-[#F0F8FF] shadow-md rounded-lg p-3 text-sm space-y-3">
              <h4 className="font-semibold text-base">
                Milestone Description on PitchHere
              </h4>
              <p>
                Milestones represent the agreed amount between users and service
                providers for any service on PitchHere. When a project is
                awarded to a service provider, a milestone amount is created and
                held by PitchHere until the project is completed. This process
                ensures:
              </p>
              <ol className="list-decimal ml-5 space-y-2">
                <li>
                  <span className="font-medium">
                    Assurance for Both Parties :
                  </span>{" "}
                  The milestone system guarantees that the project owner does
                  not pay any fees without receiving the completed project, and
                  the service provider is assured that the funds are reserved
                  for the service.
                </li>
                <li>
                  <span className="font-medium">Payment Security :</span> The
                  project owner can feel confident that the money is safely held
                  until the service provider completes the project. This helps
                  maintain trust and security on the platform.
                </li>
                <li>
                  <span className="font-medium">Release of Funds :</span> Once
                  the project is delivered by the service provider, the project
                  owner needs to release the milestone. This action confirms the
                  completion and satisfaction with the service provided.
                </li>
                <li>
                  <span className="font-medium">Dispute Resolution :</span> If
                  the project is delivered and the milestone is not released,
                  the service provider can approach the PitchHere support team
                  to resolve any disputes. This ensures fair and transparent
                  handling of issues between users and service providers.
                </li>
              </ol>
              <p>
                The milestone system on PitchHere fosters a secure and
                trustworthy environment for both parties, ensuring smooth
                transactions and successful project completions.
              </p>
            </div>
          </div>
        )}
      </div>
      <RatingModal
        open={openRateModal}
        onClose={() => setOpenRateModal(false)}
        submitRating={submitRating}
        type="market"
      />
    </div>
  );
};

export default Milestone;
