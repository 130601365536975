import React, { useState } from "react";
import { useForm } from "react-hook-form";
import useFetch from "../../../hooks/useFetch";
import { Button } from "../../../components/ui/Button";
import { Loader } from "../../../components/ui/Loader";
import { Error } from "../../../components/ui/Error";
import { MailIcon, EyeIcon, SlashedEyeIcon } from "../../../icons/icons";

const AdminLogin = ({ postLogin, forgotPasswordBtn, isError, clearError }) => {
  const adminLoginApi = `${process.env.REACT_APP_API_URL}admin-login`;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { isLoading, error, fetchApi } = useFetch();

  const [show, setShow] = useState(false);

  const LoginApiCall = (data) => {
    clearError();
    fetchApi(
      {
        url: adminLoginApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: { email: data.loginEmail, password: data.password },
      },
      postLogin
    );
  };

  return (
    <div
      className="flex col-span-7 authentication-bg relative lg:bg-white md:col-start-2 md:col-span-5 md:items-center pb-3 px-4 md:px-0 md:py-0 md:my-3 lg:col-span-2 lg:my-0 lg:py-0"
      id="adminloginForm"
    >
      <div className="mx-auto p-5 md:p-6 rounded-md shadow-md bg-white md:w-full lg:p-3 lg:bg-transparent lg:shadow-none lg:rounded-none lg:pt-0">
        {error && <Error />}
        <h4 className="mb-4 text-lg font-semibold text-center">Admin Login</h4>
        <form onSubmit={handleSubmit(LoginApiCall)}>
          <div className="mb-1">
            <label htmlFor="loginEmail" className="mb-0 text-sm required">
              Email
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{ border: "1px solid #ced4da" }}
            >
              <input
                type="email"
                className="border-0 w-full ps-3"
                id="loginEmail"
                name="loginEmail"
                placeholder="Enter email"
                {...register("loginEmail", {
                  required: true,
                  validate: {
                    matchPattern: (value) =>
                      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(
                        value
                      ) || "Please enter a valid email address",
                  },
                })}
                autoFocus
              />
              <span className="input-icons icon-span">
                <MailIcon />
              </span>
            </div>
            {errors.loginEmail && (
              <span className="text-secondary">Email is required</span>
            )}
          </div>
          <div className="mb-1">
            <label htmlFor="password" className="mb-0 text-sm required">
              Password
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{ border: "1px solid #ced4da" }}
            >
              <input
                type={show ? "text" : "password"}
                id="password"
                className="border-0 w-full ps-3"
                name="password"
                placeholder="Enter your password"
                {...register("password", {
                  required: true,
                  minLength: 7,
                })}
              />
              <span
                className="input-icons cursor-pointer icon-span"
                onClick={() => setShow(!show)}
              >
                {show ? <EyeIcon /> : <SlashedEyeIcon />}
              </span>
            </div>
            {errors.password && (
              <span className="text-secondary">Password is required</span>
            )}
          </div>
          <div className="pt-2">{isLoading && <Loader pt="10px" />}</div>
          {!error && isError && (
            <p className="text-red-500 passwordError">
              Incorrect email or password.
            </p>
          )}
          <span
            className="my-3 float-right border-0 text-blue-500 cursor-pointer text-xs md:text-sm"
            onClick={forgotPasswordBtn}
          >
            Forgot Password?
          </span>
          <Button type="submit" className="px-3 py-1 mt-2 text-sm">
            Login
          </Button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
