import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateCredits } from "../../../store/slices/FetchCreditsSlice";
import { updateTokensUsed } from "../../../store/slices/dashboard/StartupDashboardSlice";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useFetch from "../../../hooks/useFetch";
import useNotificationApi from "../../../hooks/useNotificationApi";
import { useToastContext } from "../../../context/ToastContext";
import { ConnectedUsers } from "../connectedUsers";
import { IncomingRequests } from "../incomingRequests";
import { OutgoingRequests } from "../outgoingRequests";
import { Loader } from "../../../components/ui/Loader";
import { Error } from "../../../components/ui/Error";
import Sidebar from "../../../components/layout/Sidebar";
import Navbar from "../../../components/layout/Navbar";
import { Button } from "../../../components/ui/Button";
import { ConfirmationModal } from "../../../components/ui/modals/utility-modals/confirmationModal";
import { getCurrentDate } from "../../../utils/getCurrentDate";

export const StartupConnections = () => {
  const displayConnectionsApi = `${process.env.REACT_APP_API_URL}display-connections`;
  const incomingRequestApi = `${process.env.REACT_APP_API_URL}incoming-requests`;
  const outGoingConnectionReqApi = `${process.env.REACT_APP_API_URL}outgoing-requests`;
  const acceptOrCancelReqApi = `${process.env.REACT_APP_API_URL}accept-cancel-connect-request`;
  const unfollowConnectionApi = `${process.env.REACT_APP_API_URL}unfollow-connection`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const { sendNotification } = useNotificationApi();
  const navigate = useNavigate();
  const toast = useToastContext();
  const dispatch = useDispatch();

  const [connections, setConnections] = useState([]);
  const [incomingRequest, setIncomingRequest] = useState([]);
  const [outGoingRequests, setOutGoingRequests] = useState([]);
  const [tableType, setTableType] = useState("connected");
  const [openSidebar, setOpenSidebar] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState({
    callbackFn: null,
    message: "",
  });
  const [connectionFees, setConnectionFees] = useState({
    mentor: null,
    investor: null,
  });
  const ref = useRef(true);

  const displayFriends = useCallback(() => {
    setTableType("connected");
    fetchApi(
      {
        url: displayConnectionsApi,
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setConnections(data.response);
        setConnectionFees({
          mentor: data.mentor_connectionFee,
          investor: data.investor_connectionFee,
        });
      }
    );
  }, [displayConnectionsApi, token, fetchApi]);

  const getIncomingRequests = useCallback(() => {
    setTableType("incoming");
    fetchApi(
      { url: incomingRequestApi, headers: { "x-token": token } },
      (data) => setIncomingRequest(data.response)
    );
  }, [incomingRequestApi, fetchApi, token]);

  const getOutGoingRequests = useCallback(() => {
    setTableType("outgoing");
    fetchApi(
      {
        url: outGoingConnectionReqApi,
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setOutGoingRequests(data.response);
      }
    );
  }, [outGoingConnectionReqApi, token, fetchApi]);

  const acceptOrCancelRequest = useCallback(
    (type, connection_id, tableType, role) => {
      fetchApi(
        {
          url: acceptOrCancelReqApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            connection_id: connection_id,
            type: type,
            date: getCurrentDate(),
          },
        },
        (data) => {
          console.log(tableType);
          if (data.message === "already cancelled") {
            toast.open("false", "Request was already cancelled");
          }
          if (tableType === "incoming") {
            if (data.message === "cancelled") {
              sendNotification(
                connection_id,
                `Connection request declined by ${data.name}`,
                "connections"
              );
            }
            if (data.message === "accepted") {
              sendNotification(
                connection_id,
                `Connection request accepted by ${data.name}`,
                "connections"
              );
            }
            getIncomingRequests();
          } else {
            if (data.message === "cancelled") {
              sendNotification(
                connection_id,
                `Connection request was cancelled by ${data.name}`,
                "connections"
              );
              if (role === "R$7s2") {
                dispatch(
                  updateCredits({ type: "add", amount: connectionFees.mentor })
                );
                dispatch(
                  updateTokensUsed({
                    type: "remove",
                    amount: connectionFees.mentor,
                  })
                );
              } else if (role === "Sw%aa") {
                dispatch(
                  updateCredits({
                    type: "add",
                    amount: connectionFees.investor,
                  })
                );
                dispatch(
                  updateTokensUsed({
                    type: "remove",
                    amount: connectionFees.investor,
                  })
                );
              }
            }
            if (data.message === "connected") {
              toast.open("false", "Request was already accepted");
            }
            getOutGoingRequests();
          }
        }
      );
    },
    [
      acceptOrCancelReqApi,
      fetchApi,
      token,
      getOutGoingRequests,
      getIncomingRequests,
      sendNotification,
      toast,
      dispatch,
      connectionFees,
    ]
  );

  const unFollow = useCallback(
    (friend_id) => {
      fetchApi(
        {
          url: unfollowConnectionApi,
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            connection_id: friend_id,
            date: getCurrentDate(),
          },
        },
        (data) => {
          sendNotification(
            friend_id,
            `Connection removed by ${data.name}`,
            "connections"
          );
          toast.open("true", "Connection removed successfully!");
          displayFriends();
        }
      );
    },
    [
      unfollowConnectionApi,
      fetchApi,
      token,
      sendNotification,
      displayFriends,
      toast,
    ]
  );

  useEffect(() => {
    if (userRole !== "f@!3A") {
      navigate("/");
      return;
    }
    if (ref.current) {
      displayFriends();
    }
    return () => {
      ref.current = false;
    };
  }, [displayFriends, navigate, userRole]);

  const handleConfirmationModal = (type, id, tableType, role) => {
    setConfirmationModal(true);
    if (type === "cancel") {
      setConfirmModalData({
        callbackFn: () => acceptOrCancelRequest(type, id, tableType, role),
        message: "Are you sure you want to cancel this connection request.",
      });
    } else if (type === "accept") {
      setConfirmModalData({
        callbackFn: () => acceptOrCancelRequest(type, id, tableType),
        message: "Are you sure you want to accept this connection request.",
      });
    } else if (type === "unfollow") {
      setConfirmModalData({
        callbackFn: () => unFollow(id),
      });
    }
  };

  const viewProfile = (name, role, user_id) => {
    if (role === "f@!3A") {
      navigate(`/view-startup/${name.split(" ").join("-")}/${user_id}`);
    } else if (role === "R$7s2") {
      navigate(`/view-mentor/${name.split(" ").join("-")}/${user_id}`, {
        state: { connection_fee: connectionFees.mentor },
      });
    } else if (role === "Sw%aa") {
      navigate(`/view-investor/${name.split(" ").join("-")}/${user_id}`, {
        state: { connection_fee: connectionFees.investor },
      });
    } else {
      navigate(`/view-vendor/${name.split(" ").join("-")}/${user_id}`);
    }
  };

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="p-4">
          <div className="text-end">
            <Button
              onClick={displayFriends}
              className="px-2 py-1 m-1 text-xs md:text-sm"
            >
              Connected Users
            </Button>
            <Button
              onClick={getIncomingRequests}
              className="px-2 py-1 m-1 text-xs md:text-sm"
              bgColor="bg-green-600"
            >
              Incoming Requests
            </Button>
            <Button
              onClick={getOutGoingRequests}
              className="px-2 py-1 m-1 text-xs md:text-sm"
              bgColor="bg-[#F97316]"
            >
              Outgoing Requests
            </Button>
          </div>
          {error && <Error />}
          {isLoading && <Loader />}
          {!error &&
            !isLoading &&
            (tableType === "connected" ? (
              <ConnectedUsers
                connections={connections}
                handleConfirmationModal={handleConfirmationModal}
                viewProfile={viewProfile}
              />
            ) : tableType === "incoming" ? (
              <IncomingRequests
                incomingRequest={incomingRequest}
                handleConfirmationModal={handleConfirmationModal}
                viewProfile={viewProfile}
              />
            ) : (
              <OutgoingRequests
                outGoingRequests={outGoingRequests}
                handleConfirmationModal={handleConfirmationModal}
                viewProfile={viewProfile}
              />
            ))}
        </div>
      </div>
      <ConfirmationModal
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        callBackFn={confirmModalData.callbackFn}
        message={confirmModalData.message}
      />
    </div>
  );
};
