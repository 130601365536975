import React from "react";
import blurChat from "../../images/blur-chat.png";

const BlankPreview = () => {
  return (
    <div className="h-full shadow-md rounded-md overflow-hidden">
      <div className="chat !bg-white">
        <div
          className="h-full bg-contain flex items-center justify-center chat-history chat-msg-box custom-scrollbar"
          style={{
            backgroundImage: `url(${blurChat})`,
            backgroundSize: "cover",
            minHeight: "calc(100vh - 220px)",
          }}
        >
          <p className="p-5 font-semibold rounded-md bg-white shadow-lg">
            Select a connection to start communicating
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlankPreview;
