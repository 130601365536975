import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import useFetch from "../../hooks/useFetch";
import useDisplayIndustries from "../../hooks/useDisplayIndustries";
import { useToastContext } from "../../context/ToastContext";
import { randomId } from "../../utils/randomId";
import { EditAdModal } from "../../components/ui/modals/market/editAdModal";
import { PostAdModal } from "../../components/ui/modals/market/postAdModal";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { Button, Pagination } from "../../components/ui/Button";
import { DeleteIcon, EyeIcon, PenIcon } from "../../icons/icons";
import { getCurrentDate } from "../../utils/getCurrentDate";

const MyAds = () => {
  const postAdApi = `${process.env.REACT_APP_API_URL}post-ad`;
  const startupAdsApi = `${process.env.REACT_APP_API_URL}startup-ads`;
  const deleteAdApi = `${process.env.REACT_APP_API_URL}delete-ad`;
  const editAdApi = `${process.env.REACT_APP_API_URL}edit-ad`;

  const { token } = useLocalStorage();
  const { isLoading, error, fetchApi } = useFetch();
  const toast = useToastContext();
  const { displayIndustries } = useDisplayIndustries();

  const [ads, setAds] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [postModal, setPostModal] = useState(false);
  const [editAdModal, setEditAdModal] = useState(false);
  const [singleAd, setSingleAd] = useState([]);
  const [page, setPage] = useState(1);

  const startupAds = useCallback(() => {
    fetchApi(
      {
        url: startupAdsApi,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
      },
      (data) => {
        setAds(data.ads);
      }
    );
  }, [token, startupAdsApi, fetchApi]);

  const postAd = useCallback(
    (data, expertise) => {
      fetchApi(
        {
          url: postAdApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            title: data.title,
            max_budget: data.maxBudget,
            min_budget: data.minBudget,
            deadline: data.deadline,
            tags: expertise,
            description: data.description,
            date: getCurrentDate(),
          },
        },
        () => {
          toast.open("true", "Project created successfully!");
          startupAds();
        }
      );
    },
    [fetchApi, token, postAdApi, startupAds, toast]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      startupAds();
      displayIndustries(
        (data) => setIndustries(data.industries),
        "service provider"
      );
    }, 10);
    return () => clearTimeout(timer);
  }, [fetchApi, token, startupAds, displayIndustries]);

  const editAd = (data, expertise, id) => {
    fetchApi(
      {
        url: editAdApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          id: id,
          title: data.title,
          max_budget: data.maxBudget,
          min_budget: data.minBudget,
          deadline: data.deadline,
          tags: expertise,
          description: data.description,
        },
      },
      (data) => {
        if (data.message === "awarded") {
          toast.open("warning", "Cannot edit awarded Project");
          return;
        }
        startupAds();
      }
    );
  };

  const deleteAd = (id) => {
    fetchApi(
      {
        url: deleteAdApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: { id: id },
      },
      (data) => {
        if (data.message === "awarded") {
          toast.open("warning", "Cannot delete awarded Project");
          return;
        }
        startupAds();
      }
    );
  };

  const handleEditModal = (data) => {
    setSingleAd(data);
    setEditAdModal(true);
  };

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        {isLoading && <Loader />}
        {error && (
          <div className="p-4">
            <Error />
          </div>
        )}
        {!isLoading && !error && (
          <>
            <div className="flex items-center justify-between p-3">
              <h4 className="text-xl font-semibold">My Projects</h4>
              <Button
                className="p-1.5 text-sm"
                onClick={() => setPostModal(true)}
              >
                Post Project
              </Button>
            </div>
            <p className="text-sm p-3">
              <span className="font-medium pe-1">Note :</span> The awarded
              amount will be frozen and released to the service provider only
              upon completion of the project.
            </p>
            <div className="p-3 max-w-fit overflow-auto md:max-w-none md:overflow-none md:w-full">
              <table className="table-auto min-w-max md:w-full border">
                <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
                  <tr>
                    <th className="p-3 text-sm w-5/12 font-medium">Title</th>
                    <th className="p-3 text-sm font-medium">
                      Budget <span className="font-semibold">(&#8377;)</span>
                    </th>
                    <th className="p-3 text-sm font-medium">
                      Project Fee{" "}
                      <span className="font-semibold">(&#8377;)</span>
                    </th>
                    <th className="p-3 text-sm font-medium">Timeline</th>
                    <th className="p-3 text-sm font-medium">Status</th>
                    <th className="p-3 text-sm font-medium">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {ads.length > 0 ? (
                    ads.slice(page * 7 - 7, page * 7).map((ad) => (
                      <tr
                        key={ad._id}
                        className="bg-white border-b border-slate-300 align-middle h-auto"
                      >
                        <td className="p-3 text-sm">{ad.title}</td>
                        <td className="p-3 text-sm">
                          {ad.min_budget} - {ad.max_budget}
                        </td>
                        <td className="p-3 text-sm">
                          {ad.amount === 0 ? (
                            <span className="text-red-500">NA</span>
                          ) : (
                            ad.amount
                          )}
                        </td>
                        <td className="p-3 text-sm">
                          {ad.deadline}
                          <span className="font-medium ml-1">days</span>
                        </td>
                        <td className="p-3">
                          <span
                            className={`p-1 text-xs ${
                              ad.status === "0"
                                ? "blue-badge"
                                : ad.status === "1"
                                ? "orange-badge"
                                : "green-badge"
                            }`}
                          >
                            {ad.status === "0"
                              ? "Active"
                              : ad.status === "1"
                              ? "Awarded"
                              : "Completed"}
                          </span>
                        </td>
                        <td className="p-3 text-sm">
                          <div className="flex items-center justify-evenly gap-2 h-full">
                            <Link
                              to={`/bids/${ad._id}`}
                              state={{ id: ad._id }}
                              title="View Project Details"
                            >
                              <EyeIcon className="cursor-pointer fill-green-500 transition-colors ease-in duration-50 hover:fill-green-800" />
                            </Link>
                            {ad.status === "0" ? (
                              <span title="Edit Project">
                                <PenIcon
                                  width="16px"
                                  className="cursor-pointer fill-blue-500 transition-colors ease-in duration-50 hover:fill-blue-800"
                                  onClick={() =>
                                    ad.status === "0" && handleEditModal(ad)
                                  }
                                />
                              </span>
                            ) : (
                              <span title="Cannot Edit Project">
                                <PenIcon
                                  width="16px"
                                  className="disabled fill-blue-500"
                                />
                              </span>
                            )}
                            {ad.status === "0" ? (
                              <span title="Delete Project">
                                <DeleteIcon
                                  className="cursor-pointer fill-red-500 transition-colors ease-in duration-50 hover:fill-red-800"
                                  onClick={() =>
                                    ad.status === "0" && deleteAd(ad._id)
                                  }
                                />
                              </span>
                            ) : (
                              <span title="Cannot Delete Project">
                                <DeleteIcon className="disabled fill-red-500" />
                              </span>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="bg-white text-center">
                      <td colSpan={5} className="p-3 text-sm">
                        No Ad posted
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {ads.length > 0 && (
              <Pagination
                page={page}
                setPage={setPage}
                totalDataCount={ads.length}
              />
            )}
          </>
        )}
      </div>
      <PostAdModal
        open={postModal}
        onClose={() => setPostModal(false)}
        postAd={postAd}
        industries={industries}
      />
      <EditAdModal
        key={randomId()}
        open={editAdModal}
        onClose={() => setEditAdModal(false)}
        singleAd={singleAd}
        editAd={editAd}
        industries={industries}
      />
    </div>
  );
};

export default MyAds;
