import { useEffect } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import { Button } from "../../Button";
import { FormError } from "../../FormError";
import { numberRegex } from "../../../../constants";

export const AmountWithdraw = ({
  open,
  onClose,
  withdrawRequest,
  processedAmount,
  walletDetails,
}) => {
  const { userRole } = useLocalStorage();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  if (!open) return null;

  const handleMentorSubmit = (data, e) => {
    e.preventDefault();
    withdrawRequest(data.withdrawnAmount);
    onClose();
  };

  const handleVendorSubmit = (data, e) => {
    e.preventDefault();
    withdrawRequest(data.withdrawnAmount);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`w-[90%] md:w-[40%] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading font-semibold text-center border-b p-3 text-base">
          Amount Withdrawal
        </h4>
        <form
          onSubmit={
            userRole === "R$7s2"
              ? handleSubmit(handleMentorSubmit)
              : handleSubmit(handleVendorSubmit)
          }
          className="p-3 text-left"
        >
          <p className="font-medium">
            Total Earning :{" "}
            <span>
              {processedAmount ? processedAmount.total : walletDetails.total}
            </span>
          </p>
          <p className="font-medium">
            Balance Amount :{" "}
            <span>
              {processedAmount
                ? processedAmount.balance
                : walletDetails.balance}
            </span>
          </p>
          <div className="flex mt-2">
            <span className="me-2 font-semibold text-xs">Note :</span>
            <p className="flex flex-col">
              <span className="mb-0 font-medium text-xs">
                1. Balance amount must be atleast 2000.
              </span>
              <span className="mb-0 font-medium text-xs">
                2. Platform charges 10% of your Withdrawn amount as fee.
              </span>
            </p>
          </div>
          <div className="mt-3">
            <label
              htmlFor="withdrawnAmount"
              className="font-medium text-base required"
            >
              Enter the amount
            </label>
            <input
              type="number"
              id="withdrawnAmount"
              className="w-full px-2 py-1 border border-gray-400 rounded-md focus:ring-1 focus:ring-indigo-600"
              {...register("withdrawnAmount", {
                required: "Amount is required",
                pattern: {
                  value: numberRegex,
                  message: "Amount must only contain digits",
                },
                validate: {
                  notZero: (fieldValue) => {
                    return fieldValue > 0 || "Amount cannot be 0";
                  },
                  MinAmount: (fieldValue) => {
                    return (
                      fieldValue > 1999 || "Balance amount must be atleast 2000"
                    );
                  },
                  checkBalance: (fieldValue) => {
                    return userRole === "R$7s2"
                      ? processedAmount.balance >= fieldValue ||
                          "Amount entered must be less than or equal to 'Balance Amount'"
                      : walletDetails.balance >= fieldValue ||
                          "Amount entered must be less than or equal to 'Balance Amount'";
                  },
                },
              })}
            />
          </div>
          {errors.withdrawnAmount && (
            <div>
              <FormError message={errors.withdrawnAmount.message} />
            </div>
          )}
          <div className="modalFooter p-3">
            <Button type="submit" className="px-2 py-1 mx-1">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
